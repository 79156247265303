import axios from "axios";
import UrlAssembler from "url-assembler";

export interface IAppConfig {
  readonly name: string;
  readonly apiUrl: UrlAssembler;
  readonly oDataUrl: UrlAssembler;
  readonly buildVersion: string;
  readonly git_commit: string;
  readonly version: string;
  readonly release_date: string;
  readonly FLPOptEnableUserManagement?:boolean;
}

type IAppConfigJson = {
  name: string;
  dataUrl: string;
  buildVersion: string;
  git_commit: string;
  version: string;
  release_date: string;
  readonly FLPOptEnableUserManagement?:boolean;
};

export class AppConfig implements IAppConfig {
  readonly name: string;
  //private readonly dataUrl: UrlAssembler;
  readonly apiUrl: UrlAssembler;
  readonly oDataUrl: UrlAssembler;
  readonly buildVersion: string;
  readonly git_commit: string;
  readonly version: string;
  readonly release_date: string;
  readonly FLPOptEnableUserManagement?:boolean;

  constructor(json: IAppConfigJson) {
    this.name = json.name;

    const dataUrl = new UrlAssembler(json.dataUrl);
    this.apiUrl = dataUrl.segment("api/v1/");
    this.oDataUrl = dataUrl.segment("odata/v1/");

    this.buildVersion = json.buildVersion;
    this.git_commit = json.git_commit;
    this.version = json.version;
    this.release_date = json.release_date;

    // Option to enable disable user management in fleet portal application
    this.FLPOptEnableUserManagement  = json.FLPOptEnableUserManagement;
  }
}

let loadedConfig: IAppConfig;

// Load config file from "/public/config.json"
const configFileName = "config.json";
const configUrl = process.env.PUBLIC_URL + "/" + configFileName;

async function internalLoadConfig() {
  try {
    const configResponse = await axios.get<IAppConfigJson>(configUrl);
    loadedConfig = new AppConfig(configResponse.data);
    return loadedConfig;
  } catch (e) {
    throw new Error(`Failed to load app configuration (${configFileName})`);
  }
}

const configLoaderPromise = internalLoadConfig();
// The following isn't strictly needed -- we could just export `cachedConfigLoader` directly.
// However, making it a function makes for a better API. Ex: Instead of `loadConfig.then(...)`, caller will need to use `loadConfig().then(...)`
const loadConfig = () => configLoaderPromise;

function getLoadedConfig() {
  if (!loadedConfig) {
    throw new Error("App configuration hasn't been loaded!");
  }

  return loadedConfig;
}

export { getLoadedConfig as default, loadConfig };
