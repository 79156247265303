// Function for get Practice

import requestApi from '../request'
import { ADD_PRACTICES } from '../../../redux/type/practices';

interface IPostPracticesParams {
  name: string,
  email: string,
  address: {
    line1: string,
    city: string,
    region: string,
    zip: string,
    country: string,
  }
}

const addPractice = async (params: IPostPracticesParams) => {

  const path = `Practices/Add`;

  const body = {
    "Name": params.name,
    "EmailAddress": params.email,
    "Line1": params.address.line1,
    "Line2": "",
    "Line3": "",
    "City": params.address.city,
    "Region": params.address.region,
    "PostCode": params.address.zip,
    "CountryCode": params.address.country
  }
  await requestApi(path, 'post', ADD_PRACTICES, body)
}

export default addPractice