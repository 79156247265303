import React from "react";
import ErrorBoundary from "../../shared/ErrorBoundary";

const Content: React.FC = () => {
  return (
    <>
      <p>Please reload the page and try again.</p>
      <div style={{ marginTop: "2em" }}>
        <button
          onClick={() => window.location.reload()}
          style={{ minWidth: 80 }}
        >
          Reload
        </button>
      </div>
    </>
  );
};

const content = <Content />;

const AppErrorBoundary: React.FC = ({ children }) => {
  return <ErrorBoundary content={content}>{children}</ErrorBoundary>;
};

export default AppErrorBoundary;
