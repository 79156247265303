import React from "react";
import css from "./ConfirmDisableDialog.module.scss";
import GeneralWarning from "../../../assets/images/indications/ico-GeneralWarning.svg";
import { useTranslation } from 'react-i18next'; 

export default function ConfirmDisableDialog() {

  const {t} = useTranslation();
  
  return (
    <div className={css.confirmDisable}>
      <img
        src={GeneralWarning}
        alt="GeneralWarning"
        className={css.generalWarning}
      />
      <h2 className={css.header}>{t('DisableLab')}</h2>
      <p className={css.body}>
        {t('DeletePartnerMsg')}
        <br />
        {t('ProceedMsg')}
      </p>
    </div>
  );
}
