import { getCode, getName } from "country-list";
import i18next from "i18next";
import http from "./api/http";
import getLoadedConfig from "./appConfigService";
import { IExchangeLabAddressModel, IExchangeLabModel } from "./labExchangeModel";


export const FormatAddress = (address: IExchangeLabAddressModel) => {
  if (address) {
    return `${address.line1 && `${address.line1}, `}${address.line2 && `${address.line2}, `}${address.line3 && `${address.line3}, `}${address.city && `${address.city}, `}${address.postCode && `${address.postCode}, `}${address.region && `${address.region}, `}${address.countryCode && `${getName(address.countryCode)}`}`;
  }
  return "";
};

export interface IExchangeLabList {
  value: IExchangeLabModel[];
}

export const getExchangeLabsForMemberAsync = async (
  userId: string,
  start?: number,
  numberOfLab?: number,
  searchValue?: string
): Promise<IExchangeLabList> => {
  
  // Ask for for lab exchange contrainst the partnerType must be equel to 'Exchange'
  // The email address cannot be null
  let query = `Partners/GetPartnersForUser(userId=${userId})?$count=true`

  if(numberOfLab)
    query += `&$top=${numberOfLab}`;

  if(start)
    query += `&$skip=${start}`;    

  query += "&$expand=address&$filter= recordStatus eq 'Active' and emailAddress ne null and partnerType eq 'Exchange'";

  if (searchValue) {
    // Search only on country field 
    var countryCode = getCode(searchValue);
    countryCode = countryCode?.toLowerCase();
    if (countryCode)
      query += `and contains(tolower(address/countryCode),'${countryCode}')`
    else {
      searchValue = searchValue.toLowerCase();
      query += `and contains(tolower(EmailAddress),'${searchValue}') or contains(tolower(Name),'${searchValue}')
      or contains(tolower(address/line1),'${searchValue}') or contains(tolower(address/line2),'${searchValue}')
      or contains(tolower(address/line3),'${searchValue}') or contains(tolower(address/city),'${searchValue}')
      or contains(tolower(address/region),'${searchValue}') or contains(tolower(address/postCode),'${searchValue}')`;
    }
  }

  const url = getLoadedConfig().oDataUrl + query;
  
  const req = await http.get<IExchangeLabList>(url);

  return req.data;
};

// Retrieve all partner for a member
export const getAllExchangeLabsForMemberAsync = async (
  userId: string,
): Promise<IExchangeLabList> => {
  
 const res:any = await getExchangeLabsForMemberAsync (userId);
 if(res)
 {
  // Retrieve all the other  lab exchange
  // Take the number of lab return in value tab for numberOfLab
  const count:number = res['@odata.count'];
  const countReal:number = res.value.length;
  if(count>countReal)
  {
    let promises: Promise<IExchangeLabList>[] = [];
    const step = countReal;
    for(let i=step; i<count; i+=step){
      promises.push(getExchangeLabsForMemberAsync (userId,i,step));
    }

    // Wait for all promise
    await Promise.all(promises).then((results=>{
      for(const result of results){
        res.value.push(...result.value);
      }

    }));
  }
 }
 return res;
};


export const getMemberId = async (
  userId: string,
): Promise<string> => {

  const config = getLoadedConfig();
  let memberId = null;

  const queryMemberId = `Members?$filter=userId eq ${userId}&$select=recordId`;
  const req = await http.get(config.oDataUrl + queryMemberId);
  memberId = req.data?.value[0]?.recordId;

  return memberId;
}

export const unassignLabsAsync = async (
  memberId:string,
  nameLabId:string,
): Promise<boolean> => {

  const config = getLoadedConfig();

  let req = null;
  {
    const queryAssign = "Members/UnassignMemberPartners";

    interface IUnAssignPartner {
      memberId: string;
      partnersIds: string[];
    };
    
    const data:IUnAssignPartner =  {
      memberId: memberId,
      partnersIds: [
        nameLabId
      ],
    };

    let aq = config.apiUrl + queryAssign;

    req = await http.post(aq,data);
  }

  return (req.status===204);
};

export const assignLabsAsync = async (
  userId:string,
  emailAdress:string
): Promise<IExchangeLabList|null> => {

  const config = getLoadedConfig();

  const _memberId = await getMemberId(userId);
  if(!_memberId)
    return null;

  let labId = null;
  let reqPartner = null;
  {
    const queryPartnerId = `Partners?&$filter= recordStatus eq 'Active' and EmailAddress eq '${emailAdress}'and partnerType eq 'Exchange'&$expand=address`;
    reqPartner = await http.get<IExchangeLabList>(config.oDataUrl + queryPartnerId);
    labId = reqPartner?.data?.value[0]?.recordId;
    if(!labId)
      return Promise.reject("Cannot retrieve laboratory exchange Id");
  }

  {
    const queryAssign = "Members/AssignMemberPartners";

    interface IAssignPartner {
      memberId: string;
      partnersIds: string[];
    };
    
    const data:IAssignPartner =  {
      memberId: _memberId,
      partnersIds: [
        labId,
      ],
    };

      const req = await http.post(config.apiUrl + queryAssign,data);
    if(200 !== req.status)
      return Promise.reject("Cannot assign laboratory");
  }

  return reqPartner.data;
};

export const getExchangeLabsFilteredAsync = async (
  emailSearch?:string,
  nameSearch?:string
): Promise<IExchangeLabList> => {
  const config = getLoadedConfig();

  // Ask for for lab exchange contrainst the partnerType must be equel to 'Exchange'
  // The email address cannot be null
  let query = `Partners?$count=true&$expand=address&$orderby=recordCreated desc`;
  query += "&$filter= recordStatus eq 'Active' and emailAddress ne null and partnerType eq 'Exchange'";

  if(emailSearch && (emailSearch!=="") )
    query += `and contains(emailAddress, '${emailSearch}')`;

  if(nameSearch && (nameSearch!=="") )
    query += `and contains(name, '${nameSearch}')`;    

  const url = config.oDataUrl + query;
  console.log("url", url);
  const req = await http.get<IExchangeLabList>(url);

  return req.data;
};

export const getExchangeLabsAsync = async (
  start: number,
  numberOfLab: number,
  searchValue: string
): Promise<IExchangeLabList> => {
  const config = getLoadedConfig();

  // Ask for for lab exchange contrainst the partnerType must be equel to 'Exchange'
  // The email address cannot be null
  let query = `Partners?$count=true&$top=${numberOfLab}&$skip=${start}&$expand=address&$orderby=recordCreated desc
  &$filter= recordStatus eq 'Active' and emailAddress ne null and partnerType eq 'Exchange'`;

  if (searchValue) {
    // Search only on country field 
    var countryCode = getCode(searchValue);
    countryCode = countryCode?.toLowerCase();
    if (countryCode)
      query += `and contains(tolower(address/countryCode),'${countryCode}')`
    else {
      searchValue = searchValue.toLowerCase();
      query += `and contains(tolower(EmailAddress),'${searchValue}') or contains(tolower(Name),'${searchValue}')
        or contains(tolower(address/line1),'${searchValue}') or contains(tolower(address/line2),'${searchValue}')
        or contains(tolower(address/line3),'${searchValue}') or contains(tolower(address/city),'${searchValue}')
        or contains(tolower(address/region),'${searchValue}') or contains(tolower(address/postCode),'${searchValue}')`;
    }
  }
  const url = config.oDataUrl + query;
  const req = await http.get<IExchangeLabList>(url);

  return req.data;
};

export const addExchangeLabAsyncFlp = async (
  data: IExchangeLabModel
): Promise<IExchangeLabModel> => {

    const lang = i18next.resolvedLanguage;
    const req = await http.post(
    getLoadedConfig().oDataUrl + "Partners?$expand=address",
    {
      name: data.emailAddress,
      emailAddress: data.emailAddress,
      partnerType: data.partnerType,
      enable: data.enable,
      ...(data.address ? {
      address: {
        line1: data.address.line1,
        line2: data.address.line2,
        line3: data.address.line3,
        city: data.address.city,
        region: data.address.region,
        postCode: data.address.postCode,
        countryCode: data.address.countryCode,
      } 
    }: {} ),
    },
    {
      headers: {
        'Accept-Language': lang
      }
    }
  );

  return req.data.value[0];
};

export const getLabCountAsync = async (): Promise<number> => {
  const config = getLoadedConfig();
  let query = "Partners/$count";

  const url = config.oDataUrl + query;
  const req = await http.get<number>(url);

  return req.data;
};
