import React from "react";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import {
  Button,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Pagination } from "@mui/material";
import { useTranslation } from 'react-i18next';
import {getCases, exportCases} from "../../../services/api/connectCase";
import {IConnectCase} from "../../../interfaces/IConnectCase";
import { FormatDateMMYYYY } from "../../../shared/utils/utils";
import moment from 'moment';
import classes from './CasesList.module.scss'
 

interface IProps {
  type: string;
}

export default function CasesList(props: IProps) {
  const { type } = props;
  const casesPerPage = 20; 
  const {t} = useTranslation();

  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [cases, setCases] = React.useState<IConnectCase[]>([]);
  const [casesCount, setCasesCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);

  const getCasesList = async (start:number) => {
    setLoading(true);
    setCurrentPage(start);
    const res = await getCases(
      (start - 1) * casesPerPage,
      casesPerPage
    );
    setCasesCount(res.count);
    setCases(res.value);
    setLoading(false);
  }
  const paginate = (event: any, page: number) => {
    if (page !== currentPage) {
        getCasesList(page);
    }
  };

  const startDownload = async() => {
    exportCases().then(
      response => {
       const url = window.URL.createObjectURL(new Blob([response.data])) 
       const link = document.createElement('a')
       link.href = url
       const fileName = `ScansReport_${moment().format('YYYYMMDD HHmmss')}.csv`;
       link.setAttribute('download', fileName)
       document.body.appendChild(link)
       link.click()
       link.remove()
      }
     );
  }

  React.useEffect(() => {
    // Get Connect cases list for current member's fleet
    paginate(null, 1)
  }, []);

  return (
    <div className={classes.root}>
        <div className="add-bar">
            <div className={classes.nbResult}>
                {`${casesCount} result${casesCount > 1 ? "s" : ""}`}
            </div>
            <div className={classes.download}>
                <Button
                  onClick={() => {
                    startDownload()
                  }}
                  variant="contained"
                  className={classes.button}
                  startIcon={<CloudDownloadOutlinedIcon />}>
                  {t('Download')}
                </Button>
            </div>
        </div>
        <TableContainer className={classes.container} component={Paper}>
            <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell width={200}>{t('Practice Name')}</TableCell>
                    <TableCell width={200}>{t('Device SN')}</TableCell>
                    <TableCell width={200}>{t('Case ID')}</TableCell>
                    <TableCell width={150}>{t('Acq Date')}</TableCell>
                    <TableCell width={200}>{t('Sent Date')}</TableCell>
                    <TableCell width={200}>{t('Download Date')}</TableCell>
                    <TableCell width={200}>{t('Lab Name')}</TableCell>
                    <TableCell width={200}>{t("User's Last Name")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {cases.map((row: IConnectCase, index: number) => (
                <TableRow key={row.caseId}>
                  <TableCell width={200}>{row.addressName}</TableCell>
                  <TableCell width={200}>{row.deviceSN}</TableCell>
                  <TableCell width={200}>{row.caseId}</TableCell>
                  <TableCell width={150} component="th" scope="row">{FormatDateMMYYYY(row.acquisitionDate)}</TableCell>
                  <TableCell width={200}>{FormatDateMMYYYY(row.sentDate)}</TableCell>
                  <TableCell width={200}>{FormatDateMMYYYY(row.downloadDate)}</TableCell>
                  <TableCell width={200}>{row.laboratoryName}</TableCell>
                  <TableCell width={200}>{row.dentistLastName}</TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
      {/* loader */}
      {isLoading && <LinearProgress />}
      {casesCount > 0 && (
        <Pagination
          count={Math.ceil(casesCount / casesPerPage)}
          shape="rounded"
          size="small"
          onChange={paginate}
          defaultPage={1}
          page={currentPage}
          className={classes.pagination}
          color="primary"
        />
      )}
    </div>
  );
}
