import React from "react";
import { ThemeProvider } from "@mui/material";
import AppErrorBoundary from "./AppErrorBoundary";
import { AppConfigProvider } from "../../shared/useAppConfig";
import AppRoutes from "./Routes";
import { BrowserRouter } from "react-router-dom";
import { theme } from "../../theme";
import { AuthServiceProvider } from "../../services/authService";
import { AlertContextProvider } from "../Context/AlertContext"; 

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="app">
        <AppErrorBoundary>
          <AppConfigProvider>
            <AuthServiceProvider>
            <AlertContextProvider>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
              </AlertContextProvider>
            </AuthServiceProvider>
          </AppConfigProvider>
        </AppErrorBoundary>
      </div>
    </ThemeProvider>
  );
};

export default App;
