import { IRootStoreState } from '../../reducer'

import { createSelector } from 'reselect'

export const selectNbResult = (state: IRootStoreState) => state.practices.listPractices.count
export const pendingListPractices = (state: IRootStoreState) => state.practices.listPractices.pending
export const pendingDeletePractice = (state: IRootStoreState) => state.practices.listPractices.delete.pending
export const errorMessageDeletePractice = (state: IRootStoreState) => state.practices.listPractices.delete.errorMessage
export const errorMessageAddPractice = (state: IRootStoreState) => state.practices.listPractices.add.errorMessage;
const selectListPractice = (state: IRootStoreState) => state.practices.listPractices.data
export const selectPractices = (state: IRootStoreState) => state.practices.practices
const selectAddress = (state: IRootStoreState) => state.address.address

export const selectTablePractice = createSelector(
  selectListPractice,
  selectPractices,
  selectAddress,
  (listPractices, practices, address) => listPractices.map((item) => ({
    recordId: practices[item].recordId,
    name: practices[item].name,
    emailAddress: practices[item].emailAddress,
    address: address[practices[item].addressId].label,
    addressForm: {
      line1: address[practices[item].addressId].line1,
      city: address[practices[item].addressId].city,
      region: address[practices[item].addressId].region,
      zip: address[practices[item].addressId].postCode,
      country: address[practices[item].addressId].countryCode,
    }
  }))
)

export const selectPractice = createSelector(
  selectPractices,
  selectAddress,
  (_: any, recordId: string) => recordId,
  (practices, address, recordId) => ({
    recordId: practices[recordId].recordId,
    name: practices[recordId].name,
    emailAddress: practices[recordId].emailAddress,
    addressId: practices[recordId].addressId,
    address: address[practices[recordId].addressId]
  })
)