import React from "react";
import css from "./AddMemberDialog.module.scss";
import TagsInput from "../../TagsInput";
import {
  updateEmailFLP,
  updateMembersNameFlp,
  updateUserFirstNameFlp,
  updateUserSurnameFlp,
  updateMembersUserReferenceFlp,
  updateRecordId,
  updateUserRecordId,
  IMemberResponseFlp,
} from "../../../services/memberServiceFlp";
import isEmail from "validator/lib/isEmail";
import { ConvertArrayReferences } from "../../../shared/utils/utils";
import { useTranslation } from 'react-i18next'; 

interface IProps {
  editRowData?: IMemberResponseFlp;
  setValidated?: (value: boolean) => void;
}

export default function AddEditMemberDialog(props: IProps) {
  const { editRowData, setValidated } = props;
  const member: IMemberResponseFlp | undefined = editRowData;

  const [membersName, setMembersName] = React.useState(member?.name || "");
  const [userEmail, setUserEmail] = React.useState(
    member?.user?.emailAddress || ""
  );
  const [userFirstName, setUserFirstName] = React.useState(
    member?.user?.firstName || ""
  );
  const [userSurname, setUserSurname] = React.useState(
    member?.user?.surname || ""
  );
  const [emailInvalidText, setEmailInvalidText] = React.useState("");
  const [userReference, setUserReference] = React.useState<string[]>(
    member && member.userReference
      ? ConvertArrayReferences(member.userReference)
      : []
  );
  const [localValidated, setLocalValidated] = React.useState<boolean>(false);
  const [tagsinputValidated, setTagsinputValidated] = React.useState<boolean>(
    member ? true : false
  );
  const {t} = useTranslation();

  React.useEffect(() => {
    updateRecordId(undefined === member ? "" : member?.recordId);
    updateUserRecordId(undefined === member ? "" : member?.user?.recordId);
    updateMembersNameFlp(membersName);
    updateUserFirstNameFlp(userFirstName);
    updateUserSurnameFlp(userSurname);
    updateEmailFLP(isEmail(userEmail) ? userEmail : "");
    updateMembersUserReferenceFlp(userReference);

    // Validate formular
    setLocalValidated(
      membersName !== "" &&
        userFirstName !== "" &&
        userSurname !== "" &&
        userEmail !== "" &&
        userReference.length !== 0
    );
  }, [
    member,
    membersName,
    userFirstName,
    userSurname,
    userEmail,
    userReference,
  ]);

  React.useEffect(() => {
    setValidated?.(tagsinputValidated && localValidated);
  }, [tagsinputValidated, localValidated]);

  const tagsInputType = "sapAccount";

  return (
    <div className={css.addMember}>
      <form>
        <div>
          <label htmlFor="name">{t('MemberName')} :</label>
          <input
            id="name"
            type="text"
            value={membersName}
            name="membersName"
            onChange={e => setMembersName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="userFirstName">{t('UserFirstName')} :</label>
          <input
            id="userFirstName"
            type="text"
            value={userFirstName}
            name="userFirstName"
            onChange={e => setUserFirstName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="userSurname">{t('UserSurname')} :</label>
          <input
            id="userSurname"
            type="text"
            value={userSurname}
            name="userSurname"
            onChange={e => setUserSurname(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="userEmail">{t('UserEmail')} :</label>
          <input
            id="userEmail"
            type="email"
            value={userEmail}
            name="userEmail"
            placeholder={t('ExampleEmailAddress')}
            className={emailInvalidText !== "" ? "error-input" : ""}
            onBlur={e => {
              !isEmail(userEmail)
                ? setEmailInvalidText("Invalid email !")
                : setEmailInvalidText("");
            }}
            onChange={e => setUserEmail(e.target.value)}
          />
          {emailInvalidText !== "" && (
            <p className="error-text">{emailInvalidText}</p>
          )}
        </div>
        <div className={css.customerRef}>
          <label htmlFor="memberSapAccount">Customer Ref:</label>
          <TagsInput
            initValues={userReference}
            type={tagsInputType}
            id="memberCustomerRef"
            onValueChange={(values: any) =>
              setUserReference(values as string[])
            }
            setValidated={setTagsinputValidated}
          />
        </div>
        {/* <div>
                    <FormControlLabel
                        labelPlacement="start"
                        value={invite}
                        label="Enable subdivision"
                        control={<Switch color="primary" checked={invite} onChange={e => {
                          setInvite(!invite);
                        }}
                           name="invite" />}
                    />
                </div> */}
      </form>
    </div>
  );
}
