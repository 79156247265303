import { AuthenticationResult, SilentRequest } from "@azure/msal-browser";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { getLoggedInUser } from "../authService";
import { scopes } from "../authService/msal-config.json";
import { getMsalClient } from "../authService/msalClient";

interface IServerApiInnerError {
  type: string;
  message: string;
  stacktrace: string;
  innerError?: IServerApiInnerError;
}

export interface IServerApiError {
  error: {
    code: string;
    message: string;
    innererror?: IServerApiInnerError;
  };
}

function isServerApiError(error: any): error is IServerApiError {
  return error.error && error.error.code && error.error.message;
}

// This is returned, for example, when the WebApi fails to parse the JSON (ex. invalid Guid)
export interface IServerException {
  Message: string;
  ExceptionType: string;
  ExceptionMessage: string;
}

function isServerException(error: any): error is IServerException {
  return error.Message && error.ExceptionType && error.ExceptionMessage;
}

// Custom HTTP Error
export class HttpError extends Error {
  readonly statusCode: number | undefined;

  constructor(message: string, statusCode?: number) {
    super(message);
    this.name = "XHttpError" + (statusCode ? `.${statusCode}` : "");
    this.statusCode = statusCode;
  }
}

function throwHttpError(error: AxiosError<any>, isRetry: boolean): never {
  const statusCode = error.response && error.response.status;
  console.assert(statusCode !== 401);
  const prefix = isRetry ? "[Retry-Phase] " : "";

  // Handle "400 - Bad Data" error.
  if (statusCode === 400) {
    let errorResponse = error.response!.data;
    if (isServerException(errorResponse)) {
      throw new HttpError(prefix + errorResponse.ExceptionMessage, statusCode);
    }

    if (isServerApiError(errorResponse)) {
      throw new HttpError(prefix + errorResponse.error.message, statusCode);
    }

    if (errorResponse.Message) {
      throw new HttpError(prefix + errorResponse.Message, statusCode);
    }

    // Check if detail section is present in the error response
    if (error.response!.data.detail) {
      errorResponse = error.response!.data.detail;
    }

    throw new HttpError(prefix + errorResponse, statusCode);
  }

  // handle error detail or title -- Note: keep this until error management is DONE
  if (error.response?.data?.detail || error.response?.data?.title) {
    throw new HttpError(
      prefix + (error.response?.data?.detail || error.response?.data?.title),
      statusCode
    );
  }

  throw new HttpError(prefix + error.message, statusCode);
}

// Create custom instance of Axios for API requests
const http = axios.create();

// Set the request bearer token
function setRequestBearerToken(
  config: AxiosRequestConfig,
  accessToken: string
) {
  console.assert(!!accessToken);
  config.headers = config.headers ?? {};
  config.headers.Authorization = `Bearer ${accessToken}`;
}

// Custom request interceptor
http.interceptors.request.use(async (config: AxiosRequestConfig) => {
  if (!config.method) {
    throw new Error("HTTP Rquest invoked witout a method.");
  }

  config.headers = config.headers ?? {};
  // Azure AD Bearer token
  if (!config.headers.Authorization) {
    let authResponse: AuthenticationResult | undefined = undefined;

    try {
      const msalClient = getMsalClient();
      const account = getLoggedInUser();
      if (account) {
        const request: SilentRequest = {
          scopes: [scopes.userStandard],
          account: account,
        };

        authResponse = await msalClient.acquireTokenSilent(request);
      } else {
        throw new Error("HTTP Request invoked without a logged-in user.");
      }
      console.assert(!!authResponse?.accessToken);
    } catch (error: any) {
      throw new HttpError(
        "Auth token aquisition failure. " + error?.message,
        401
      );
    }
    setRequestBearerToken(config, authResponse!.accessToken);
  }

  return config;
});

http.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response;
  },
  (error: AxiosError<any>) => {
    console.log("Interceptor Response Error" + error);

    // NOTE: When a CORS request fails, no response is passed along to Axios, and Axios will return
    // `error.code === undefined`, `error.response === undefined` and `error.message` === "Network Error".
    // Ref: https://github.com/axios/axios/issues/383
    const statusCode = error.response && error.response.status;
    if (!statusCode) {
      throw new HttpError(
        typeof error.code === "undefined"
          ? "Possible CORS failure."
          : error.message,
        statusCode
      );
    }

    // Is this an authentication error?
    if (statusCode === 401) {
      throw new HttpError(
        "Authentication failure: " + error.message,
        statusCode
      );
    }

    // Is this an authorisation error?
    if (statusCode === 403) {
      throw new HttpError(
        "Authorisation failure: " + error.message,
        statusCode
      );
    }

    if (error?.config?.params?.notThrowError) {
      console.error(error);
    } else throwHttpError(error, false);
  }
);

export default http;
