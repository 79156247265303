import { Button, CircularProgress } from "@mui/material";
import { Alert } from "@mui/material";
import GeneralWarning from "../../../assets/images/indications/ico-GeneralWarning.svg";
import css from "./ConfirmDeleteDialog.module.scss";
import { useTranslation } from 'react-i18next'; 


interface IProps {
  deletedData?: () => void;
  errorMessage?: string;
  closeDialog?: () => void;
  isLoading?: boolean;
}

export default function ConfirmDeleteDialog(props: IProps) {
  const { deletedData, closeDialog, errorMessage, isLoading } = props; 
  const {t} = useTranslation();

  return (
    <>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <div className={css.confirmDelete}>
        <img
          src={GeneralWarning}
          alt="General warning"
          className={css.generalWarning}
        />
        <h2 className={css.header}>{t('Delete')}</h2>
        <p className={css.body}>
        {t('DeleteCofirmMsg')}<br />
        {t('NoUndoMsg')}
        </p>
      </div>
      {deletedData &&
        closeDialog && ( // remove this condition when the refacto is DONE
          <div className="dialog-actions">
            <Button
              onClick={closeDialog}
              variant="outlined"
              className="btn-cancel"
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={() => deletedData?.()}
              variant="outlined"
              className="btn-confirm"
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={20} style={{ color: "#898a8d" }} />
              ) : (
                "Delete"
              )}
            </Button>
          </div>
        )}
    </>
  );
}
