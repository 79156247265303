import React from "react";
import css from "./TermsOfUse.module.scss";

export const TermsOfUse: React.FC = () => {

  return (
    <>
    <div className={css.mainLayout}>
        <h1>Terms of Use</h1>

        <h3>General</h3>

        <p>
            These Terms of Use ("Terms") are concluded between you and Dental Imaging Technologies Corporation (DITC), 450 Commerce Dr Quakertown, PA, 18951-3729 United States and/or any other affiliated company (defined as any other business entity, whether current or in the future, that directly, or through one or more intermediaries, controls or is controlled by or is under common control) of our group ("Company" or "Our" or "We")). If you choose to use this Website ("Site"), You hereby accept to abide by all the Terms and any other terms which are incorporated herein by reference.
        </p>
        <p>
            Company may change, suspend or discontinue any aspect of this Site at any time, including the availability of any feature, database, or content. Company may also impose limits on certain features and services or restrict access to parts or all of the Site without notice or liability. Additionally, Company may terminate and/or suspend access and/or ability to utilize the Site at any time and without notice, should any of the Terms be violated.
        </p>
        <p>
            In order to access the full content of this Site, you must register as a User or Service Provider. You are responsible for maintaining the confidentiality of Your own password.
        </p>
        <p>
            Using the Site may be prohibited or restricted in certain countries. You are responsible for complying with the laws and regulations of the territory from which You access or use the Site.
        </p>

        <h3>Definitions</h3>
        <p>
            <strong>"Agreement"</strong> refers to these Terms and Conditions.
        </p>
        <p>
            <strong>"Services"</strong> are the ability to access and process selected data collected by Company on the status and use of certain connected medical imaging devices and/or related medical imaging software produced and/or distributed by Company, which You acquired or are otherwise under Your control.
        </p>
        <p>
            <strong>"User"</strong> is a licensed health care professional and whose professional license and/or accreditation remains current and in good standing or a person working under the supervision of such health care professional.
        </p>
        <p>
            <strong>"You"</strong> shall mean the User.
        </p>

        <h3>Use of this Site</h3>
        <p>
            <u>Lawful Access:</u> By accessing this Site, You agree that You will not use this Site for any purpose that is unlawful or prohibited by these Terms. You shall not use this Site in any manner that could damage, disable, overburden, or impair any of Our servers or networks or the servers or networks of Our affiliated companies or of third parties connected to this Site. You shall not gain or try to gain unauthorized access to any area on this Site, which is protected by password, or any other computer systems or networks connected to any of Our servers through hacking or any other means. You shall abide by all applicable Federal and State laws and regulations.
        </p>
        <p>
            <u>Site for Professionals:</u> This Site is exclusively reserved for dental professionals. We aim to include accurate and up to date information, but make no representations or warranties, express or implied, as to the accuracy or completeness of the information provided on this Site and disclaim any liability for the use of this Site and any site linked to it. The information and materials provided on this Site are intended to be general and do not constitute advice in any specific patient case. Although the information at this Site are presented in good faith and believed to be correct, We make no representations or warranties as to the completeness or accuracy of the information. Information is supplied upon the condition that the persons receiving same will make their own determination as to its suitability for their purposes prior to use. In no event will We be responsible for damages of any nature whatsoever resulting from the use of or reliance upon information from this Site or the products, including the Software. Nothing on this Site that is made available by Company is intended to replace the services of a trained health professional or to be a substitute for medical advice of treatment providers.
        </p>
        <p>
            <u>Update to the Site:</u> We may change this Site, its Terms and/or any options on the Site at any time without notice but do not assume any responsibility to update it. Access and use of this Site and any website linked to from this Site and the content thereof is at the risk of Users.
        </p>
        <p>
            <u>Listing on the Site:</u> You may choose to be removed from the Site in Your discretion. To be removed please request removal from make a written request to Company in writing and allow up 10 business days for the removal to be finalized.
        </p>

        <h3>Service Fees</h3>
        <p>Use of the site is subject to the payment of a recurring (annual) fee and may be subject to change in Company's discretion. </p>

        <h3>Communication</h3>
        <p>When utilizing the Services feature of the Site, it allows You to communicate with, receive information from and/or place Service requests with third parties. To enable such communication, You agree that other Users and Service Providers utilizing this Site are authorized (i) to retrieve your name, email and address, telephone number and/or (ii) send You a contact request via the Site. In no event will We be responsible for damages of any nature whatsoever resulting from the use of or reliance on such communication with third parties via the Site. We disclaim any and all liability for any direct or indirect damages arising out of any information You receive from a third party through this Site, or any communications or conduct that ensues from possessing that information.</p>
        
        <h3>Data</h3>
        <p>The Services are based on data collected by Company on the status and use of certain connected medical imaging devices and/or related medical imaging software produced and/or distributed by Company. Information on the data collected by Company, including the nature of this data, Company’s use of this data and storage policy for this data, is available in the sale agreement of the applicable medical imaging device and/or the license governing the related medical imaging software</p>

        <h3>Restricted Activity</h3>
        <p>
            By utilizing the Site, You agree that you will not do the following:
            <ul>
                <li>violate any law, statute, and/or regulations;</li>
                <li>communicate  in an unprofessional manner, including but not limited to communicating in a manner that is deemed harassing and/or threatening; use of the Site in a manner that would be fraudulent and/or in any manner that would infringe on any third party's intellectual property rights;</li>
                <li>improperly (i.e., utilize for purposes outside of the scope of the Site) transfer, use and/or sell any data or Content that is transmitted through the Site; or</li>
                <li>discriminate  against any User based on race, religious creed, color, national origin, ancestry, physical or mental disability, sex, gender, gender identity, age, medical condition, sexual orientation, military or veteran status and/or any other protected classification.</li>
            </ul>
        </p>

        <h3>Disclaimers</h3>
        <p>
            Company does not guarantee or make any promise relating to the results from utilizing the Site. Company disclaims all representations and warranties, express, implied or statutory, not expressly set out in these Terms.
        </p>
        <p>
            The Company strongly recommends strict adherence to any product instructions for use and/or manuals. The Site and the Services provided through the Site are not intended to replace the services of a trained healthcare professional or to be a substitute for the use of independent medical judgment. You have a duty to determine whether or not the Service provided is suitable to Your needs in a particular circumstance. Company does not make any review of the Services accessed through the Site. You agrees that Company does not assume and specifically disclaims any liability for damages or injuries resulting from use of the Site.
        </p>

        <h3>Privacy</h3>
        <p>Company is committed to protecting your privacy.</p>
        <p>
            Type of data collected and purpose of collection: Company may collect Your e-mail address, name, surname or other information that You provide us. Such personal information may be used for product ordering, customer service and other services and to communicate with you. In some instances, such as part of a customer service enquiry, You may be obliged to provide us with some information in order for us to perform our services. In other instances, we may use Your contact details in order to send You product information, or Company information that we believe are of interest to You.
        </p>
        <p>
            <strong>Transfer of data to third parties:</strong> Company does not sell or disclose Your personal information to third parties without Your consent, except:
            <ul>
                <li>To affiliates and third-party service providers to provide services (including support services) and information on our Site and related products, including marketing and advertising, and to support our business operations. We require these parties to handle Personal Data in accordance with our Privacy Notice.</li>
                <li>To authorities or third parties in order to enforce this Agreement or protect the rights, property or safety of Company, its affiliates, employees, users of the Site or the public. To governing regulatory authorities, including the US Food and Drug Administration, or as may otherwise be necessary for Company to comply with a legal obligation or demand.</li>
            </ul>
        </p>
        <p>
            If Company decides to reorganize or divest our business through sale, merger, or acquisition, Company may share personal information about You with actual or prospective purchasers. We will require any actual or prospective purchasers to treat this persona information in a manner consistent with this notice. You can find all related affiliates here: <a href="https://www.envistaco.com">https://www.envistaco.com</a>.
        </p>
        <p>
            <strong>Your rights:</strong> Depending on the legislation applicable to you, You have the right to see and get a copy of personal information about You that we maintain as well as to ask us to make corrections to inaccurate or incomplete personal information about You. You have the right to receive data You have provided to us in a machine-readable format and to transmit that data to another controller. You may also request the erasure of Your Personal Data or the restriction of its processing, or object to the processing of personal information about You or withdraw your consent. To seek access to personal information about You, to file a concern, complaint, or request for correction, or to opt-out of particular programs, please contact our Privacy Office by emailing us at <a href="mailto:privacy@envistaco.com">privacy@envistaco.com</a>.
        </p>
        <p>
            <strong>International Data Transfers:</strong> Company may in exceptional cases transfer Your personal information to, or store it at, a destination outside the European Economic Area ("EEA") Switzerland or the UK. It may also be processed by staff operating outside the EEA, Switzerland or UK who work for us or for one of our suppliers. Such staff may be engaged in, among other things, the provision of support services. Where the Company has to transfer Your personal information to other third countries, we will use appropriate approved safeguards or we will seek Your explicit consent. For further information, please do not hesitate to contact us at <a href="mailto:privacy@envistaco.com">privacy@envistaco.com</a>.
        </p>
        <p>
            <strong>EEA countries:</strong> Your Local Data Protection Authority is responsible for making sure that privacy law is followed in the relevant countries. For more information about your privacy rights, or if you are not able to resolve a problem directly with us and wish to make a compliant, contact your local Data Protection Authority: <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
        </p>
        <p>
            <strong>Security Measures:</strong> The Company will implement appropriate technical and organizational measures to protect personal information against accidental or unlawful destruction or accidental loss, alteration, unauthorized disclosure or access by unauthorized third parties and against other unlawful forms of processing. However, we cannot guarantee that unauthorized third parties will not thwart our security systems and gain unlawful access to your personal information.
            <br/><br/>
            Our privacy policy, which is incorporated to this Agreement by reference, is available in full here: <a href="https://envistaco.com/en/privacy-policy">https://envistaco.com/en/privacy-policy</a>.
        </p>

        <h3>Warranty</h3>
        <p>
            Any implied warranties are excluded to the fullest permitted extent. Neither We nor any of Our affiliated companies, presented on this Site make any warranty with respect to such product, express or implied, written or oral, including guarantees of merchantability of fitness for a particular purpose, to the fullest permitted extent. We do not warrant the accuracy or timeliness of the materials on this Site and have no liability for any errors or omissions in the materials. We do not warrant the accuracy, relevance or timeliness of any information You receive or expect to receive from a third party through this Site.
        </p>

        <h3>Limitation of Liability</h3>
        <p>Company shall not be liable for indirect, direct, incidental, special, punitive, consequential or any other monetary damages, fees, fines, penalties, or liabilities arising out of or relating to the use of the Site, the products, including the Software and/or in use of the Services. Neither We nor any other party involved in creating, producing or delivering this Site or on any website linked to from this Site shall be liable in any manner whatsoever for any direct, incidental, consequential, indirect or punitive damages arising out of access, use or inability to use this Site or any website linked to from this Site, or any errors or omissions in the content thereof.</p>
        <p>Company disclaims any liability, express or implies and shall have no responsibility for any direct, indirect, punitive or other damages arising out of on in connection with any errors in professional judgement or practice in the utilization of the Services. </p>
        <p>Company is not liable for any damage resulting from the use of the Site, including any temporarily unavailability of the Site, the products, including the Services and/or accidental removal of data.</p>

        <h3>Indemnity</h3>
        <p>You agree to indemnify and hold Us and our affiliates and their officers, directors, employees, and agents harmless from any and all claims, demands, losses, liabilities, and expenses (including attorneys' fees), arising out of or in connection with: (i) use of the Site, the products, including the Software and/or Services; (ii) Your breach or violation of any of these Terms; (iii) violation of the rights of any third party.</p>

        <h3>Intellectual Property Rights</h3>
        <p>All intellectual property rights on this Site are and shall remain Our exclusive property or of Our licensors, as applicable. All texts, graphics, interfaces, photographs, trademarks, logos, sounds, music, videos, artwork and computer code contained on this Site is owned, controlled or licensed by or to Us, and is protected by copyright, patent and trademark laws, and various other intellectual property rights and unfair competition laws.</p>

        <h3>Waiver and Severability</h3>
        <p>No failure or delay by either party in exercising any right under these Terms will constitute a waiver of that right. If any provision of these Terms are found to be deemed null and void, and the remaining provisions of this Agreement will remain in effect.</p>

        <h3>Governing law</h3>
        <p>If any part of this Agreement is found void and unenforceable, the remaining of the Agreement shall remain valid and enforceable according to its terms. The Company is entitled to change the terms of this Agreement; any and all changes shall become effective the earliest of You accepting them (e.g. at installation of an Update/Upgrade), or thirty (30) days after the communication of such change (by notice to you or by update of these terms). This Agreement shall be governed by the substantive laws of Switzerland. The exclusive place of jurisdiction shall be at the registered office of Company.</p>
    </div>
    </>
  );
};

export default TermsOfUse;
