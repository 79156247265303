// Delete a Practice api

import requestApi from '../request'
import { DELETE_PRACTICE } from '../../../redux/type/practices';

const deletePractice = async (recordId: string) => {
  const path = `Practices(${recordId})`;
  await requestApi(path, 'delete', DELETE_PRACTICE, null, "odata")
}

export default deletePractice

