import { RecordStatus } from "../shared/enums/RecordStatus";
import { UserRole } from "../shared/enums/UserRole";
import http from "./api/http";
import getLoadedConfig from "./appConfigService";

export interface IUserModel {
  recordId: string;
  userName: string;
  emailAddress: string;
  role: string;
}
export interface IUserList {
  count?: number;
  value: IUserModel[];
}

export interface IInfoModel {
  title: string;
  build: string;
}

export interface IUserRequest {
  userName: string;
  emailAddress: string;
  role: UserRole;
}

export interface IEditUserRequest extends IUserRequest{
  recordId: string;
}

export const addUserAsync = async (user: IUserRequest): Promise<IUserModel> => {
  const config = getLoadedConfig();

  const req = await http.post(config.apiUrl + "Users/CreateCSDUser", user);

  return req.data[0];
};

export const editUserAsync = async (
  user: IEditUserRequest
): Promise<IUserModel> => {
  const config = getLoadedConfig();

  const req = await http.put(config.apiUrl + `Users/UpdateCSDUser?key=${user.recordId}`, user);

  return req.data[0];
};

export const deleteUserAsync = async (recordId: string) => {
  const config = getLoadedConfig();

  const req = await http.delete(config.apiUrl + `Users/DeleteCSDUser?key=${recordId}`);

  return req.data;
};

export const getBackendVersionAsync = async () => {
  const config = getLoadedConfig();

  let url = new URL(config.apiUrl.toString());

  const req = await http.get<IInfoModel>(url.origin + "/version");

  return req.data.build;
};
