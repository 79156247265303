import Box from "@mui/material/Box";
import { configContext } from "../../../shared/useAppConfig";
import { getBackendVersionAsync } from "../../../services/userService";
import React, { useMemo, useState } from "react";
import { useTranslation } from 'react-i18next'; 


interface IInfoDialogProps {
  isOpen: boolean;
  close: () => void;
}

export default function AppInfoDialog() {
  const config = React.useContext(configContext);
  const [backendVersion, setBackendVersion] = useState<string>();
  const {t} = useTranslation();

  useMemo(
    async () =>
      await getBackendVersionAsync().then(value => setBackendVersion(value)),
    []
  ); 

  return (
    <div>
      <Box ml="20px">
        <p style={{ fontSize: "14px", fontWeight: 700 }}>
        {t('AppInfo')}:
        </p>
        <ul style={{ paddingLeft: "20px" }}>
          {console.log(backendVersion)}
          <li>{t('AppInfo')}: {config?.version}</li>
          <li>{t('BackendVersion')}: {backendVersion}</li>
          <li>{t('ReleaseDate')}: {config?.release_date}</li>
          <li>{t('CommitHash')}: {config?.git_commit}</li>
        </ul>
      </Box>
    </div>
  );
}
