import getLoadedConfig from "../appConfigService";
import { store } from '../../redux/store';
import http from "./http";

export default async (path: string, method: 'get' | 'post' | 'put' | 'delete', action: string, body?: any, apiType: "standard" | "odata" = "standard") => {

  return new Promise<any>((resolve, reject) => {
    store.dispatch({ type: `@${action}_PENDING` })
    const baseApiPath = apiType === "odata" ? getLoadedConfig().oDataUrl : getLoadedConfig().apiUrl;
    http[method](`${baseApiPath}${path}`, body).then((response) => {
      if (response.status >= 200 || response.status <= 300)
        store.dispatch({ type: `@${action}_SUCCESS`, data: response.data })

      resolve("");
    }).catch(err => {
      store.dispatch({ type: `@${action}_FAIL`, data: err })
      reject("");
    });
  });
}
