import {
  GET_PRACTICES_SUCCESS,
  GET_PRACTICES_PENDING,
  GET_PRACTICES_FAIL,
  UPDATE_PRACTICES_SUCCESS,
  UPDATE_PRACTICES_FAIL,
  DELETE_PRACTICE_FAIL,
  ADD_PRACTICES_SUCCESS,
  ADD_PRACTICES_FAIL,
  DELETE_PRACTICE_PENDING,
  DELETE_PRACTICE_SUCCESS,
  RESET_DELETE_ERROR,
  RESET_ADD_ERROR
} from '../type/practices'

import {
  GET_DEVICES_SUCCESS,
} from '../type/device'

import { IPracticeAction } from '../action/practices'
import IPractice from '../../interfaces/IPractice'

export interface IPracticeState {
  practices: {
    [key: string]: IPractice
  },
  listPractices: {
    data: Array<string>,
    count: number,
    pending: boolean,
    delete: {
      errorMessage: string,
      pending: boolean
    }
    add: {
      errorMessage: string,
      pending: boolean
    }
  }
}

const initialState: IPracticeState = {
  practices: {},
  listPractices: {
    data: [],
    count: 0,
    pending: false,
    delete: {
      errorMessage: "",
      pending: false
    },
    add: {
      errorMessage: "",
      pending: false
    }
  }
}

export default function (
  state: IPracticeState = initialState,
  action: IPracticeAction
) {
  switch (action.type) {
    case GET_PRACTICES_PENDING:
      return {
        ...state,
        listPractices: {
          ...state.listPractices,
          pending: true,
        }
      }

    case GET_PRACTICES_SUCCESS:
      return {
        ...state,
        practices: {
          ...state.practices,
          ...action.data.value.reduce((a: any, b: any) => ({ ...a, [b.recordId]: b }), {})
        },
        listPractices: {
          ...state.listPractices,
          data: action.data.value.map((practice: any) => practice.recordId),
          count: action.data['@odata.count'],
          pending: false
        }
      }
    case GET_PRACTICES_FAIL:
      return {
        ...state,
        listPractices: {
          ...state.listPractices,
          pending: false,
        }
      }

    case ADD_PRACTICES_SUCCESS:
    case RESET_ADD_ERROR:
      return {
        ...state,
        listPractices: {
          ...state.listPractices,
          add: {
            ...state.listPractices,
            errorMessage: "",
            pending: false
          }
        }
      }


    case UPDATE_PRACTICES_SUCCESS:
    case ADD_PRACTICES_SUCCESS:
    case RESET_ADD_ERROR:
      return {
        ...state,
        practices: {
          ...state.practices,
          [action.data.recordId]: {
            ...state.practices[action.data.recordId],
            name: action.data.name,
            emailAddress: action.data.emailAddress,
          }
        }
      }

    case UPDATE_PRACTICES_FAIL:
    case ADD_PRACTICES_FAIL:
      return {
        ...state,
        listPractices: {
          ...state.listPractices,
          add: {
            ...state.listPractices,
            errorMessage: action.data.message,
            pending: false
          }
        }
      }

    case DELETE_PRACTICE_PENDING:
      return {
        ...state,
        listPractices: {
          ...state.listPractices,
          delete: {
            ...state.listPractices,
            errorMessage: "",
            pending: true
          }
        }
      }
    case DELETE_PRACTICE_FAIL:
      return {
        ...state,
        listPractices: {
          ...state.listPractices,
          delete: {
            ...state.listPractices,
            errorMessage: action.data.message,
            pending: false
          }
        }
      }

    case DELETE_PRACTICE_SUCCESS:
    case RESET_DELETE_ERROR:
      return {
        ...state,
        listPractices: {
          ...state.listPractices,
          delete: {
            ...state.listPractices,
            errorMessage: "",
            pending: false
          }
        }
      }
    case GET_DEVICES_SUCCESS:
      return {
        ...state,
        practices: {
          ...state.practices,
          ...action.data.value.filter((item: any) => item?.practiceRecordId).reduce((a: any, b: any) => ({ ...a, [b.practiceRecordId]: b.practice }), {})
        },
      }
  }
  return state
}