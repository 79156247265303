import http from "../services/api/http";
import getLoadedConfig from "../services/appConfigService";
import IPractice from "../interfaces/IPractice";

export const getPractices = async (query: string): Promise<IPractice[]> => {
  const config = getLoadedConfig();
  const path = `Practices?$filter=contains(Name,'` + query + `')`;
  const req = await http.get<any>(config.oDataUrl + path);
  return req.data["value"] as IPractice[];
}
