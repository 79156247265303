import { IRootStoreState } from '../../reducer'

import { createSelector } from 'reselect'

export const pendingListExchangeLab = (state: IRootStoreState) => state.exchangeLab.listExchangeLabs.pending
const selectListExchangeLab = (state: IRootStoreState) => state.exchangeLab.listExchangeLabs.data
const selectExchangeLab = (state: IRootStoreState) => state.exchangeLab.exchangeLabs
const selectAddress = (state: IRootStoreState) => state.address.address

export const selectListExhangeLab = createSelector(
  selectListExchangeLab,
  selectExchangeLab,
  selectAddress,
  (listPractices, echangeLab, address) => listPractices.map((item) => ({
    emailAddress: echangeLab[item].emailAddress,
    name: echangeLab[item].name,
    address: {
      line1: address[echangeLab[item].addressId].line1,
      city: address[echangeLab[item].addressId].city,
      region: address[echangeLab[item].addressId].region,
      zip: address[echangeLab[item].addressId].postCode,
      country: address[echangeLab[item].addressId].countryCode,
    }
  }))
)