// Function for get Practice
import getLoadedConfig from '../../appConfigService'
import http from '../http'

export const getCases = async (skip: number|null = null, top: number|null = null, startDate?: string, endDate?:string, members?: string[]) => {
  let url = getLoadedConfig().oDataUrl + `ConnectCases?$count=true&$orderby=acquisitionDate desc`;
  url = top !== null ? `${url}&$top=${top}` : url;
  url = skip !== null ? `${url}&$skip=${skip}` : url;


  if(startDate && endDate)
    url = `${url}&$filter=sentDate gt ${startDate} and sentDate lt ${endDate}`;

  const req = await http.get<any>(url);
  const res = req.data;
  return {
    count: res["@odata.count"],
    value: res["value"]
  }
}

export const exportCases = async () => {
  const url = getLoadedConfig().apiUrl + `ConnectCases/Export`;
  return http.get(url);
}