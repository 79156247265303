import {
  GET_EXCHANGE_LAB_SUCCESS,
  GET_EXCHANGE_LAB_PENDING,
  GET_EXCHANGE_LAB_FAIL,
} from '../type/exchangeLab'

import { IExchangeLabAction } from '../action/exchangeLab'
import IExchangeLab from '../../interfaces/IExchangeLab'
  
export interface IExchangeLabState {
  exchangeLabs: {
    [key: string]: IExchangeLab
  },
  listExchangeLabs: {
    data: Array<string>,
    count: number,
    pending: boolean,
  }
}
  
const initialState: IExchangeLabState = {
  exchangeLabs: {},
  listExchangeLabs: {
    data: [],
    count: 0,
    pending: false,
  }
}
  
export default function (
  state: IExchangeLabState = initialState,
  action: IExchangeLabAction
) {
  switch (action.type) {
    case GET_EXCHANGE_LAB_PENDING:
      return {
        ...state,
        listExchangeLabs: {
        ...state.listExchangeLabs,
          pending: true,
        }
      }
  
    case GET_EXCHANGE_LAB_SUCCESS:
      return {
        ...state,
        exchangeLabs: {
          ...state.exchangeLabs,
          ...action.data.value.reduce((a: any, b: any) => ({ ...a, [b.recordId]: b }), {})
        },
        listExchangeLabs: {
          ...state.listExchangeLabs,
          data: action.data.value.map((practice: any) => practice.recordId),
          count: action.data['@odata.count'],
          pending: false
        }
      }
    case GET_EXCHANGE_LAB_FAIL:
      return {
        ...state,
        listExchangeLabs: {
          ...state.listExchangeLabs,
          pending: false,
        }
      }
    }
  return state
}