// Function for get Practice

import { GET_DEVICES } from '../../../redux/type/device';
import { IUserState } from '../../../redux/reducer/user';
import { UserRole } from "../../../shared/enums/UserRole";
import http from '../http'
import getLoadedConfig from '../../appConfigService'
import { store } from '../../../redux/store';

interface IGetDevicesParams {
  filter?: string,
  top: number,
  skip: number,
  user: IUserState
}

const formatLabsName = (devicesConf: any): string => {
  return devicesConf ? devicesConf.map((it: any) => it?.partner?.name)?.join(", ") : ""
};

const isCustomerRefValid = (sapId: any, memberRef: any):boolean => {
  return true;
/*  if (sapId && sapId.length > 0 && memberRef && memberRef.length > 0) {
    var refArray = memberRef.split(';')
    return refArray.some(e => e === sapId)
  } else {
    return false
  }*/
}

const getDevices = async (params: IGetDevicesParams) => {
  let path = ""

  // Use endpoint by role
  if (
    UserRole.FLP_ADMINISTRATOR === params.user.role ||
    UserRole.FLP_SUPPORT === params.user.role
  ) {
    path = `Devices?$expand=DeviceConfiguration($expand=Member,Practice($expand=address), PartnerDevices($expand=Partner))&$count=true&$top=${params.top}&$skip=${params.skip}&$orderby=SerialNumber asc${params.filter ? `&$filter=${params.filter}` : ''}`
  } else if (UserRole.MEMBER_MANAGER === params.user.role || UserRole.MEMBER_SUB_MANAGER === params.user.role) {
    path = `Devices/GetForMember()?$expand=DeviceConfiguration($expand=Member,Practice($expand=address), PartnerDevices($expand=Partner))&$count=true&$top=${params.top}&$skip=${params.skip}&$orderby=SerialNumber asc${params.filter ? `&$filter=${params.filter}` : ''}`
  }


  const config = getLoadedConfig()



  return new Promise<any>(async (resolve, reject) => {
    // start call api
    store.dispatch({ type: `@${GET_DEVICES}_PENDING` })
    let devicesValue: any = []

    // Get devices list
    await http.get(`${config.oDataUrl}${path}`).then(async (deviceList) => {
      if (deviceList.status >= 200 || deviceList.status <= 300) {
        deviceList.data.value.map(async (device: any) => {
          if(!!device.serialNumber) {
               devicesValue.push({
                ...device,
                configuration: device.deviceConfiguration?.partnerDevices?.length > 0 ? "Managed" : "Standard",
                memberName: device.deviceConfiguration?.member?.name,
                memberId: device.deviceConfiguration?.member?.recordId,
                member: device.deviceConfiguration?.member,
                practiceId: device.deviceConfiguration?.practice?.recordId,
                practice: device.deviceConfiguration?.practice,
                labName: device.deviceConfiguration?.partnerDevices?.length > 0 ?  formatLabsName(device.deviceConfiguration?.partnerDevices) : "",
                configRecordId: device.deviceConfiguration ? "" : device.deviceConfiguration?.recordId,
                sapId: device.userRef,
                memberRef: device.deviceConfiguration?.member?.userReference
              })
            }
          })
            store.dispatch({ type: `@${GET_DEVICES}_SUCCESS`, data: {
              ...deviceList.data,
              value: devicesValue
            }})
        }

      resolve("");
    }).catch(err => {
      store.dispatch({ type: `@${GET_DEVICES}_FAIL`, data: err })
      reject("");
    });
  })
}

export default getDevices