import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import css from "./CustomizedDialog.module.scss";
import { Box } from '@mui/material';

interface IDialogProps {
    title: string;
    children: any;
    openDialog: boolean;
    setOpenDialog: (openDialog: boolean) => void;
}

export default function CustomizedDialog(props: IDialogProps) {
    const { title, children, openDialog } = props;

    return (
        <div>
            <Dialog open={openDialog}>
              <DialogTitle>
                <Box fontWeight={700} fontSize="18px">
                    {title}
                </Box>
              </DialogTitle>
              <DialogContent className={css.dialogContent}>
                  {children}
              </DialogContent>
            </Dialog>
        </div>
    )
}
