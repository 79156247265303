import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { Stack } from '@mui/material';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { IExchangeLabModel } from "../../../services/labExchangeModel";
import { getAssociatedPartners } from "../../../services/labExchangeDeviceService";
import IPractice from "../../../interfaces/IPractice";
import { getPractices, getMembers } from "../../../api";
import { Alert } from "@mui/material";
import { useTranslation } from 'react-i18next'; 
import IMember from "../../../interfaces/IMember";
  

interface IProps {
  deviceId: string;
  userId: string;
  loading?: boolean;
  errorMessage?: string;
  exchangeLabsForMember?: IExchangeLabModel[];
  practice?: IPractice;
  member?: IMember;
  saveData?: (labs: IExchangeLabModel[], practice: IPractice | undefined, member: IMember|undefined) => void;
  closeDialog?: () => void;
}

export default function EditDeviceFlpDialog(props: IProps) {
  const {
    saveData,
    closeDialog,
    deviceId,
    userId,
    loading,
    errorMessage,
    exchangeLabsForMember,
    practice,
    member,
  } = props;
 
  const {t} = useTranslation();
  const [exchangeLabsDevice, setExchangeLabsDevice] =
    React.useState<IExchangeLabModel[]>();
  const [selectedLabs, setSelectedLabs] = React.useState<IExchangeLabModel[]>(
    []
  );
  const [practiceList, setPracticeList] = React.useState<IPractice[]>([]);
  const [selectedPractice, setSelectedPractice] = React.useState<IPractice | undefined>(undefined);

  const [subMembersList, setSubMembersList] = React.useState<IMember[]>([]);
  const [selectedSubMember, setSelectedSubMember] = React.useState<IMember | undefined>(undefined);

  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [localErrorMessage, SetLocalErrorMessage] =
    React.useState<any>(errorMessage);

  React.useEffect(() => {

    setSelectedPractice(practice);
    setSelectedSubMember(member);
    // Get practices list
    getPractices("")
      .then((data: IPractice[]) => {
        setPracticeList(data);
      })
      .catch((error: any) => {
        if (error?.statusCode !== 404)
          SetLocalErrorMessage(t('FailedPracticesListMsg'));
      })
      .finally(() => {
        setLoading(false);
      });

      // Get members list
      getMembers("")
        .then((data: IMember[]) => {
          setSubMembersList(data);
        })
        .catch((error: any) => {
          if (error?.statusCode !== 404)
            SetLocalErrorMessage(t('FailedSubMembersListMsg'));
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);

  React.useEffect(() => {
    if (userId) {
      setLoading(true);

      // Get associated device to the current device
      if (
        !Boolean(exchangeLabsForMember) ||
        exchangeLabsForMember?.length === 0
      ) {
        SetLocalErrorMessage(
          t('NoLabExchangeDeviceMsg')
        );
        setLoading(false);
      } else {
        if (deviceId) {
          getAssociatedPartners(deviceId)
            .then((data: IExchangeLabModel[]) => {
              setExchangeLabsDevice(data);
              setSelectedLabs(data);
            })
            .catch((error: any) => {
              if (error?.statusCode !== 404)
                SetLocalErrorMessage(
                  t('FaildAssociatedLabsMsg')
                );
              //else no lab exchange associated to that device
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          // no deviceId device configuration has not been created
          // it will be created on saving
          setLoading(false);
        }
      }
    }
  }, [userId, deviceId]);

  const save = () => {
    if (saveData) {
      saveData(selectedLabs, selectedPractice, selectedSubMember);
    }
  };

  return (
    <>
      <div>
        {localErrorMessage && (
          <Alert severity="error">{localErrorMessage}</Alert>
        )}
        {!errorMessage && isLoading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress
              size={20}
              style={{ color: "#898a8d", padding: 2 }}
            />
            {t('WaitingDeviceMsg')}
          </div>
        )}
        {/* If there is no lab exchange do not propose autocomplete */}
        {!isLoading &&
          exchangeLabsForMember &&
          exchangeLabsForMember.length !== 0 && (
            <Autocomplete
              multiple
              id="tags-outlined"
              options={exchangeLabsForMember ? exchangeLabsForMember : []}
              getOptionLabel={(option: IExchangeLabModel) => option.name}
              value={selectedLabs}
              getOptionSelected={(
                option: IExchangeLabModel,
                value: IExchangeLabModel
              ) => option.recordId === value.recordId}
              filterSelectedOptions
              onChange={(
                event: any,
                updateValue: IExchangeLabModel[],
                reason: any,
                details: any
              ) => {
                setSelectedLabs(updateValue);
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t('AssignedExchangeLaboratory')}
                  placeholder={t('ExchangeLaboratory')}
                />
              )}
            />
          )}
        <Stack sx={{ height: 10 }}></Stack> 
        {!isLoading && practiceList && practiceList.length !== 0 && (
          <Autocomplete
            id="tags-outlined"
            options={practiceList ? practiceList : []}
            getOptionLabel={(option: IPractice) => option.name}
            value={selectedPractice}
            onChange={(event: any, value: any) => setSelectedPractice(value)}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={t('AssignedPractice')}
                placeholder={t('Practice')}
              />
            )}
          />
        )}        
        <Stack sx={{ height: 10 }}></Stack> 
        {!isLoading && subMembersList && subMembersList.length !== 0 && (
          <Autocomplete
            id="tags-outlined"
            options={subMembersList ? subMembersList : []}
            getOptionLabel={(option: IMember) => option.name}
            value={selectedSubMember}
            onChange={(event: any, value: any) => setSelectedSubMember(value)}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={t('AssignedSubMember')}
                placeholder={t('TeamMember')}
              />
            )}
          />
        )}
      </div>
      <div>
        {closeDialog && ( // remove this condition when the refacto is DONE
          <div className="dialog-actions">
            <Button
              onClick={closeDialog}
              variant="outlined"
              className="btn-cancel"
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={() => save()}
              variant="outlined"
              className="btn-confirm"
            >
              {loading ? (
                <CircularProgress size={20} style={{ color: "#898a8d" }} />
              ) : (
                <>{t('Save')}</>
              )}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
