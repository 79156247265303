import { GET_PRACTICES_SUCCESS, UPDATE_PRACTICES_SUCCESS } from '../type/practices'
import { GET_EXCHANGE_LAB_SUCCESS } from '../type/exchangeLab'
import { IPracticeAction } from '../action/practices'
import IAddress from '../../interfaces/IAddress'
import { CountryCode } from '../../shared/enums/CountryCode';

export interface IAddressState {
  address: {
    [key: string]: IAddress
  }
}

const initialState: IAddressState = {
  address: {}
}

const FormatAddress = (address: any) => {
  if (address) {
    const countrySelected = Object.entries(CountryCode).map(([key, value]) => ({ key: key, country: value })).filter(item => item.key === address.countryCode);
    const line2 = address.line2 ? `, ${address.line2}` : "";
    const line3 = address.line3 ? `, ${address.line3}` : "";
    const region = address.region ? `, ${address.region}` : "";
    return `${address.line1}${line2}${line3}, ${address.city}, ${address.postCode}${region}, ${countrySelected[0].country ?? "N/A"}`;
  }
  return "";
};

export default function (
  state: IAddressState = initialState,
  action: IPracticeAction
) {
  switch (action.type) {
    case GET_PRACTICES_SUCCESS:
    case GET_EXCHANGE_LAB_SUCCESS:
      return {
        ...state,
        address: {
          ...state.address,
          ...action.data.value.reduce((a: any,b: any) => ({...a, [b.address.recordId]: {
            ...b.address,
            label: FormatAddress(b.address)
          }}), {})
        },
      }
    case UPDATE_PRACTICES_SUCCESS:
      return {
        ...state,
        address: {
          ...state.address,
          [action.data.addressId] : {
            ...action.data.address,
            label: FormatAddress(action.data.address)
          }
        }
      }
    }
    return state
}