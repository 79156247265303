import React, { useState, useEffect } from 'react'

// Interface
import IPracticeForm, { IPractice } from './IPracticeForm';
import { IAddress } from '../../Molecules/AddressField/IAddressField';

//Style
import styles from './PracticeForm.module.scss'

import { InputForm, AddressField, Dialog } from '../..';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';

const PracticeForm: React.FC<IPracticeForm> = ({
  practice,
  onChange,
  open,
  setOpen,
  saveRequest,
  errorMessage
}) => {

  //const translation
  const {t} = useTranslation()
  
  const [practiceLocal, setPractice] = useState(practice)
  const [validate, setValidate] = useState(false)

  useEffect(() => {
    practiceLocal.name !== '' &&
    practiceLocal.address.line1 !== '' &&
    practiceLocal.address.city !== '' &&
    practiceLocal.address.zip !== '' &&
    practiceLocal.address.country !== '' &&
    validateEmail(practiceLocal.email) ? setValidate(false) : setValidate(true)
  }, [practiceLocal])

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleOnChange = (newPractice: IPractice) => {
    setPractice(newPractice)
    onChange(newPractice)
  }

  return(
    <Dialog onClose={() => setOpen(false)} title={t('Add Practices')} open={open}>
      <>
      { errorMessage && <Alert severity="error">{errorMessage}</Alert> }
      </>
      <InputForm
        className={styles.field}
        title={t('Practice name')}
        value={practice.name}
        placeholder={t('Practice name')}
        onChange={(newValue: string) => handleOnChange({
          ...practice,
          name: newValue
        })}
      />
      <InputForm
        className={styles.field}
        title={t('Practice email')}
        value={practice.email}
        placeholder={t('Practice placeholder')}
        onChange={(newValue: string) => handleOnChange({
          ...practice,
          email: newValue
        })}
      />
      <AddressField
        address={practice.address}
        onChange={(newValue: IAddress) => handleOnChange({
          ...practice,
          address: newValue
        })}
      />
      <div className="dialog-actions">
        <Button
          onClick={() => setOpen(false)}
          variant="outlined"
          className="btn-confirm"
        >{t('Cancel')}</Button>
        <Button
          disabled={validate}
          onClick={() => {
            saveRequest()
          }}
          variant="outlined"
          className="btn-confirm"
        >{t('Save')}</Button>
      </div>
    </Dialog>
  )
}

export default PracticeForm