import React, { useEffect } from "react";
import { makeStyles } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import {
  Button
} from "@mui/material";
import premiumLogo from "../../../assets/images/premium.svg";
import useDialogState from "../../../shared/useDialogState";
import Dialogs from "../../Dialogs";
import ConfirmDisableDialog from "../../Dialogs/ConfirmDisableDialog";
import {
  ISupportDeviceColumn,
  IDeviceRowData,
} from "../../../shared/interfaces/IDeviceConf";
import { useState } from "react";
import { AppType } from "../../../shared/enums/AppType";
import { DialogType } from "../../../shared/enums/DialogType";
import useAuthService from "../../../services/authService";
import {
  IDeviceColumnFLP,
  IDeviceDataFLP,
} from "../../../shared/interfaces/IDeviceList";
import {
  IUserColumnFlp,
  IUserDataFlp,
} from "../../../shared/interfaces/IUserDataFlp";
import AddEditUserFlpDialog from "../../Dialogs/AddEditUserFlpDialog";
import { UserRole } from "../../../shared/enums/UserRole";
import { useTranslation } from 'react-i18next';
import classes from './DenseTable.module.scss'

interface IDenseTableProps {
  columns:
    | ISupportDeviceColumn[]
    | IDeviceColumnFLP[]
    | IUserColumnFlp[];
  rows:
    | IDeviceRowData[]
    | IDeviceDataFLP[]
    | IUserDataFlp[];
  totalCount: number;
  type: string;
  pageNumber?: number;
  itemPerPage?: number;
}

type DenseColumn =
  | ISupportDeviceColumn
  | IDeviceColumnFLP
  | IUserColumnFlp;

export default function DenseTable(props: IDenseTableProps) {
  const { columns, rows, totalCount, type, pageNumber, itemPerPage } = props;
  const [isOpen, open, close] = useDialogState();
  const [rowData, setRowData] = React.useState<any>([]);
  const [recordCount, setRecordCount] = React.useState<number>(0);
  const [editRowData, setEditRowData] = React.useState<any>([]);
  const [editRowIndex, setEditRowIndex] = React.useState<number>(-1);
  let [rowIndex, setRowIndex] = React.useState<any>(-1);
  const [validated, setValidated] = React.useState(false);
  const [fieldConflictError, setFieldConflictError] = React.useState("")

  const [dialogType, setDialogType] = useState("");
  const [title, setTitle] = useState(""); 
 
  const {t} = useTranslation();
  const authService = useAuthService();
  const [userRole, setUserRole] = useState<UserRole | UserRole>(
    UserRole.NOT_SET
  );

  const renderDialogContentByType = (dialogType: string) => {
    switch (dialogType) {
      case DialogType.CONFIRM_DISABLE_DIALOG:
        return <ConfirmDisableDialog />;
      case DialogType.ADD_USERFLP:
        return <AddEditUserFlpDialog />;
      case DialogType.EDIT_USERFLP:
        return <AddEditUserFlpDialog editRowData={editRowData} />;
      default:
        return <></>;
    }
  };

  // Handle dialog close event
  const handleDialogClose = (
    isOpen: boolean,
    cancelled: boolean,
    data: any,
    error?: boolean
  ) => {
    if (error) {
      setFieldConflictError(data)
    } else {
      setFieldConflictError("")

      // Do nothing if the dialog was cancelled
      if (!cancelled) {
        // Add user flp
        if (
          dialogType === DialogType.ADD_USERFLP ||
          dialogType === DialogType.EDIT_USERFLP
        ) {
          if (data) {
            // Add new user in front
            if (-1 === editRowIndex) {
              rowData.push(data as IUserDataFlp);
            } else if (dialogType === DialogType.EDIT_USERFLP) {
              rowData[editRowIndex] = data;
            }
          }
        }

        // Remove row from data
        if (dialogType === DialogType.CONFIRM_DELETE) {
          rowData.splice(editRowIndex, 1);
        }
      }

      if (
        dialogType === DialogType.ADD_MEMBER ||
        dialogType === DialogType.EDIT_MEMBER
      ) {
        setValidated?.(false);
      }

      close();
    }
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      const role = await authService.getUserRole();
      setUserRole(role);
    };
    fetchUserRole();
    setRowData(rows);
    setRecordCount(totalCount);

  }, [rowData, totalCount, rows, authService]);

  return (
    <>
      <div className="add-bar">
        <div>
          {type === AppType.USERSFLP && (
            <Button
              variant="contained"
              onClick={() => {
                setTitle(t('AddUser'));
                setDialogType(DialogType.ADD_USERFLP);
                setEditRowIndex(-1);
                open();
              }}
            >
              <AddIcon />
              Add User
            </Button>
          )}
        </div>
        <p className={classes.nbResult}>
          {recordCount > 1 ? recordCount + " results" : recordCount + " result"}
        </p>
      </div>
      {isOpen && (
        <Dialogs
          dialogType={dialogType}
          appType={type}
          isOpen={true}
          onDialogClosed={handleDialogClose}
          title={title}
          content={renderDialogContentByType(dialogType)}
          confirmDisable={rowIndex}
          editIndex={editRowIndex}
          editData={editRowData}
          validated={validated}
        />
      )}

      <div style={{ display: "flex" }}>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="customized sticky table" size="small">
            <TableHead>
              <TableRow>
                {columns.map((column: DenseColumn) => (
                  (column.id !== "actions") &&
                  <TableCell
                    key={column.id}
                    align={column.align}
                    width={column.minWidth}
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {column.id === "premium" ? (
                      <img
                        src={premiumLogo}
                        alt="Premium"
                        className={classes.premiumLogo}
                      />
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map((row: any, index: number) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    className={classes.tableRow}
                    tabIndex={-1}
                    key={row.recordId}
                  >
                    {columns.map((column: DenseColumn) => {
                      return (column.id !== "actions") &&
                       (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                        >
                          {column.id === "no" && pageNumber && (
                            <>
                              {(pageNumber - 1) *
                                (itemPerPage ? itemPerPage : 0) +
                                index +
                                1}
                            </>
                          )}

                          {column.id === "activation" && (
                            <>{row?.activationDate}</>
                          )}

                          {column.id === "recordId" ? (
                            index + 1
                          ) : (
                            eval("row." + column.id)
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
