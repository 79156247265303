// Function for get Member

import requestApi from '../request'
import { GET_MEMBER_ID } from '../../../redux/type/user';

const getMemberId = async (userId: string) => {
  const path = `Members?$filter=userId eq ${userId}&$select=recordId`;
  requestApi(path, 'get', GET_MEMBER_ID, null, "odata")
}

export default getMemberId