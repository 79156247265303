import React, { createContext, useEffect, useState, useContext } from "react";
import Wait from "./Wait";
import { loadConfig, IAppConfig } from "../services/appConfigService";

export const configContext = createContext<IAppConfig | undefined>(undefined);

export const AppConfigProvider: React.FC = ({ children }) => {
  const [config, setConfig] = useState<IAppConfig | undefined | null>();
  useEffect(() => {
    loadConfig()
      .then(setConfig)
      .catch(() => setConfig(null));
  }, []);

  // Config is loading ....
  if (typeof config === "undefined") {
    return (
      <div style={{ height: "100vh" }}>
        <div className="centered">
          <Wait style={{ padding: "8px 20px" }} />
        </div>
      </div>
    );
  }

  // Failed to load config
  if (config === null) {
    return (
      <div className="centered">
        <h4 className="elevated" style={{ width: 340 }}>
          Error :(
        </h4>
        <p>Failed to load application configuration.</p>
      </div>
    );
  }

  // Config loaded ...
  // console.log("CONFIG LOADED:", config);
  return (
    <configContext.Provider value={config}>{children}</configContext.Provider>
  );
};

const useAppConfig = () => {
  const config = useContext(configContext);
  if (!config) {
    throw new Error("AppConfig not available on context!");
  }
  return config;
};

export default useAppConfig;
