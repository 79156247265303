import { Redirect, Route, Switch } from "react-router-dom";
import PageHeader from "../PageHeader/PageHeader";
import css from "../../components/App/App.module.scss";
import Login from "../Login";
import Home from "../Home";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "../../services/authService";
import Footer from "../PageFooter/PageFooter";
import TermsOfUse from "../../page/TermsOfUse";

const NotLoggedIn = () => {
  return (
    <div>
      <Switch>
          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/login" component={Login} />
          <Redirect to="/login" />
      </Switch>
          
      <div className="hcentered">
        <Footer />
      </div>
    </div>
  );
};

const LoggedIn = () => {
  return (
    <div className={css.home}>
      <div className={css.content}>
        <div className="hcentered">
          <PageHeader />
        </div>

        <Switch>
          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/" component={Home} />
          <Redirect to="/" />
        </Switch>
        
        <div className="hcentered">
          <Footer />
        </div>
      </div>
    </div>
  );
};

const DealerRoutes = () => {
  return (
    <div style={{ width: "100%", height: "100%", alignItems: "center" }}>
      <UnauthenticatedTemplate>
        <NotLoggedIn />
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <LoggedIn />
      </AuthenticatedTemplate>
    </div>
  );
};

export default DealerRoutes;
