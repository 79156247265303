import React, { useState, useEffect } from "react";
import { useSelector  } from 'react-redux';
import css from "./ExchangeLabForm.module.scss";
import { TextField, Button } from "@mui/material";
import {
  getExchangeLabsFilteredAsync,  
  getAllExchangeLabsForMemberAsync
} from "../../../../services/labExchangeService";
import isEmail from "validator/lib/isEmail";
import { UserRole } from "../../../../shared/enums/UserRole";
import { Alert, Autocomplete } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { IExchangeLabModel } from "../../../../services/labExchangeModel";
import { AddressField } from "../..";
import { IAddress } from "../../Molecules/AddressField/IAddressField";
import { useTranslation } from 'react-i18next';

import { selectUser } from '../../../../redux/selector/user';
import { selectListExhangeLab } from '../../../../redux/selector/exchangeLab';

const ExchangeLabForm = (props: {
  add?: (data: any) => void;
  errorMessage?: string;
  loading?:boolean;
  closeDialog: () => void;
}) => {
  const { add, closeDialog, errorMessage,loading } = props;
  const [labName, setLabName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [labNames, setLabNames] = useState<string[]>([]);  
  const [labEmails, setLabEmails] = useState<string[]>([]);
  const [exchangeLabsForMember, setExchangeLabsForMember] = useState<IExchangeLabModel[]>();
  const [exchangeLabs, setExchangeLabs] = useState<IExchangeLabModel[]>();
  const [addressSource, setAddressSource] = useState<any>();
  const [loadingOtpion, setLoadingOtpion] = useState<boolean>(false);
  const [lock, setLock] = useState<string>('')

  // 0 = initialisation
  // 1 = edit new laboratory by email
  // 2 = search by name laboratory
  // 3 = select laboratory by email or name
  const [state, setState] = useState<number>(0)

  const {t} = useTranslation()

  const [address, setAddress] = useState({
    line1: '',
    city: '',
    zip: '',
    country: '',
    region: '',
  })
  
  const user = useSelector(selectUser)
  const exchangeLabsTest = useSelector(selectListExhangeLab)
  // const loadingOptpionAll = useSelector(pendingListExchangeLab)
  
   useEffect(() => {

    if( (user.ID !== "") && (user.role === UserRole.MEMBER_MANAGER)  ) {
      setLoadingOtpion(true);
      getAllExchangeLabsForMemberAsync(user.ID).then( (labs:any) => {
        setExchangeLabsForMember(labs.value);
      }).finally(()=>{
        setLoadingOtpion(false);
      });
    }

    }, []);


  useEffect(() => {
    setLoadingOtpion(true);
    getExchangeLabsFilteredAsync().then((allPartners) => {

      // Filter allPartners by alReadyAssignedPartners for member
      let filteredPartners;
      if (exchangeLabsForMember !== undefined) {
        filteredPartners = allPartners.value?.filter((allPartner) =>
          !exchangeLabsForMember?.find(
            (assignPartner) => assignPartner.recordId === allPartner.recordId
          ));
      }
      //else we retrieve all the partners for the admin
      else
        filteredPartners = allPartners.value;

      setExchangeLabs(filteredPartners);

          // Sort lab name and email for better display
          setLabNames(filteredPartners?.map((option:IExchangeLabModel) => option?.name ));
          setLabEmails(filteredPartners?.map((option:IExchangeLabModel) => option?.emailAddress ));

        }).finally(()=>{
          setLoadingOtpion(false);
        });
        
  }, [exchangeLabsForMember]);

  useEffect(() => {
    if(undefined === exchangeLabs || emailAddress === "")
      return;

    const labModel = exchangeLabs.find((it: any)=> emailAddress.toUpperCase() === it.emailAddress.toUpperCase() );
    if(labModel)
    {
      setLabName(labModel.name);
      // Lab can have been declared by cs connect without adress
      setAddressSource({
        line1: labModel?.address?.line1 ?? '',
        city: labModel?.address?.city ?? '',
        region: labModel?.address?.region ?? '',
        zip: labModel?.address?.postCode ?? '',
        country: labModel?.address?.countryCode ?? ''
      });
    }
    else {
      setAddressSource( (prevAddressSource: any) => {
        if (prevAddressSource) {
          clearAddress();
          setLabName('');
        }
        return undefined;
      });
    }

  },[exchangeLabs, emailAddress]);

  useEffect(() => {
    if(undefined === exchangeLabs || labName === "")
      return;

    const labModel = exchangeLabs.find((it: any)=> labName.toUpperCase() === it.name.toUpperCase() );
    if(labModel)
    {
      setEmailAddress(labModel.emailAddress);
      // Lab can have been declared by cs connect without adress
      setAddressSource({
        line1: labModel?.address?.line1 ?? '',
        city: labModel?.address?.city ?? '',
        region: labModel?.address?.region ?? '',
        zip: labModel?.address?.postCode ?? '',
        country: labModel?.address?.countryCode ?? ''
      });
    } else {
      setAddressSource( (prevAddressSource: any) => {
        if (prevAddressSource) clearAddress();
        return undefined;
      });
    }

  },[exchangeLabs, labName]);

  useEffect(() => {
    if(addressSource) {
      setAddress({
        line1: addressSource.line1 ?? '',
        city: addressSource.city ?? '',
        zip: addressSource.zip ?? '',
        country: addressSource.country ?? '',
        region: addressSource.region ?? '',
      })
    }

  },[addressSource]);

  const Validated = ():boolean => {
    let validate:boolean = isEmail(emailAddress);

      if(validate && !addressSource) {
        validate = validate && address.line1 !== "" && address.city !== "" && address.zip !== "" && address.country !== "";
      }
      return validate;
  };

  const clearAddress = () => {
    setAddress({
      line1: '',
      city: '',
      zip: '',
      country: '',
      region: '',
    })
  };

  const Submit = (e: any) => {
    e.preventDefault()

    const data:IExchangeLabModel = {
      recordId: "",
      name: labName,
      emailAddress: emailAddress,
      partnerType: "Exchange",
      enable: true,
      address : {
        recordId: "",
        line1: address.line1,
        line2: '',
        line3: '',
        city: address.city,
        region: address.region,
        postCode: address.zip,
        countryCode: address.country,
      }
    };
      add?.(data);
  }

  const resetField = () => {
    setEmailAddress('');
    setLabName('');
    setAddressSource(null);
    clearAddress();
    setState(0);
  }
  
  return (
    <div 
      style={{ minWidth: 520 }} className={css.exchangeLabDialog}>
      {errorMessage && (
        <Alert severity="error" >
          {errorMessage}
        </Alert>
      )}      
      <label  className={css.label} htmlFor="email">{t('Email')}:</label>
      {
      (<Autocomplete className={css.autocomplete}
        id="email"
        freeSolo
        disableClearable
        inputValue={emailAddress}
        onChange={(e,v:string) => {
          setEmailAddress(v)
          setState(1)
          if(!v) resetField()
        }}
        onInputChange={(e,v:string) => {
          setEmailAddress(v)
          setState(1)
          if(!v) resetField()
        }}
        options={labEmails}
        getOptionSelected={ (option: string, value: string) => option === value }
        loading={loadingOtpion}
        disabled={state === 2}
        style={{margin: 0}}
        renderInput={(params) => (
          <TextField
            className={css.input}
            {...params}
            InputProps={{
              ...params.InputProps,
              type: 'search'
            }}
            variant="outlined"
            margin="dense"
            fullWidth
            placeholder={t('Email')}
          />
        )}
      />)}    
    <label className={css.label} htmlFor="firstName">{t('Lab_Name')}:</label>
      {
      (<Autocomplete className={css.autocomplete}
        freeSolo
        id="firstName"
        disableClearable
        onChange={(e,v:string) => {
          setLabName(v)
          setState(2)
          if(!v) resetField()
        }}
        onInputChange={(e,v:string) => {
          setLabName(v)
          setState(2)
          if(!v) resetField()
        }}        
        getOptionSelected={ (option: string, value: string) => option === value }
        options={labNames}
        inputValue={labName}
        fullWidth
        loading={loadingOtpion}
        disabled={state === 1 || user.role === UserRole.FLP_ADMINISTRATOR}
        renderInput={(params) => (
          <TextField
            className={css.input}
            {...params}
            InputProps={{
              ...params.InputProps,
              type: 'search'
            }}
            variant="outlined"
            margin="dense"
            fullWidth
          />
        )}
      />)}  
      <AddressField
        address={address}
        onChange={(newValue: IAddress) => {setAddress(newValue)}}
        disabled={state === 2 || Boolean(addressSource)}
      />
      <div className="dialog-actions">
        <Button
          onClick={closeDialog}
          variant="outlined"
          className="btn-cancel"
        >
          {t('Cancel')}
        </Button>
        <Button
          onClick={Submit}
          disabled={!Validated() || loading}
          variant="outlined"
          className="btn-confirm"
        >
          {loading && (
            <CircularProgress size={20} style={{ color: "#898a8d" }} />
          )}
          {!loading && <>{t('Add')}</>}
        </Button>
      </div>
    </div>
  );
}

export default ExchangeLabForm