export const licenceKeysRegex = (licenseKey: string): boolean => {
  //return /^[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{6}$/.test(licenceKey)
  return /^[a-zA-Z0-9]{10}$/.test(licenseKey);
};

// Check syntax of IOS device serial number
export const serialNumberRegex = (sn: string): boolean => {
  // Serial number syntax for camera
  return /^([A-Za-z]{2})([Nn][A-La-l])([A-Za-z0-9]{4})$/.test(sn);
};


// The minimum number of digits we have for an Customer Ref is 6
// but there are also 7 and 8 digit numbers in use. Here are the min and max (excluding IDs that use alpha characters and can be 10 characters long,
// which I think are for internal customers) exple: 100064, 21010261
// There are over 900 accounts that use alphanumeric IDs and these range from 6 to 10 characters.
// 04-MARCH-2022: Extending regex by precaution to 5-12 characters in prevision of Envista migration, en because we are adding an additional check that SAP ID is matching existing devices
export const CustomerRefRegex = (sapAccount: string): boolean => {

  return (
    /^[A-Za-z0-9]{4,12}$/.test(sapAccount)
  );
};

// Format a string date to short date as given example: 'January 01 1970'
export const FormatShortDate = (aDate: string): string => {
  if (aDate) {
    const date = new Date(aDate);
    return date
      .toLocaleDateString("en-us", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      })
      .replace(",", " ");
  } else {
    return "";
  }
};
export const FormatDateMMYYYY = (aDate: string): string => {
  if (aDate) {
    const date = new Date(aDate);
    return date
      .toLocaleDateString("en-us", {  day: 'numeric', month: "short", year: "numeric" })
  } else {
    return "";
  }
};

// Format a string date to short date as given example: 'Jan, 01, 1970'
export const FormatDate = (aDate: string): string => {
  if (aDate) {
    const date = new Date(aDate);
    return date.toLocaleString("en-us", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  } else {
    return "";
  }
};

export const GetDifferenceInDays = (
  dateOne: number,
  dateTwo: number
): number => {
  const dateDiff = dateOne - dateTwo;
  return dateDiff / (1000 * 60 * 60 * 24);
};

export function EnumKeys<E>(e: E): (keyof E)[] {
  return Object.keys(e) as (keyof E)[];
}

// From a string or an array of string
// Build the serialize string for user references that contains several sap number concatenated separated by coma
export const BuildUserReference = (
  input_userReference: string | string[],
  init_userReference: string
): string => {
  let output_userReference = init_userReference;

  let references: string[] =
    output_userReference !== ""
      ? output_userReference
          .split(",")
          .map(input_userReference => input_userReference.trim())
      : [];
  if (typeof input_userReference === "string")
    references.push(input_userReference);
  if (typeof input_userReference === "object")
    references = input_userReference as string[];

  output_userReference = references.join();

  return output_userReference;
};

export const ConvertArrayReferences = (userReference: string): string[] => {
  let references: string[] =
    userReference !== ""
      ? userReference.split(",").map(userReference => userReference.trim())
      : [];

  return references;
};
