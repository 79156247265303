import React, { useMemo } from "react";

const Text = ({ text }: { text: string }) => (
  <div style={{ textAlign: "center", padding: "4px 8px" }}>{text}</div>
);

export interface IWaitProps {
  useVerticalLayout?: boolean;
  leftOtTopText?: string;
  rightOrBottomText?: string;
  showBorder?: boolean;
  style?: React.CSSProperties;
}

const Wait: React.FC<IWaitProps> = ({
  leftOtTopText,
  rightOrBottomText,
  style,
  useVerticalLayout,
  showBorder,
}) => {
  rightOrBottomText = "Loading...";

  const innerStyle = useMemo<React.CSSProperties>(
    () => ({
      height: "100%",
      width: "100%",
      borderRadius: 4,
      border: showBorder !== false ? "solid 1px #cde" : undefined,
      boxShadow:
        showBorder !== false ? "0 0 3px #cde, 1px 1px 2px #cde" : undefined,
      flexDirection: useVerticalLayout ? "column" : "row",
      background: "#fff",
      ...style,
    }),
    [showBorder, style, useVerticalLayout]
  );

  return (
    <div>
      <div className="centered" style={innerStyle}>
        {leftOtTopText && <Text text={leftOtTopText} />}
        {rightOrBottomText && <Text text={rightOrBottomText} />}
      </div>
    </div>
  );
};

export default Wait;
