import React from "react";
import { licenceKeysRegex, CustomerRefRegex } from "../../shared/utils/utils";
import Button from "@mui/material/Button";
import "./TagsInput.scss";
import { TagsInputType } from "../../shared/enums/TagsInputType";

interface IProps {
  type: string;
  licenceType?: string;
  initValues?: string[];
  id: string;
  onValueChange?: (value: string | string[]) => void;
  setValidated?: (value: boolean) => void;
  fieldConflictError?: string;
}

const TagsInput = (props: IProps) => {
  const { type, id, initValues, onValueChange, setValidated, licenceType, fieldConflictError } =
    props;
  const tagsInit =
    typeof initValues === "object" && initValues.length > 0 ? initValues : [];
  const [tags, setTags] = React.useState<string[]>(tagsInit);
  const [inputValue, setInputValue] = React.useState<string>("");
  const [error, setError] = React.useState<boolean>(false);
  const [errorLicenceKeys, setErrorLicenceKeys] = React.useState<string[]>([]);
  const [errorCustomerRefs, setErrorCustomerRefs] = React.useState<string[]>([]);
  const errorText = `${
    type === TagsInputType.LICENCE_KEYS
      ? `License key ${errorLicenceKeys[0]}`
      : `Customer Ref ${errorCustomerRefs[0]}`
  } not valid.`;
  const helpText = `Supports multiple ${
    type === TagsInputType.LICENCE_KEYS ? "license keys" : "Customer Refs"
  } with a comma separator. Press “enter” to confirm.`;

  const removeTag = (index: number) => {
    const isLicenceKeyType = type === TagsInputType.LICENCE_KEYS;

    const newTags = [...tags];

    // Remove the tag from the error list if present
    var newErrorLicenceKeys = [...errorLicenceKeys];
    var newErrorCustomerRefs = [...errorCustomerRefs];

    const pos = isLicenceKeyType
      ? newErrorLicenceKeys?.findIndex(val => val === newTags[index])
      : newErrorCustomerRefs?.findIndex(val => val === newTags[index]);
    if (pos !== -1) {
      isLicenceKeyType
        ? newErrorLicenceKeys.splice(pos, 1)
        : newErrorCustomerRefs.splice(pos, 1);
    }

    // Remove the tag from tags list
    newTags.splice(index, 1);

    // Check if the list of errors is empty
    const hasError = isLicenceKeyType
      ? newErrorLicenceKeys.length > 0
      : newErrorCustomerRefs.length > 0;
    setError(hasError);
    setValidated?.(!hasError && newTags.length > 0);

    onValueChange?.(newTags);

    // Update state variables
    setTags(newTags);
    setErrorLicenceKeys(newErrorLicenceKeys);
    setErrorCustomerRefs(newErrorCustomerRefs);
  };

  const validateInputs = (val: string) => {
    // Ignore empty inputs
    if (val.trim() === "") {
      return;
    }

    setInputValue("");
    val = val.toUpperCase();
    const newSelectedItem = [...tags];
    var newErrorLicenceKeys = [...errorLicenceKeys];
    var newErrorCustomerRefs = [...errorCustomerRefs];

    if (tags.includes(val)) {
      return;
    }

    let noDuplicate = [...new Set(val.split(","))];
    noDuplicate.forEach(async (v: any) => {
      newSelectedItem.push(v);
      if (type === TagsInputType.LICENCE_KEYS) {
        if (!licenceKeysRegex(v)) {
          setError(true);
          newErrorLicenceKeys = [...newErrorLicenceKeys, v];
          setValidated?.(false);
        } else {
          // Validate the key against the licensing server
          // const valid = await validateTokenAsync(v, licenceType);
          // if (valid) {
          //   addNewLicenceKey(v);
          //   if (newErrorLicenceKeys.length === 0) {
          //     // We only need to set validated to true for the first added tag
          //     // After, a valid tag does not change the global state, only invalid tags do
          //     setValidated?.(true);
          //   }
          // } else {
          //   setError(true);
          //   newErrorLicenceKeys = [...newErrorLicenceKeys, v];
          //   setValidated?.(false);
          // }
        }
        // Update errors list
        setErrorLicenceKeys(newErrorLicenceKeys);
      }
      if (type === TagsInputType.SAP_ACCOUNT) {
        if (!CustomerRefRegex(v)) {
          setError(true);
          newErrorCustomerRefs = [...newErrorCustomerRefs, v];
          setValidated?.(false);
        } else {
          onValueChange?.(v);
          if (newErrorCustomerRefs.length === 0) {
            // We only need to set validated to true for the first added tag
            // After, a valid tag does not change the global state, only invalid tags do
            setValidated?.(true);
          }
        }
        // Update errors list
        setErrorCustomerRefs(newErrorCustomerRefs);
      }
    });
    setTags([...newSelectedItem]);
  };

  const inputKeyDown = (e: any) => {
    let val = e.target.value;
    if (e.key === "Enter" && val) {
      e.preventDefault();
      validateInputs(val);
    } else if (e.key === "Backspace" && !val) {
      e.preventDefault();
      removeTag(tags.length - 1);
    }
  };

  const onBlur = (e: any) => {
    let val = e.target.value;
    e.preventDefault();
    validateInputs(val);
  };

  React.useEffect(() => {
    if (type === TagsInputType.SAP_ACCOUNT) {
      onValueChange?.(tags);
    }
  }, [onValueChange, tags, type]);

  return (
    <div>
      <div className={
        `input-tag ${error || fieldConflictError?.toLowerCase() === "value" ?
        "border-orange"
        :
        "border-black"}`}
      >
        <ul className="input-tag__list">
          {tags.map((tag: string, index: number) => (
            <li
              key={index}
              style={{
                backgroundColor: (
                  type === TagsInputType.LICENCE_KEYS
                    ? errorLicenceKeys.includes(tag)
                    : errorCustomerRefs.includes(tag)
                )
                  ? "#FF5100"
                  : "",
                color: (
                  type === TagsInputType.LICENCE_KEYS
                    ? errorLicenceKeys.includes(tag)
                    : errorCustomerRefs.includes(tag)
                )
                  ? "white"
                  : "black",
              }}
            >
              {tag}
              <Button
                style={{
                  color: (
                    type === TagsInputType.LICENCE_KEYS
                      ? errorLicenceKeys.includes(tag)
                      : errorCustomerRefs.includes(tag)
                  )
                    ? "white"
                    : "black",
                }}
                onClick={() => {
                  removeTag(index);
                }}
              >
                x
              </Button>
            </li>
          ))}
          <input
            type="text"
            id={id}
            onKeyDown={inputKeyDown}
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            onBlur={onBlur}
          />
        </ul>
      </div>
      <p className="helper-text">{helpText}</p>
      {error && <p className="error-text">{errorText}</p>}
      {fieldConflictError?.toLowerCase() === "value" && <p className="error-text">This licence key(s) already exist.</p>}
    </div>
  );
};

export default TagsInput;
