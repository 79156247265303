import React, { useContext, useMemo } from "react";
import {
  MsalProvider,
  UnauthenticatedTemplate as MsalUnauthenticatedTemplate,
  AuthenticatedTemplate as MsalAuthenticatedTemplate,
} from "@azure/msal-react";
import { csdLoginRequest, getMsalClient, loginRequest } from "./msalClient";
import IAuthService from "../../shared/interfaces/IAuthService";
import { AccountInfo } from "@azure/msal-browser";
import MsalContainer from "./MsalContainer";
import getLoadedConfig from "../appConfigService";
import http from "../api/http";
import { RecordStatus } from "../../shared/enums/RecordStatus";
import { UserRole } from "../../shared/enums/UserRole";
import IAccountInfoFLP from "../../shared/interfaces/IAccountInfo";

const accountIdentifierKey = "_account_Id_";
const accountInfoKey = "_account_info_";

export const getCurrentAccountId = (throwIfNotFound: boolean = false) => {
  const id = sessionStorage.getItem(accountIdentifierKey) || undefined;
  if (!id && throwIfNotFound) {
    throw new Error("Can't find logged in user's account ID.");
  }

  return id;
};

export const getLoggedInUser = (throwIfNotFound: boolean = true) => {
  const accountId = getCurrentAccountId(throwIfNotFound);
  const account = getMsalClient().getAccountByHomeId(accountId || "");

  if (!account && throwIfNotFound) {
    throw new Error("No logged in user found");
  }
  return account;
};

export const getAccountEmail = (account = getLoggedInUser()) =>
  (account?.idTokenClaims as { email: string })?.email;

type Type_IAccount = IAccountInfoFLP;

async function getUserAccount(): Promise<Type_IAccount | undefined> {
  const data = sessionStorage.getItem(accountInfoKey);
  if (data) {
    var accountInfo = JSON.parse(data) as Type_IAccount;
    return accountInfo;
  } else {
    try {
      const config = getLoadedConfig();
      const url = config.apiUrl + `Account`;

      const req = await http.get<Type_IAccount>(url);

      sessionStorage.setItem(accountInfoKey, JSON.stringify(req.data));

      return req.data;
    } catch (error) {
      return undefined;
    }
  }
}

const setLoggedInUser = (account: AccountInfo | null) => {
  if (!account) {
    sessionStorage.removeItem(accountIdentifierKey);
    sessionStorage.removeItem(accountInfoKey);
  } else {
    sessionStorage.setItem(accountIdentifierKey, account.homeAccountId);
  }
};

// Base class to keep in common base behaviour
abstract class BaseAuthService implements IAuthService {

  login = async (performCSDLogin: boolean) => {
    this.setLoggedInUser(null);

    const authRequest = performCSDLogin ? csdLoginRequest : loginRequest;

    await getMsalClient().loginRedirect(authRequest);
  };

  logout = async () => {
    this.setLoggedInUser(null);

    await getMsalClient().logoutRedirect();
  };

  setLoggedInUser = async (account: AccountInfo | null) => {
    setLoggedInUser(account);
  };

  getLoggedInUser = (throwIfNotFound = true) =>
    getLoggedInUser(throwIfNotFound);

  get isLoggedIn() {
    return !!this.getLoggedInUser(false);
  }

  isAuthorizedUser = async () => {
    const account = await getUserAccount();

    if (account && account.recordStatus === RecordStatus.ACTIVE) {
      return true;
    } else {
      return false;
    }
  };

  getUserId = async () => {
    const account = await getUserAccount();

    if (account) {
      const recordId = account.recordId;
      return recordId;
    } else {
      return "Unknown";
    }
  };

  getUserReference = async () => {
    const account = await getUserAccount();

    if (account) {
      const reference = account.userReference;
      return reference;
    } else {
      return "Unknown";
    }
  };

  isUserRole = async (userRole: UserRole) => {
    const account = await getUserAccount();

    if (account?.role) {
      return account.role === userRole;
    } else {
      return false;
    }
  };

  abstract getUserReferences(): Promise<string[]>;
  abstract getUserAvatar(): Promise<string>;
  abstract getDisplayName(): Promise<string>;
  abstract getUserRole(): Promise<UserRole>;
}

// Auth Service for fleet portal
class FleetPortalAuthService extends BaseAuthService {
  getUserReferences = async () => {
    // Not implemented
    throw Error("Fleetportal getUserReferences Not implemented");
  };

  getUserAvatar = async () => {
    const account = (await getUserAccount()) as IAccountInfoFLP;

    if (account) {
      // Take the first letter of the surname and the first letter of the firstname for avatar
      const avatar =
        account.firstName.slice(0, 1).toUpperCase() +
        account.surname.slice(0, 1).toUpperCase();
      return avatar;
    } else {
      return "U";
    }
  };

  getDisplayName = async () => {
    const account = (await getUserAccount()) as IAccountInfoFLP;

    if (account) {
      const displayName = account.firstName + " " + account.surname;
      return displayName;
    } else {
      return "Unknown";
    }
  };

  getUserRole = async () => {
    const account = await getUserAccount();

    if (account) {
      const role = account.role as UserRole;
      return role;
    } else {
      return UserRole.NOT_SET;
    }
  };

  RoleManager = async () => {
    throw new Error("Not implemented for fleetportal");
  }

  isTTSBarcelona =  async () => {
    throw new Error("Not implemented for fleetportal");
  }

}

/// Auth Provider
const context = React.createContext<IAuthService | Error>(
  new Error("MSAL Client isn't available on context!")
);

const FactoryAuthService = (): IAuthService | null => {
  return new FleetPortalAuthService();
};

export const AuthServiceProvider: React.FC = ({ children }) => {
  const config = getLoadedConfig();

  const instanceAutService = FactoryAuthService();
  if (!instanceAutService) throw new Error("Cannot create autService");

  const authService = useMemo(() => instanceAutService, []);

  // Wrap in MSAL provider
  return (
    <context.Provider value={authService}>
      <MsalProvider instance={getMsalClient()}>
        <MsalContainer authService={authService} logMessages={true}>
          {children}
        </MsalContainer>
      </MsalProvider>
    </context.Provider>
  );
};

function useAuthService() {
  const ctx = useContext(context);
  if (ctx instanceof Error) {
    throw ctx;
  }
  return ctx;
}

export const UnauthenticatedTemplate: React.FC = ({ children }) => (
  <MsalUnauthenticatedTemplate
    homeAccountId={getCurrentAccountId()}
    children={children}
  />
);

export const AuthenticatedTemplate: React.FC = ({ children }) => (
  <MsalAuthenticatedTemplate
    homeAccountId={getCurrentAccountId()}
    children={children}
  />
);

export default useAuthService;
