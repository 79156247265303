// Function for get Practice

import requestApi from '../request'
import { UPDATE_PRACTICES } from '../../../redux/type/practices';
import { store } from '../../../redux/store';
import { selectPractice } from '../../../redux/selector/practices/index';

interface IUpdatePracticesParams {
  recordId: string,
  form: {
    name: string,
    email: string,
    address: {
      line1: string,
      city: string,
      zip: string,
      country: string,
      region: string
    }
  }
}

const updatePractice = async (params: IUpdatePracticesParams) => {
  const path = `Practices/${params.recordId}`;
  const data = selectPractice(store.getState(), params.recordId)

  const { name, email, address } = params.form
  const paramsRequest = {
    RecordId: data.recordId,
    Name: name,
    EmailAddress: email,
    addressId: data.addressId,
    Address: {
      RecordId: data.address.recordId,
      Line1: address.line1,
      Line2: data.address.line2,
      Line3: data.address.line3,
      City: address.city,
      Region: address.region,
      PostCode: address.zip,
      CountryCode: address.country
    }
  }

  await requestApi(path, 'put', UPDATE_PRACTICES, paramsRequest)
}

export default updatePractice