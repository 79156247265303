import { IRootStoreState } from '../../reducer'
import { createSelector } from 'reselect'
import { selectPractices } from '../practices'

export const selectNbResult = (state: IRootStoreState) => state.device.listDevices.count
export const pendingListDevices = (state: IRootStoreState) => state.device.listDevices.pending
const selectListDevice = (state: IRootStoreState) => state.device.listDevices.data
const selectDevices = (state: IRootStoreState) => state.device.devices

export const selectTableDevices = createSelector(
  selectListDevice,
  selectDevices,
  selectPractices,
  (listDevices, devices) => listDevices.map((item: any) => {
    return({
    entityId: devices[item].entityId,
    serialNumber: devices[item].serialNumber,
    deviceType: devices[item].deviceType,
    countryCode: devices[item].countryCode,
    configuration: devices[item].configuration,
    firstUseDate: devices[item].firstUseDate,
    lastUseDate: devices[item].lastUseDate,
    memberName: devices[item].memberName,
    memberId: devices[item].memberId,
    member: devices[item].member,
    practice: devices[item].practice,
    labName: devices[item].labName,
    recordId: devices[item].recordId,
    sapId: devices[item].sapId,
    memberRef: devices[item].memberRef
  })})
)