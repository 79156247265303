import {
  Configuration,
  BrowserCacheLocation,
  LogLevel,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import {
  tenantName,
  clientId,
  loginDomain,
  policies,
  scopes,
} from "./msal-config.json";

const isProdEnv = process.env.NODE_ENV === "production";
let _msalClient: PublicClientApplication;

export const consoleTextStyles = {
  Title: "background: SteelBlue; color: #000",
  Unset: "background: unset; color:unset",
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [scopes.userStandard],
  prompt: "login",
};
// CSD login request
export const csdLoginRequest: RedirectRequest = {
  scopes: [scopes.userStandard],
  extraQueryParameters: { domain_hint: "csdental.com" },
};

export function getMsalClient() {
  if (!_msalClient) {
    _msalClient = new PublicClientApplication(getMsalClientConfig());
  }
  return _msalClient;
}

export function getMsalClientConfig() {
  const msalConfig: Configuration = {
    auth: {
      clientId,
      authority: `https://${loginDomain}/${tenantName}.onmicrosoft.com/${policies.login}`,
      knownAuthorities: [loginDomain],
      navigateToLoginRequestUrl: false,
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: { loggerCallback },
    },
  };

  return msalConfig;
}

function loggerCallback(
  level: LogLevel,
  message: string,
  containsPii: boolean
) {
  if (containsPii) {
    return;
  }

  message = "%cMSAL%c " + message;

  switch (level) {
    case LogLevel.Error:
      console.error(message, consoleTextStyles.Title, consoleTextStyles.Unset);
      return;

    case LogLevel.Warning:
      console.warn(message, consoleTextStyles.Title, consoleTextStyles.Unset);
      return;

    case LogLevel.Info:
      isProdEnv ||
        console.info(message, consoleTextStyles.Title, consoleTextStyles.Unset);
      return;

    case LogLevel.Verbose:
      isProdEnv ||
        console.debug(
          message,
          consoleTextStyles.Title,
          consoleTextStyles.Unset
        );
      return;
  }
}
