import React, { useState, useEffect } from 'react'

// Interface
import IInputForm from './IInputForm';

//Style
import styles from './InputForm.module.scss'


const InputForm: React.FC<IInputForm> = (props) => {
  const {
    title,
    onChange,
    value,
    placeholder,
    className,
    disabled
  } = props

  const [localValue, setValueLabel] = useState(value)

  useEffect(() => {
    setValueLabel(value)
  }, [value])

  return(
    <div className={`${styles.container} ${className && className}`}>
      { title && <label className={styles.title} htmlFor={title}>{title} :</label>}
      <input
        className={styles.field}
        type="text"
        value={localValue}
        placeholder={placeholder}
        name={value}
        onChange={(event) => {
          setValueLabel(event.target.value)
          onChange(event.target.value)
        }}
        disabled={!!disabled}
      />
    </div>
  )
}

export default InputForm