// Function for update a device

import requestApi from '../request'
import { UPDATE_DEVICE } from '../../../redux/type/device';

interface IUpdateDeviceParams {
  recordId: string,
  form: {
  serialNumbers: string[],
  deviceType: string,
  memberId: string,
  }
}

const updateDevice = async (params: IUpdateDeviceParams) => {
  const path = `Devices/DevicesForAdmin(${params.recordId})`;
  
  const body = {
    "serialNumber": params.form.serialNumbers,
    "deviceType": params.form.deviceType,
    "memberId": params.form.memberId
  }
  await requestApi(path, 'put', UPDATE_DEVICE, body)
}

export default updateDevice