import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  LinearProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useDialogState from "../../../shared/useDialogState";
import AddEditMemberDialog from "../../Dialogs/AddEditMemberDialog";
import AddIcon from "@mui/icons-material/Add";
import { DialogType } from "../../../shared/enums/DialogType";
import Dialogs from "../../Dialogs";
import {
  getMembersFlpAsync,
  IMemberResponseFlp,
} from "../../../services/memberServiceFlp";
import ConfirmDeleteDialog from "../../Dialogs/ConfirmDeleteDialog";
import { Pagination } from "@mui/material";
import { SearchBar } from "../../../components/shared";
import { useTranslation } from 'react-i18next';
import classes from './Members.module.scss'
import useAuthService from "../../../services/authService";
 

interface IProps {
  type: string;
}

export default function Members(props: IProps) {
  const { type } = props;
  const memberPerPage = 10; 
  const {t} = useTranslation();
  const authService = useAuthService();

  const [isOpen, open, close] = useDialogState();
  const [dialogType, setDialogType] = React.useState("");
  const [dialogContent, setDialogContent] = React.useState(<></>);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [editRowIndex, setEditRowIndex] = React.useState<number>(-1);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [rowsData, setRowsData] = React.useState<IMemberResponseFlp[]>([]);
  const [memberCount, setMemberCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [validated, setValidated] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [userId, setUserId] = React.useState<string>("");

  const handleDialogClose = (
    isOpen: boolean,
    cancelled: boolean,
    data: IMemberResponseFlp
  ) => {
    if (!cancelled) {
      if (
        dialogType === DialogType.ADD_MEMBER ||
        dialogType === DialogType.EDIT_MEMBER
      ) {
        if (data) {
          if (-1 === editRowIndex) {
            if (rowsData.length >= memberPerPage) rowsData.splice(-1);
            setMemberCount(memberCount + 1);
            rowsData.unshift(data);
          } else if (dialogType === DialogType.EDIT_MEMBER) {
            rowsData[editRowIndex] = data;
          }
        }
      }

      // Remove row from data
      if (dialogType === DialogType.CONFIRM_DELETE) {
        if (rowsData.length === memberPerPage && memberCount > memberPerPage) handleChange(null, currentPage, true);
        if (rowsData.length === 1 && memberCount > memberPerPage) handleChange(null, 1, true);
        setMemberCount(memberCount - 1);
        rowsData.splice(editRowIndex, 1);
      }
    }

    if (
      dialogType === DialogType.ADD_MEMBER ||
      dialogType === DialogType.EDIT_MEMBER
    ) {
      setValidated?.(false);
    }
    close();
  };

  const handleChange = (event: any, value: number, refresh: boolean = false, cancel: boolean = false) => {
    if (value !== currentPage || refresh || cancel) {
      setLoading(false);
      setCurrentPage(value);
      getMembersFlpAsync(
        false,
        (value - 1) * memberPerPage,
        memberPerPage,
        cancel ? "" : searchValue
      ).then((response: any) => {
        setMemberCount(response["@odata.count"]);
        setRowsData(response?.value);
        setLoading(true);
      });
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleChange(null, 1, true)
  },[searchValue]);

  React.useEffect(() => {
    authService.getUserId().then(result => {
      setUserId(result);
    });
    handleChange(null, 1);
  }, []);

  return (
    <div className={classes.root}>
      <div className="searchbar">
        <div>
        <SearchBar
            onCancelSearch={() => { setSearchValue("") } }
            onRequestSearch={(value: string) => { setSearchValue(value)}}
            tooltipText={t('MembersNameFirstNameSurnameEmail') ?? ''}
            buttonLabel={`${t('Search')}`} 
            placeholder={t('MembersNameFirstNameSurnameEmail')} >
          </SearchBar>
        </div>
      </div>
      <div className="add-bar">
        <div>
          <Button className={classes.button}
            variant="contained"
            onClick={() => {
              setDialogType(DialogType.ADD_MEMBER);
              setDialogTitle("Add Fleet Manager");
              setDialogContent(
                <AddEditMemberDialog setValidated={setValidated} />
              );
              setEditRowIndex(-1);
              open();
            }}
          >
            <AddIcon />
            {t('Add Fleet Manager')}
          </Button>
        </div>
        <p className={classes.nbResult}>{`${memberCount} result${
          memberCount > 1 ? "s" : ""
        }`}</p>
      </div>
      {isOpen && (
        <Dialogs
          dialogType={dialogType}
          appType={type}
          isOpen={true}
          onDialogClosed={handleDialogClose}
          title={dialogTitle}
          content={dialogContent}
          editIndex={selectedIndex}
          editData={rowsData[selectedIndex]}
          validated={validated}
        />
      )}
      {
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width={35}>{t('No')}</TableCell>
                <TableCell width={194}>{t('MemberName')}</TableCell>
                <TableCell width={194}>{t('FirstName')}</TableCell>
                <TableCell width={194}>{t('Surname')}</TableCell>
                <TableCell width={260}>{t('Email')}</TableCell>
                <TableCell width={89} align="right">
                  {t('Actions')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsData.map((row: IMemberResponseFlp, index: number) => (
                <TableRow key={row.recordId}>
                  <TableCell width={35} component="th" scope="row">
                    {currentPage && (
                      <>
                        {(currentPage - 1) *
                          (memberPerPage ? memberPerPage : 0) +
                          index +
                          1}
                      </>
                    )}
                  </TableCell>
                  <TableCell width={194}>{row.name}</TableCell>
                  <TableCell width={194}>
                    {row?.user?.firstName || ""}
                  </TableCell>
                  <TableCell width={194}>{row?.user?.surname || ""}</TableCell>
                  <TableCell width={260}>
                    {row?.user?.emailAddress || ""}
                  </TableCell>
                  <TableCell
                    width={89}
                    align="right"
                    style={{ paddingRight: 0 }}
                  >
                    {row.userId !== userId && (
                      <>
                        <IconButton
                          aria-label="edit"
                          onClick={e => {
                            setSelectedIndex(index);
                            setDialogType(DialogType.EDIT_MEMBER);
                            setDialogTitle("Edit Fleet Manager");
                            setDialogContent(
                              <AddEditMemberDialog
                                editRowData={row}
                                setValidated={setValidated}
                              />
                            );
                            setEditRowIndex(index);
                            open();
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={e => {
                            setSelectedIndex(index);
                            setDialogType(DialogType.CONFIRM_DELETE);
                            setDialogTitle("");
                            setEditRowIndex(index);
                            setDialogContent(<ConfirmDeleteDialog />);
                            open();
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </>
              )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
      {/* loader */}
      {!isLoading && <LinearProgress />}
      {memberCount > 0 && (
        <Pagination
          count={Math.ceil(memberCount / memberPerPage)}
          shape="rounded"
          size="small"
          onChange={handleChange}
          defaultPage={1}
          page={currentPage}
          className={classes.pagination}
          color="primary"
        />
      )}
    </div>
  );
}
