import React from "react";
import { UserRole } from "../../../shared/enums/UserRole";
import { IUserDataFlp } from "../../../shared/interfaces/IUserDataFlp";
import css from "./AddEditUserFlpDialog.module.scss";
import { setInstanceUserFLP } from "../../../services/userServiceFlp";
import isEmail from "validator/lib/isEmail";
import { useTranslation } from 'react-i18next'; 

interface IProps {
  editRowData?: IUserDataFlp;
}

export default function AddEditDealerDialog(props: IProps) {
  const { editRowData } = props;
  const user: IUserDataFlp | undefined = editRowData;

  const [firstName, setFirstName] = React.useState<string>(
    user?.firstName || ""
  );
  const [surname, setSurname] = React.useState<string>(user?.surname || "");
  const [emailAddress, setEmailAddress] = React.useState<string>(
    user?.emailAddress || ""
  );
  const [emailInvalidText, setEmailInvalidText] = React.useState("");
  const [role, setRole] = React.useState<string>(
    user?.role || UserRole.NOT_SET
  );
  const {t} = useTranslation();

  React.useEffect(() => {
    setInstanceUserFLP({
      recordId: undefined === user ? "" : user.recordId,
      firstName: firstName,
      surname: surname,
      emailAddress: isEmail(emailAddress) ? emailAddress : "",
      role: role,
    });
  }, [firstName, surname, emailAddress, role, user]);

  return (
    <div className={css.addEditUserFlp}>
      <form>
        <div>
          <label htmlFor="firstName">{t('FirstName')} :</label>
          <input
            id="firstName"
            type="text"
            defaultValue={firstName}
            name="firstName"
            onChange={e => {
              setFirstName(e.target.value);
            }}
          />
        </div>
        <div>
          <label htmlFor="surname">{t('Surname')} :</label>
          <input
            id="surname"
            type="text"
            defaultValue={surname}
            name="surname"
            onChange={e => {
              setSurname(e.target.value);
            }}
          />
        </div>
        <div>
          <label htmlFor="userEmail">{t('UserEmail')} :</label>
          <input
            id="userEmail"
            type="email"
            value={emailAddress}
            name="userEmail"
            placeholder={t('ExampleEmailAddress')}
            className={emailInvalidText !== "" ? "error-input" : ""}
            onBlur={e => {
              !isEmail(emailAddress)
                ? setEmailInvalidText("Invalid email !")
                : setEmailInvalidText("");
            }}
            onChange={e => setEmailAddress(e.target.value)}
          />
          {emailInvalidText !== "" && (
            <p className="error-text">{emailInvalidText}</p>
          )}
        </div>
        <div>
          <label htmlFor="userRole">{t('Role')} :</label>
          <select
            name="userRole"
            id="userRole"
            defaultValue={role}
            onChange={e => {
              setRole(e.target.value);
            }}
          >
            <option value={UserRole.NOT_SET} disabled selected>
              Select role
            </option>
            <option value={UserRole.MEMBER_MANAGER}>Fleet Manager</option>
            <option value={UserRole.FLP_ADMINISTRATOR}>
            {t('DexisAdministrator')}
            </option>
            <option value={UserRole.FLP_SUPPORT}>FleetPortal Support</option>
          </select>
        </div>
      </form>
    </div>
  );
}
