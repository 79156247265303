import React, { useContext, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import deviceList from "../../assets/images/home/device-list.svg";
import deviceListWhite from "../../assets/images/home/device-list-white.svg";
import memberConf from "../../assets/images/home/member-configuration.svg";
import memberConfWhite from "../../assets/images/home/member-configuration-white.svg";
import userDetails from "../../assets/images/home/user-details.svg";
import userDetailsWhite from "../../assets/images/home/user-details-white.svg";
import help from "../../assets/images/help.svg";
import "./VerticalMenu.scss";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { configContext } from "../../shared/useAppConfig";
import { AppType } from "../../shared/enums/AppType";
import useAuthService from "../../services/authService";
import UsersFlpConf from "../UserConfFlp";
import Members from "../MemberConf/Members";
import ExchangeLabList from "../MemberConf/ExchangeLabConf";
import { UserRole } from "../../shared/enums/UserRole";
import AdminQuickConnectPartners from "../MemberConf/QuickConnectPartners/AdminQuickConnectPartners";
import MemberQuickConnectPartners from "../MemberConf/QuickConnectPartners/MemberQuickConnectPartners";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

import { Practice, Device } from '../../page';
import { useTranslation } from 'react-i18next';  
import CasesList from "../MemberConf/CasesList";
import PracticeCases from "../MemberConf/PracticeCases";
import Dashboard from "../MemberConf/Dashboard";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = useState<string>("0");
  const config = useContext(configContext);
  const [type, setType] = useState(AppType.DEVICES);
  const authService = useAuthService();
  const [isFLPCsdAdmin, setIsFLPCsdAdmin] = useState(false);
  const [currentRole, setCurrentRole] = useState<UserRole|UserRole>();
  const {t} = useTranslation() ;

  useEffect(() => {
    const fetchRole = async () => {

      // TODO need to be refactorized when RoleManager will be implementd for fleetportal
      const role = await authService.getUserRole();
      setCurrentRole(role);
      setIsFLPCsdAdmin(role === UserRole.FLP_ADMINISTRATOR);

    };
    fetchRole();
  }, [authService]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="tabs">
      <Tabs
        TabIndicatorProps={{ style: { background: "#36B0C9" } }}
        style={{ alignItems: "center" }}
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
      >
        <Tab
          value="0"
          onClick={() => {
            setType(AppType.DEVICES);
          }}
          icon={
            value === "0" ? (
              <img alt="device list White" src={deviceListWhite} />
            ) : (
              <img alt="device list" src={deviceList} />
            )
          }
          aria-label="Device list"
          {...a11yProps(0)}
        />

        {isFLPCsdAdmin && (
          <Tab
            value="2"
            onClick={() => {
              setType(AppType.MEMBERS);
            }}
            icon={
              value === "2" ? (
                <img alt="member configuratioin White" src={memberConfWhite} />
              ) : (
                <img alt="member configuration" src={memberConf} />
              )
            }
            aria-label="Member Configuration"
            {...a11yProps(2)}
          />
        )}
        { (currentRole === UserRole.MEMBER_MANAGER) && (
          <Tab
            value="2"
            onClick={() => {
              setType(AppType.LABEXCHANGE);
            }}
            icon={
              value === "2" ? (
                <img alt="Member configuration White" src={memberConfWhite} />
              ) : (
                <img alt="Member configuration" src={memberConf} />
              )
            }
            aria-label="Member Configuration"
            {...a11yProps(2)}
          />
        )}
        {(config?.FLPOptEnableUserManagement) && (
          <Tab
            value="3"
            onClick={() => {
              setType(AppType.USERSFLP);
            }}
            icon={
              value === "3" ? (
                <img alt="user details white" src={userDetailsWhite} />
              ) : (
                <img alt="Users" src={userDetails} />
              )
            }
            aria-label="Users"
            {...a11yProps(3)}
          />
        )}        
        { (currentRole === UserRole.MEMBER_MANAGER || currentRole === UserRole.MEMBER_SUB_MANAGER) && (
          <Tab
            value="4"
            onClick={() => {
              setType(AppType.DASHBOARD_OVERVIEW);
            }}
            icon={<AssessmentOutlinedIcon style={value === "4" ? { color: '#fff' } : { color: '#262626' } } />}
            aria-label="Fleet Dashboard"
            {...a11yProps(2)}
          />
        )}
        <div className="help">
          <img src={help} alt="help" />
        </div>
      </Tabs>
      <div className="box">
          <div className="header">
            {value === "0" && <h2 className="title">{t('DeviceList')}</h2>}
            {isFLPCsdAdmin && (value === "2") && (
              <>
                <h2 className="title">{t('MemberConfiguration')}</h2>
                <div className="subTitle">
                  <Button onClick={() => setType(AppType.MEMBERS)}>
                  {t('Fleet managers')}
                  </Button>
                  <Divider orientation="vertical" flexItem light={true} />
                  <Button
                    onClick={() => setType(AppType.QUICK_CONNECT_PARTNERS)}
                  >
                    {t('QuickConnectPartners')}
                  </Button>
                  <Divider orientation="vertical" flexItem light={true} />
                  <Button onClick={() => setType(AppType.LABEXCHANGE)}>
                    Lab Exchange
                  </Button>
                </div>
              </>
            )}
            {(currentRole === UserRole.MEMBER_MANAGER) && (value === "2") && (
              <>
                <h2 className="title">{t('NetworkConfiguration')}</h2>
                <div className="subTitle">
                  <Button onClick={() => setType(AppType.LABEXCHANGE)}>
                  {t('LabExchange')}
                  </Button>
                  <Divider orientation="vertical" flexItem light={true} />
                  <Button onClick={() => setType(AppType.QUICK_CONNECT_PARTNERS)}>
                    {t('QuickConnectPartners')}
                  </Button>
                  <Divider orientation="vertical" flexItem light={true} />
                  <Button onClick={() => setType(AppType.PRACTICE)}>
                    {t('Practice')}
                  </Button>
                  <Divider orientation="vertical" flexItem light={true} />
                  <Button onClick={() => setType(AppType.MEMBERS)}>
                  {t('Fleet managers')}
                  </Button>
                </div>
              </>
            )}
            {(currentRole === UserRole.MEMBER_MANAGER || currentRole === UserRole.MEMBER_SUB_MANAGER) && (value === "4") && (
              <>
                <h2 className="title">{t('Fleet Dashboard')}</h2>
                <div className="subTitle">
                  <Button onClick={() => setType(AppType.DASHBOARD_OVERVIEW)}>
                    {t('Overview')}
                  </Button>
                  <Divider orientation="vertical" flexItem light={true} />
                  <Button onClick={() => setType(AppType.DASHBOARD_PRACTICE_CASES)}>
                    {t('Weekly practice view')}
                  </Button>
                  <Divider orientation="vertical" flexItem light={true} />
                  <Button onClick={() => setType(AppType.DASHBOARD_CASESLIST)}>
                    {t('Detailed Scans List')}
                  </Button>
                </div>
              </>
            )}
            {value === "3" && <h2 className="title">{t('Users')}</h2>}
          </div>
        <TabPanel value={value} index="0">
          <Device type={type} />
        </TabPanel>
        <TabPanel value={value} index="2">
          {isFLPCsdAdmin && (
            (type === AppType.MEMBERS && <Members type={type} />) ||
            (type === AppType.QUICK_CONNECT_PARTNERS && <AdminQuickConnectPartners type={type} />)
          )}
          {!isFLPCsdAdmin && (
            (type === AppType.QUICK_CONNECT_PARTNERS && <MemberQuickConnectPartners type={type} />)
          )}
          {currentRole === UserRole.MEMBER_MANAGER && (
            (type === AppType.MEMBERS && <Members type={type} />)
          )}
          { type === AppType.LABEXCHANGE && <ExchangeLabList type={type} />}
          {type === AppType.PRACTICE && <Practice/>}
        </TabPanel>
        <TabPanel value={value} index="3">
          <UsersFlpConf type={type} />
        </TabPanel>
        <TabPanel value={value} index="4">
          {
            (currentRole === UserRole.MEMBER_MANAGER || currentRole === UserRole.MEMBER_SUB_MANAGER) && ((type === AppType.DASHBOARD_OVERVIEW && <Dashboard type={type} />))
          }
          {
            (currentRole === UserRole.MEMBER_MANAGER || currentRole === UserRole.MEMBER_SUB_MANAGER) && ((type === AppType.DASHBOARD_CASESLIST && <CasesList type={type} />))
          }
          {
            (currentRole === UserRole.MEMBER_MANAGER || currentRole === UserRole.MEMBER_SUB_MANAGER) && ((type === AppType.DASHBOARD_PRACTICE_CASES && <PracticeCases type={type} />))
          }
        </TabPanel>
      </div>
    </div>
  );
}
