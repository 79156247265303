import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import {store} from './redux/store'
import { Provider } from 'react-redux'
import { I18nextProvider } from "react-i18next";
import i18n from './i18n'

if (!__csd__.isSupportedBrowser) {
  console.warn("Unsupported browser: ", navigator.userAgent);
} else {
  ReactDOM.render(
    // Turn off StrictMode for now, since current version of BluePrint JS (v3.38.1) triggers a bunch of warnings/errors.
    //<React.StrictMode>
    (<Provider store={store}><I18nextProvider i18n={i18n}><App /></I18nextProvider></Provider>),
    //</React.StrictMode>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
