import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch  } from 'react-redux'

//api
import {getPractices, addPractice, updatePractice, deletePractice} from '../../services/api/practices'

//translation
import { useTranslation } from 'react-i18next';

//component
import { SearchBar, PracticeForm } from '../../components/shared'
import {
  Button, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  IconButton,
  makeStyles
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Pagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

//Style
import styles from './Practice.module.scss'

//redux
import { selectTablePractice, selectNbResult, pendingListPractices, errorMessageDeletePractice, errorMessageAddPractice } from '../../redux/selector/practices';
import { IPractice } from '../../components/shared/Organisms/PracticeForm/IPracticeForm';
import { resetErrorMessage } from '../../redux/action/practices'
import { resetAddErrorMessage } from '../../redux/action/practices'

//Delete dialog
import CustomizedDialog from "../../components/Dialogs/CustomizedDialog";
import ConfirmDeleteDialog from "../../components/Dialogs/ConfirmDeleteDialog";
import { getCode } from 'country-list';

// Config
const nbPerPage: number = 10
const fieldsFilters: Array<string> = ['name', 'emailAddress', 'address/line1', 'address/line2', 'address/line3', 'address/city', 'address/postCode', 'address/countryCode', 'address/region', ]

const Practice: React.FC = () => {
  
  const initForm = {
    name: '',
    email: '',
    address: {
      line1: '',
      city: '',
      zip: '',
      country: '',
      region:''
    }
  }
  //const translation
  const {t} = useTranslation();

  // const local
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState('')

  const [openFormPractice, setOpenFormPractice] = useState(false)
  const [editFormPractice, setEditFormPractice] = useState(initForm)
  const [ idSelected, setIdSelected ] = useState('')

  // const delete dialog
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  // const redux
  const nbResult = useSelector(selectNbResult)
  const tableData = useSelector(selectTablePractice)
  const pending = useSelector(pendingListPractices)
  const errorMessageDelete = useSelector(errorMessageDeletePractice);
  const errorMessageAdd = useSelector(errorMessageAddPractice);
  const dispatch = useDispatch();

  //Init data practice page 1
  useEffect(() => {
    getPractices({
      top: nbPerPage,
      skip: 0,
    })
  }, []);

  const closeDialog = () => {
    setOpenDeleteDialog(false);
    dispatch(resetErrorMessage);
  };

  const closeFormDialog = () => {
    setOpenFormPractice(false);
    dispatch(resetAddErrorMessage);
  };

  const generateFilter = (fields: Array<string>, value: string) => {
    var fieldsString: string = ''
    value = value.toLowerCase();
    fields.forEach((field: string, index: number) => {
      if(field !== 'address/countryCode')
         index < 1 ? fieldsString +=  `contains(tolower(${field}),'${value}')` : fieldsString +=  ` or contains(tolower(${field}),'${value}')`
    })
    // Search only on country field 
    var countryCode = getCode(value);
    countryCode = countryCode?.toLowerCase();
    if(countryCode)
       fieldsString +=  `or contains(tolower(address/countryCode),'${countryCode}')`

    return fieldsString
  }

  const removePractice = async () => {
    deletePractice(idSelected)
    .then(() => {
     setIdSelected('')
     closeDialog();
     const count = Math.ceil(nbResult / nbPerPage);
     let currentPage = page;
     if(nbResult - 1 === nbPerPage * (count - 1)) 
         currentPage=page -1;
     getPractices({
     top: nbPerPage,
     skip: (currentPage - 1) > 0 ? (currentPage - 1)* nbPerPage : 0,
     filter: generateFilter(fieldsFilters, filter)
     })
     setPage((currentPage - 1) > 0 ? currentPage: 1);
    })
}

  return (
    <>
      <PracticeForm 
        practice={editFormPractice}
        onChange={(value: IPractice) => {
          setEditFormPractice(value)
        }}
        open={openFormPractice}
        setOpen={closeFormDialog}
        saveRequest={async () => {
          idSelected === '' ?
          addPractice(editFormPractice).then(() => {
            getPractices({
              top: nbPerPage,
              skip: (page - 1) * nbPerPage,
              filter: generateFilter(fieldsFilters, filter)
            })
            closeFormDialog(); 
          }) : updatePractice({ recordId: idSelected, form: editFormPractice}).then(() => {
            closeFormDialog(); 
            setIdSelected('')})
        }}
        errorMessage={errorMessageAdd}
      />
    <div>
    <CustomizedDialog
        title={""}
        openDialog={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
      >
        <ConfirmDeleteDialog
          closeDialog={closeDialog}
          deletedData={removePractice}
          errorMessage={errorMessageDelete}
        />
      </CustomizedDialog>
      <SearchBar
        onCancelSearch={()=> {
          setFilter('')
          getPractices({
            top: nbPerPage,
            skip: 0
          })
        }}
        onRequestSearch={(value:string) => {
          setFilter(value)
          setPage(1)
          getPractices({
            top: nbPerPage,
            skip: 0,
            filter: generateFilter(fieldsFilters, value)
          })
        }}
        tooltipText={t('PracticeEmailAddress')}
        buttonLabel={t('Search')}
        placeholder={t('PracticeEmailAddress')}/>
    </div>
    
    <div className={styles.add_bar}>
      <div>
        <Button className={styles.button}
          onClick={() => {
            setOpenFormPractice(true)
            setEditFormPractice(initForm)
          }}
          variant="contained"
          startIcon={<AddIcon/>}>
            {t('Add Practices')}
        </Button>
      </div>
      {nbResult > 0 && <p className={styles.nb_result}>{t(`result`, {count: nbResult})}</p>}
    </div>
    {nbResult > 0 ? (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
            <TableCell width={35}>No</TableCell>
              <TableCell width={100} >{t('Practice')}</TableCell>
              <TableCell width={150} >{t('Email_Address')}</TableCell>
              <TableCell width={500} >{t('Address')}</TableCell>
              <TableCell width={120} align="center">{t('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row: any, index: number) => (
              <TableRow key={`practiceTable-${index}`}>
                <TableCell width={35} component="th" scope="row">
                  {nbPerPage * (page-1) + index+1 }
                </TableCell>
                <TableCell>{row?.name}</TableCell>
                <TableCell>{row?.emailAddress}</TableCell>
                <TableCell>{row?.address}</TableCell>
                <TableCell align="center">
                  <IconButton 
                  onClick={() => {
                    setEditFormPractice({
                      name: row?.name,
                      email: row?.emailAddress,
                      address: row?.addressForm
                    });
                    setOpenFormPractice(true)
                    setIdSelected(row?.recordId)
                  }} >
                    <EditIcon
                      fontSize="small" />
                  </IconButton>
                  <IconButton
                        aria-label="delete"
                        onClick={() => {
                          setIdSelected(row?.recordId)
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton> 
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>  
      <Pagination
        count={Math.ceil(nbResult / nbPerPage)}
        className={styles.pagination}
        shape="rounded"
        size="small"
        onChange={(_event, currentPage) => {
          setPage(currentPage)
          getPractices({
            top: nbPerPage,
            skip: (currentPage - 1) * nbPerPage,
            filter: generateFilter(fieldsFilters, filter)
          })
        }}
        color="primary"
        page={page}
      />
    </>
    ) : !!pending ? (<CircularProgress/>) : (<span>{t('no_result')}</span>)}   
    </>
  )
}

export default Practice