import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import css from "./Dialogs.module.scss";
import { DialogType } from "../../shared/enums/DialogType";
import { AppType } from "../../shared/enums/AppType";
import { UserRole } from "../../shared/enums/UserRole";
import { ICSDUserData } from "../../shared/interfaces/ICSDUser";
import {
  getInstanceUserFLP,
  addUserAsyncFlp,
  updateUserAsyncFlp,
  deleteUserAsyncFlp,
} from "../../services/userServiceFlp";
import {
  addMemberAsyncFlp,
  deleteMemberAsyncFlp,
  getMemberFLP,
  IMemberResponseFlp,
  updateMemberAsyncFlp,
} from "../../services/memberServiceFlp";
import { IDevice } from "../../shared/interfaces/IDeviceList";
import { IMemberDataFlp } from "../../shared/interfaces/IMemberConfFlp";
import { useTranslation } from 'react-i18next';

interface IDialogs {
  isOpen: boolean;
  onDialogClosed: (
    isOpen: boolean,
    isCancelled: boolean,
    data: any | null,
    error?: boolean
  ) => void;
  title: string;
  content: React.ReactNode;
  confirmDisable?: number;
  editIndex?: number;
  editData?:
    | ICSDUserData
    | IMemberDataFlp
    | IDevice
    | IMemberResponseFlp;
  dialogType: string;
  appType?: string;
  updateCountPremiumSeatsInUse?: () => void;
  validated?: boolean;
  updatePremium?: () => void;
}

export default function Dialogs(props: IDialogs) {
  const {
    isOpen,
    onDialogClosed,
    title,
    content,
    editIndex,
    editData,
    appType,
    dialogType,
    validated,
  } = props;
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [successMessage, setSuccessMessage] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState(false);
 
  const {t} = useTranslation();

  const handleAdd = async () => {
    // add controls before submit
    if (dialogType === DialogType.ADD_MEMBER) {
      const member = getMemberFLP();
      if (
        member.name !== "" &&
        member.user.firstName !== "" &&
        member.user.surname !== "" &&
        member.user.emailAddress !== "" &&
        member.userReference !== ""
      ) {
        setErrorMessage("");
        setIsLoading(true);
        await addMemberAsyncFlp(member)
          .then(result => {
            setIsLoading(false);
            onDialogClosed(false, false, result);
          })
          .catch((e) => {
            console.log(e.message);
            setErrorMessage(e.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }

    if (dialogType === DialogType.ADD_USERFLP) {
      const userFlp = getInstanceUserFLP();
      if (
        userFlp.firstName !== "" &&
        userFlp.surname !== "" &&
        userFlp.emailAddress !== "" &&
        userFlp.role !== UserRole.NOT_SET
      ) {
        setIsLoading(true);
        setErrorMessage("");
        try {
          const result = await addUserAsyncFlp(userFlp);
          onDialogClosed(false, false, result);
        } catch (error: any) {
          setErrorMessage(error?.message);
        }
        setIsLoading(false);
      }
    }
  };

  const handleEdit = async () => {
    if (typeof editIndex !== "number" || editIndex < 0) return; // index incorrect cannot edit item

    if (dialogType === DialogType.EDIT_USERFLP) {
      const userFlp = getInstanceUserFLP();
      if (
        userFlp.firstName !== "" &&
        userFlp.surname !== "" &&
        userFlp.emailAddress !== "" &&
        userFlp.role !== UserRole.NOT_SET
      ) {
        const result = await updateUserAsyncFlp(userFlp);
        onDialogClosed(false, false, result);
      }
    }

    if (dialogType === DialogType.EDIT_MEMBER) {
      const member = getMemberFLP();
      if (
        member.name !== "" &&
        member.user.firstName !== "" &&
        member.user.surname !== "" &&
        member.user.emailAddress !== "" &&
        member.userReference !== ""
      ) {
        setErrorMessage("");
        await updateMemberAsyncFlp(member)
          .then(result => {
            onDialogClosed(false, false, result);
          })
          .catch(() => {
            setErrorMessage(t('FailedEditMsg'));
          })
          .finally(() => {});
      }
    }
  };

  const handleDelete = async () => {
    if (typeof editIndex !== "number" || editIndex < 0) return; // index incorrect cannot delete item

    // Delete user fleetportal
    if (appType === AppType.USERSFLP && undefined !== editData) {
      await deleteUserAsyncFlp((editData as ICSDUserData)?.recordId)
        .then(_ => {
          setSuccessMessage(t('DeleteSuccessMsg'));
        })
        .catch(_ => {
          setErrorMessage(t('FailedDeleteMsg'));
        })
        .finally(() => {
          setIsLoading(false);
          onDialogClosed(false, false, null);
        });
    }

    // Delete member fleetportal
    if (appType === AppType.MEMBERS && undefined !== editData) {
      await deleteMemberAsyncFlp((editData as IMemberDataFlp)?.recordId)
        .then(_ => {
          setSuccessMessage(t('DeleteSuccessMsg'));
        })
        .catch(_ => {
          setErrorMessage(t('FailedDeleteMsg'));
        })
        .finally(() => {
          setIsLoading(false);
          onDialogClosed(false, false, null);
        });
    }
  };

  const handleDisable = () => {
    // updateEnabled(confirmDisable || -1); // replace by the api (when back is done)
    onDialogClosed(false, false, null);
  };

  const renderDialogContentByType = (type: string) => {
    switch (type) {
      case DialogType.ADD_MEMBER:
      case DialogType.ADD_USERFLP:
        return (
          <>
            <div className={css.cancel}>
              <Button
                onClick={() => onDialogClosed(false, true, null)}
                variant="outlined"
              >
                {t('Cancel')}
              </Button>
            </div>
            <div className={css.confirm}>
              <Button
                onClick={handleAdd}
                disabled={
                  type === DialogType.ADD_MEMBER
                    ? isLoading || !validated
                    : isLoading
                }
              >
                {isLoading && (
                  <CircularProgress size={20} style={{ color: "#898a8d" }} />
                )}
                {!isLoading && <>{t('Add')}</>}
              </Button>
            </div>
          </>
        );
      case DialogType.EDIT_USERFLP:
      case DialogType.EDIT_MEMBER:
        return (
          <>
            <div className={css.cancel}>
              <Button
                onClick={() => onDialogClosed(false, true, null)}
                variant="outlined"
              >
                {t('Cancel')}
              </Button>
            </div>
            <div className={css.confirm}>
              <Button
                onClick={handleEdit}
                disabled={type === DialogType.EDIT_MEMBER ? !validated : false}
              >
                {t('Save')}
              </Button>
            </div>
          </>
        );
      case DialogType.APPLICATION_INFO:
        return (
          <div className={css.confirm}>
            <Button
              onClick={() => onDialogClosed(false, true, null)}
              variant="outlined"
            >
              {t('Close')}
            </Button>
          </div>
        );
      case DialogType.CONFIRM_DISABLE_DIALOG:
        return (
          <>
            <div className={css.cancel}>
              <Button
                onClick={() => onDialogClosed(false, true, null)}
                variant="outlined"
              >
                {t('Cancel')}
              </Button>
            </div>
            <div className={css.confirm}>
              <Button onClick={handleDisable}>Yes, disable</Button>
            </div>
          </>
        );
      case DialogType.CONFIRM_DELETE:
        return (
          <>
            <div className={css.cancel}>
              <Button
                onClick={() => onDialogClosed(false, true, null)}
                variant="outlined"
              >
                {t('Cancel')}
              </Button>
            </div>
            <div className={css.confirm}>
              <Button onClick={handleDelete} disabled={isLoading}>
                {isLoading && (
                  <CircularProgress size={20} style={{ color: "#898a8d" }} />
                )}
                {!isLoading && <>Delete</>}
              </Button>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => onDialogClosed(false, false, null)}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="form-dialog-title">
          <Box fontWeight={700} fontSize="18px">
            {title}
          </Box>
        </DialogTitle>
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        {errorMessage && (
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        )}
        <DialogContent style={{ minWidth: 470 }}>{content}</DialogContent>
        <DialogActions
          style={{ justifyContent: "center", margin: "90px 0 30px" }}
        >
          {renderDialogContentByType(dialogType)}
        </DialogActions>
      </Dialog>
    </div>
  );
}
