import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  LinearProgress,
  IconButton,
  Tooltip
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import CustomizedDialog from "../../Dialogs/CustomizedDialog";
import {
  addExchangeLabAsyncFlp,
  assignLabsAsync,
  FormatAddress,
  getExchangeLabsAsync,
  getExchangeLabsFilteredAsync,
  getExchangeLabsForMemberAsync,
  getMemberId,
  IExchangeLabList,
  unassignLabsAsync,
} from "../../../services/labExchangeService";
import { ExchangeLabForm } from "../../shared/Organisms";
import { Pagination } from "@mui/material";
import { UserRole } from "../../../shared/enums/UserRole";
import useAuthService from "../../../services/authService";
import ConfirmDeleteDialog from "../../Dialogs/ConfirmDeleteDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { IExchangeLabModel } from "../../../services/labExchangeModel";
import { SearchBar } from "../../../components/shared";
import { useTranslation } from 'react-i18next'; 
import { Alert, Color } from "@mui/material"; 
import {useAlertContext} from "../../Context/AlertContext";
import classes from './ExchangeLabConf.module.scss'


interface IProps {
  type: string;
}

export default function ExchangeLabList(props: IProps) {
  const labPerPage = 10;
  const { type } = props;
  const [labRows, setLabRows] = React.useState<any[]>([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [isLabLoading, setLabLoading] = React.useState<boolean>(false);
  const [labCount, setLabCount] = React.useState<number>(0);
  const [userRole, setUserRole] = React.useState<UserRole>(UserRole.NOT_SET);
  const [userId, setUserId] = React.useState<string>("");
  const [memberId, setMemberId] = React.useState<string>("");
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [title, setTitle] = React.useState(""); 
  const [isAddExLabLoading, setAddExLabLoading] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>(""); 
 
  const alertContext = useAlertContext(); 

  const [editRowData, setEditRowData] = React.useState<any>();
  const [editRowIndex, setEditRowIndex] = React.useState<number>(-1);
 
  const {t} = useTranslation();
  const authService = useAuthService();

  React.useEffect(() => {
    authService.getUserRole().then((aRole: any) => {
      setUserRole(aRole as UserRole);
    });
  }, [authService]);

  // Fetch current user id
  React.useEffect(() => {
    if (UserRole.NOT_SET !== userRole) {
      authService.getUserId().then(result => {
        setUserId(result);
      });
    }
  }, [authService, userRole]);


  const handleAdd = async (
    data: IExchangeLabModel
  ) => {
    // Remove row from data
    try {

    setAddExLabLoading(true);

    if (data) {
      closeDialogBackGroundWork('info', t("AddLabProgress", {lab:data.emailAddress}), true, false); 
      alertContext.setAlertErrorMessage("");
      
      if (userRole === UserRole.FLP_ADMINISTRATOR || userRole === UserRole.FLP_SUPPORT) {
        
        const findExchangeLab = await getExchangeLabsFilteredAsync(data.emailAddress);
        if (findExchangeLab.value.length === 0) {
          try {
            const result = await addExchangeLabAsyncFlp(data);

            const lab = {
              recordId: result.recordId,
              name: result.name,
              emailAddress: result.emailAddress,
              address: result.address,
              actions: "",
            };

            if (labRows.length >= labPerPage) labRows.splice(-1);
            setLabCount(labCount + 1);
            labRows.unshift(lab);
          }
          catch (error: any) {
            throw new Error(t("CannotAddExchangeLab", {lab:data.emailAddress}))
          }

        } else {
          throw new Error(t("LabAlreadyAdded", {lab:data.emailAddress}))
        }
        
      } else if (userRole === UserRole.MEMBER_MANAGER) {
          let resultAssign: IExchangeLabList | null = null;

          const findExchangeLab = await getExchangeLabsFilteredAsync(data.emailAddress);
          if (findExchangeLab.value.length === 0) {

            let newLab = null;
            try {
               newLab = await addExchangeLabAsyncFlp(data);
            }
            catch(error:any){
              throw new Error(t("CannotAddExchangeLab", {lab:data.emailAddress}))
           }

            if(newLab)
            {
              try {
              resultAssign = await assignLabsAsync(userId, newLab.emailAddress);              
              }
              catch(error:any) {
                throw new Error(t("CannotAssignLab", {lab:data.emailAddress}))
               }
            }
          }
          else {
            try {
            resultAssign = await assignLabsAsync(userId, data.emailAddress);
            }
            catch(error:any) {
              throw new Error(t("CannotAssignLab", {lab:data.emailAddress}))
             }
          }

          if(resultAssign)
          {
            const lab = {
              recordId: resultAssign.value[0].recordId,
              name: resultAssign.value[0].name,
              emailAddress: resultAssign.value[0].emailAddress,
              address: resultAssign.value[0].address,
              actions: "",
            };
            
            if (labRows.length >= labPerPage) labRows.splice(-1);
            setLabCount(labCount + 1);
            labRows.unshift(lab);
          }
      }
    } 
  }
    catch (error: any) { 
      alertContext.setAlertErrorMessage(error?.message);
    }   
      
    
    alertContext.setAlertErrorMessage((alertErrorMessage)=> 
    {
      if(alertErrorMessage)
      {
        alertContext.setAlertMessage(alertErrorMessage);
        alertContext.setAlertSeverity("error");
      } else
      {
        alertContext.setAlertMessage(t("AddLabsucess", {lab:data.emailAddress}));
        alertContext.setAlertSeverity("success"); 
      }
      return alertErrorMessage;
    }); 
 
    setAddExLabLoading(false);
    alertContext.setButtonDisabled(false);
  };

  const handleChange = (event: any, value: number, refresh: boolean = false, cancel: boolean = false) => {
    
    if(userId === "")
      return;

    if (refresh || value !== currentPage || cancel) {
      setLabLoading(true);
      setCurrentPage(value);

      const processResponseFLP = (response: any) => {
        if (undefined !== response) {
          setLabCount(response["@odata.count"]);
          setLabRows(response?.value);
        } else setLabRows([]);
        setLabLoading(false);
      };

      if(userRole === UserRole.FLP_ADMINISTRATOR || userRole === UserRole.FLP_SUPPORT )
      {
        getExchangeLabsAsync((value - 1) * labPerPage, labPerPage, cancel ? '' : searchValue).then(
          processResponseFLP
        );
      }else if(userRole === UserRole.MEMBER_MANAGER)
      {
        getExchangeLabsForMemberAsync(userId, (value - 1) * labPerPage, labPerPage, cancel ? '' : searchValue).then(
          processResponseFLP
        );
      }
    }
  };

  const closeDialog = () => {
    setOpenDialog(false); 
    alertContext.setAlertErrorMessage("");
    setAddExLabLoading(false);
  };

  const closeDialogBackGroundWork = (severity: Color, msg: string, isAddingLabEx: boolean, isOpenModal: boolean) => {
    setOpenDialog(isOpenModal);
    alertContext.setAlertMessage(msg); 
    alertContext.setAlertErrorMessage("");
    alertContext.setAlertSeverity(severity);
    setAddExLabLoading(isAddingLabEx);
    alertContext.setOpenAlert(true);
    alertContext.setButtonDisabled(true);
  };

  const unassignedPartner = async () => {
    setLoading(true)
    try {
      if(memberId==="")
        throw new Error("Empty memberId");

      if(!await unassignLabsAsync(memberId, editRowData.recordId))
        throw new Error(`Cannot removed partner: userId:${userId} partnerId:${editRowData.recordId}`);

      if (labRows.length === labPerPage && labCount > labPerPage) handleChange(null, currentPage, true);
      if (labRows.length === 1 && labCount > labPerPage) handleChange(null, 1, true);
      setLabCount(labCount - 1);
      handleChange(null, 1, true);
      labRows.splice(editRowIndex, 1);
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
    closeDialog()
  };

  React.useEffect(() => {
    if(userId !== "" && (userRole === UserRole.MEMBER_MANAGER) ){
      getMemberId(userId).then((id:string)=>{
        setMemberId(id);
      });
    }
  },[userRole, userId]);

  React.useEffect(() => {
    handleChange(null, 1, true)
  },[searchValue]);

  React.useEffect(() => {
    handleChange(null, 1);
  }, [userRole, userId]);

  return (
    <div className={classes.root}>
      <div className="searchbar">
          <div>
          <SearchBar
            onCancelSearch={() => { setSearchValue("") } }
            onRequestSearch={(value: string) => { setSearchValue(value)}}
            tooltipText={t('LabNameEmailAddress') ?? ''}
            buttonLabel={`${t('Search')}`} 
            placeholder={t('LabNameEmailAddress')} >
          </SearchBar>
          </div>
      </div>
      <div className="add-bar">
        <div>
           <Button className={classes.button}
            disabled = {alertContext.isButtonDisabled}
            variant="contained"
            onClick={() => {
              setTitle(t('AddExchangeLab'));
              setOpenDialog(true);
            }}
          >
            <AddIcon />
            {t('AddExchangeLab')}
            </Button>
        </div>
        <p className={classes.nbResult}>{`${labCount} result${
          labCount > 1 ? "s" : ""
        }`}</p>
      </div>     
      <CustomizedDialog
        title={title}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      >
      {title ? (        
      <ExchangeLabForm
            add={handleAdd}
            closeDialog={closeDialog}
             
            loading={isAddExLabLoading}
            />):
            (
              <ConfirmDeleteDialog
                closeDialog={ closeDialog }
                deletedData={ unassignedPartner }
                isLoading={isLoading}
              />
            )}
      </CustomizedDialog>    

      {alertContext.isOpenAlert && <Alert onClose={() => { alertContext.setOpenAlert(false) }}
        severity={alertContext.alertSeverity as Color}>{alertContext.alertMessage}</Alert>} 
      {
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width={35}>{t('No')}</TableCell>
                <TableCell width={125}>{t('Lab_Name')}</TableCell>
                <TableCell width={194}>{t('Email_Address')}</TableCell>
                <TableCell width={200}>{t('Address')}</TableCell>
                {(userRole === UserRole.MEMBER_MANAGER)&&
                 (<TableCell width={50}>{t('Actions')}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {labRows.map((row: any, index: number) => (
                <TableRow key={row?.emailAddress}>
                  <TableCell width={35} component="th" scope="row">
                    {currentPage && (
                      <>
                        {(currentPage - 1) * (labPerPage ? labPerPage : 0) +
                          index +
                          1}
                      </>
                    )}
                  </TableCell>
                  <TableCell width={94}>{row?.name}</TableCell>
                  <TableCell width={194}>{row?.emailAddress || ""}</TableCell>
                  <TableCell width={116}>
                    {
                    // Display postal adress of lab
                    // Display the information that adress is not available if field is empty
                    row&&row.address ? FormatAddress( row.address ) : t('NotAvailable')
                    }
                  </TableCell>   
                  {(userRole === UserRole.MEMBER_MANAGER)&&
                  (<TableCell width={50}>
                    <IconButton
                    aria-label={t('Delete')}
                    onClick={() => {
                      setEditRowIndex(index);
                      setEditRowData(row);
                      setTitle("");
                      setOpenDialog(true);
                    } }
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>                    
                  </TableCell>)} 
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
      {isLabLoading && <LinearProgress style={{ marginTop: `16px` }} />}
      {labCount > 0 && (
        <Pagination
          count={Math.ceil(labCount / labPerPage)}
          shape="rounded"
          size="small"
          onChange={handleChange}
          defaultPage={1}
          page={currentPage}
          className={classes.pagination}
          color="primary"
        />
      )}
    </div>
  );
}
