
import { Dialog as DialogMui, DialogContent, DialogTitle, Box } from "@mui/material";

// Interface
import IDialog from './IDialog';

//Style
import styles from './Dialog.module.scss'

export default function Dialog(props: IDialog) {
  const { title, children, open, onClose } = props;

  return (
    <DialogMui open={open} onClose={onClose}>
      <DialogTitle>
        <Box fontWeight={700} fontSize="18px">
          {title}
        </Box>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        {children}
      </DialogContent>
    </DialogMui>
  )
}
