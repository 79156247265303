import React from "react";
import {
  IUserDataFlp,
  IUserColumnFlp,
} from "../../shared/interfaces/IUserDataFlp";
import {
  IUserRequest,
  getUsersFlpAsync,
} from "../../services/userServiceFlp";
import DenseTable from "../Tables/DenseTable";
import classes from './UserConfFlp.module.scss'

interface IProps {
  type: string;
}

const usersFlpColumns: IUserColumnFlp[] = [
  { id: "recordId", label: "No", minWidth: 10 },
  { id: "firstName", label: "first name", minWidth: 50 },
  { id: "surname", label: "surname", minWidth: 50 },
  { id: "emailAddress", label: "email", minWidth: 50 },
  { id: "role", label: "role", minWidth: 50 },
  { id: "actions", label: "Actions", minWidth: 89, align: "left" },
];

export default function UsersFlpConf(props: IProps) {
  const { type } = props;

  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [rowsData, setRowsData] = React.useState<IUserDataFlp[]>([]);

  const usersRows: IUserDataFlp[] = [];

  const fetchUserInfo = () => {
    getUsersFlpAsync(false).then((data: IUserRequest[]) => {
      for (const value of data) {
        usersRows.push({
          recordId: value.recordId,
          firstName: value.firstName,
          surname: value.surname,
          emailAddress: value.emailAddress,
          role: value.role,
        });
      }
      setRowsData(usersRows);
      setLoading(true);
    });
  };

  React.useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <div className={classes.root}>
      <DenseTable
        columns={usersFlpColumns}
        rows={isLoading ? rowsData : []}
        totalCount={usersRows.length}
        type={type}
      />
    </div>
  );
}
