import http from "../services/api/http";
import getLoadedConfig from "../services/appConfigService";
import IMember from "../interfaces/IMember";

export const getMembers = async (query: string): Promise<IMember[]> => {
  const config = getLoadedConfig();
  const path = `Members?$filter=contains(Name,'` + query + `')`;
  const req = await http.get<any>(config.oDataUrl + path);
  return req.data["value"] as IMember[];
}
