import { combineReducers } from 'redux'

import addressReducer, { IAddressState } from './address'
import practiceReducer, { IPracticeState } from './practices'
import userReducer, { IUserState } from './user'
import deviceReducer, { IDeviceState } from './device';
import exchangeLabReducer, { IExchangeLabState } from './exchangeLab'

export interface IRootStoreState {
  practices: IPracticeState,
  address: IAddressState,
  user: IUserState,
  device: IDeviceState,
  exchangeLab: IExchangeLabState
}

export default combineReducers({
    practices: practiceReducer,
    address: addressReducer,
    user: userReducer,
    device: deviceReducer,
    exchangeLab: exchangeLabReducer
})