import React, { useCallback } from "react";
import css from "./Login.module.scss";
import Button from "@mui/material/Button";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useAuthService from "../../services/authService";
import { useTranslation } from 'react-i18next';



const Login: React.FC<{ isEmployeeLogin: boolean }> = ({ isEmployeeLogin }) => {
  const authService = useAuthService(); 
  const {t} = useTranslation();
  const doLogin = useCallback(() => {
    authService.login(isEmployeeLogin);
  }, [authService, isEmployeeLogin]);

  return (
    <div className={css.landingBackground}>
      <div className={css.logoLandingStyle}>
        <div className={css.betaLogoLandingStyle}>
        </div>
      </div>
      <div className={css.floatLandingContainer}>
        <div className={css.floatLandingChild}>
          <h3 className={css.textLandingStyle}> IS Fleet Portal</h3>
          <div>
            <Button
              className={css.loginLandingBtn}
              startIcon={<ExitToAppIcon />}
              style={{ minWidth: 120 }}
              onClick={doLogin}
            >
              {t('LOGIN')}
            </Button>
          </div>
        </div>
        <div className={css.globImage}>
        </div>
      </div>
    </div>
  );
};

export default Login;
