// Function for get Practice

import requestApi from '../request'
import getParams from '../getParams'
import { GET_PRACTICES } from '../../../redux/type/practices';

interface IGetPracticesParams {
  filter?: string,
  top: number,
  skip?: number,
}

const getPractices = async (params: IGetPracticesParams) => {
  const path = `Practices${getParams({ ...params, expand: 'Address', count: 'true' })}`;
  requestApi(path, 'get', GET_PRACTICES, null, "odata")
}

export default getPractices