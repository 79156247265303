export const GET_PRACTICES = "GET_PRACTICES"
export const GET_PRACTICES_PENDING = "@GET_PRACTICES_PENDING"
export const GET_PRACTICES_SUCCESS = "@GET_PRACTICES_SUCCESS"
export const GET_PRACTICES_FAIL = "@GET_PRACTICES_FAIL"

export const ADD_PRACTICES = "ADD_PRACTICES"
export const ADD_PRACTICES_PENDING = "@ADD_PRACTICES_PENDING"
export const ADD_PRACTICES_SUCCESS = "@ADD_PRACTICES_SUCCESS"
export const ADD_PRACTICES_FAIL = "@ADD_PRACTICES_FAIL"

export const UPDATE_PRACTICES = "UPDATE_PRACTICES"
export const UPDATE_PRACTICES_PENDING = "@UPDATE_PRACTICES_PENDING"
export const UPDATE_PRACTICES_SUCCESS = "@UPDATE_PRACTICES_SUCCESS"
export const UPDATE_PRACTICES_FAIL = "@UPDATE_PRACTICES_FAIL"

export const DELETE_PRACTICE = "DELETE_PRACTICE"
export const DELETE_PRACTICE_PENDING = "@DELETE_PRACTICE_PENDING"
export const DELETE_PRACTICE_SUCCESS = "@DELETE_PRACTICE_SUCCESS"
export const DELETE_PRACTICE_FAIL = "@DELETE_PRACTICE_FAIL"

export const RESET_DELETE_ERROR = "RESET_DELETE_ERROR"
export const RESET_ADD_ERROR = "RESET_ADD_ERROR"
