import { GET_USER_ID, GET_USER_ROLE, GET_MEMBER_ID_SUCCESS, GET_MEMBER_ID_PENDING } from '../type/user'
import { IUserAction } from '../action/user'

export interface IUserState {
  ID: string,
  role: string,
  memberID: string
  loading: boolean
}

const initialState: IUserState = {
  loading: true,
  ID: '',
  role: '',
  memberID: ''
}

export default function (
  state: IUserState = initialState,
  action: IUserAction
) {
  switch (action.type) {
    case GET_USER_ID:
      return {
        ...state,
        ID: action.data
      }
    case GET_USER_ROLE:
      return {
        ...state,
        role: action.data
      }
    case GET_MEMBER_ID_PENDING:
      return {
        ...state,
        loading: true
      }
    case GET_MEMBER_ID_SUCCESS:
      return {
        ...state,
        memberID: action.data.value[0]?.recordId,
        loading: false
      }
    }
    return state
}