import React from "react";
interface IProps {
  title?: string;
  content?: string | React.ReactElement | React.ReactFragment;
  t?: any;
}

interface IState {
  error?: Error;
}

class ErrorBoundary extends React.PureComponent<IProps, IState> {
  state: IState = {};
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error });
    console.error("Render Error:", error, errorInfo);
  }

  render() {
    if (this.state.error) {
      let { t } = this.props;
      const { title = t("Oops! Something went wrong :(") } = this.props;
      const content =
        this.props.content || t("Please reload the page and try again.");

      return (
        <div className="centered">
          <h4 className="elevated" style={{ maxWidth: 400 }}>
            {title}
          </h4>
          <p>{content}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
