import { Store, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer, { IRootStoreState } from './reducer'
import { IRootActions } from './action'

type DispatchType = (args: IRootActions) => IRootActions

export const store: Store<IRootStoreState, IRootActions>  & {
    dispatch: DispatchType
  }= createStore(rootReducer, composeWithDevTools() )



export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch