import ApplianceFalse from '../../assets/images/indications/ico-ApplianceFalse.svg';
import ApplianceTrue from '../../assets/images/indications/ico-ApplianceTrue.svg';
import DentureFalse from '../../assets/images/indications/ico-DentureFalse.svg';
import DentureTrue from '../../assets/images/indications/ico-DentureTrue.svg';
import GuidedSurgeryFalse from '../../assets/images/indications/ico-GuidedSurgeryFalse.svg';
import GuidedSurgeryTrue from '../../assets/images/indications/ico-GuidedSurgeryTrue.svg';
import OrthodonticFalse from '../../assets/images/indications/ico-OrthodonticFalse.svg';
import OrthodonticTrue from '../../assets/images/indications/ico-OrthodonticTrue.svg';
import RestorativeAndImplantFalse from '../../assets/images/indications/ico-RestorativeAndImplantFalse.svg';
import RestorativeAndImplantTrue from '../../assets/images/indications/ico-RestorativeAndImplantTrue.svg';
import SleepDentistryFalse from '../../assets/images/indications/ico-SleepDentistryFalse.svg';
import SleepDentistryTrue from '../../assets/images/indications/ico-SleepDentistryTrue.svg';

// Note: the strings for indications come from CS Connect documentation (bitbucket: csdental/CS Connect/cscdocs/docs/thirdparty/README.md)
// Note: order should be consistent with Global Export Tool
export enum Indications {
  RESTORE_IMPLANT = "RESTORE_IMPLANT",
  GUIDED_SURGERY = "GUIDED_SURGERY",
  ORTHODONTICS = "ORTHODONTICS",
  SLEEP = "SLEEP",
  DENTURE = "DENTURE",
  APPLIANCE = "APPLIANCE"
}

// Note: tooltips should be consistent with Global Export Tool
export const toTooltip = (indication: string) => {
	switch (indication) {
		case Indications.RESTORE_IMPLANT:
      return "Restorative/Implant";
    case Indications.GUIDED_SURGERY:
      return "Guided surgery";
    case Indications.ORTHODONTICS:
      return "Orthodontic";
    case Indications.SLEEP:
      return "Sleep dentistry";
    case Indications.DENTURE:
      return "Denture";
    case Indications.APPLIANCE:
      return "Appliance";
    default:
      return "";
  }
};

export const toIcon = (indication: string, allowed: boolean) => {
  switch (indication) {
    case Indications.RESTORE_IMPLANT:
      return allowed ? RestorativeAndImplantTrue : RestorativeAndImplantFalse;
    case Indications.GUIDED_SURGERY:
      return allowed ? GuidedSurgeryTrue : GuidedSurgeryFalse;
    case Indications.ORTHODONTICS:
      return allowed ? OrthodonticTrue : OrthodonticFalse;
    case Indications.SLEEP:
      return allowed ? SleepDentistryTrue : SleepDentistryFalse;
    case Indications.DENTURE:
      return allowed ? DentureTrue : DentureFalse;
    case Indications.APPLIANCE:
      return allowed ? ApplianceTrue : ApplianceFalse;
    default:
      return undefined;
  }
};