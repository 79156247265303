import React from "react";
import { BarChart, Bar, ScatterChart, Scatter, PieChart, Pie, Cell, Sector, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { useTranslation } from 'react-i18next';
import {getCases} from "../../../services/api/connectCase";
import {IConnectCase} from "../../../interfaces/IConnectCase";
import moment from 'moment';
import {IDevice} from "../../../shared/interfaces/IDevice";
import { getMemberSerialNumbers } from "../../../services/deviceService";
import classes from './dashboard.module.scss';

import { ClickAwayListener } from "@mui/base";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import IMember from "../../../interfaces/IMember";

interface IProps {
  type: string;
}

interface IDeviceCases {
    device: string,
    count: number
}

interface IDeviceLabCases {
    device: string,
    laboratory: string,
    count: number
}

interface ILabCases {
    laboratory: string,
    count: number
}

export default function Dashboard(props: IProps) {
  const {t} = useTranslation();
  const [calendarVisible, setCalendarVisible] = React.useState(false);
  const [allUserDevices, setAllUserDevices] = React.useState<IDevice[]>([]);
  const [userDevices, setUserDevices] = React.useState<IDevice[]>([]);
  const [membersList, setMembersList] = React.useState<IMember[]>([]);
  const [activeMembers, setActiveMembers] = React.useState<string[]>([]);
  const [devicesCases, setDevicesCases] = React.useState<IDeviceCases[]>([]);
  const [labsCases, setLabsCases] = React.useState<ILabCases[]>([]);
  const [deviceLabCases, setDeviceLabCases] = React.useState<any[]>([]);
  const [indicationCases, setIndicationCases] = React.useState<any[]>([]);
  const [pieActiveIndex, setPieActiveIndex] = React.useState<number>(0);
  

  let currentRange = {
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
    displayedStartDate: moment().startOf("month").format("yyyy-MM-DD"),
    displayedEndDate: moment().endOf("month").format("yyyy-MM-DD"),
    key: "selection",
  };

  let localDateRange = sessionStorage.getItem("x-fleet-dashboard-range");
  if(!localDateRange) {
    localDateRange = JSON.stringify(currentRange);
    sessionStorage.setItem("x-fleet-dashboard-range", localDateRange);
  } else {
    currentRange = JSON.parse(localDateRange);
    currentRange.startDate = moment(currentRange.startDate).toDate();
    currentRange.endDate = moment(currentRange.endDate).toDate();
  }
  
  const [currentDateRange, setDateRange] = React.useState(currentRange);

  const toggleCalendar = () => {
    setCalendarVisible(!calendarVisible);
  }

  const saveMembersList =  (devices: IDevice[])=> {
    const members = devices.map((d: IDevice) => d.deviceConfiguration.member);
    const uniqueMembers = [...new Map(members.map(v => [v.recordId, v])).values()];
    setMembersList(uniqueMembers);
    setActiveMembers(uniqueMembers.map(m => m.recordId));    
  }

  const handleDateChange = (ranges: any) => {
    
    const range = {
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      displayedStartDate: moment(ranges.selection.startDate).format("yyyy-MM-DD"),
      displayedEndDate: moment(ranges.selection.endDate).format("yyyy-MM-DD"),
      key: "selection",
    };
    sessionStorage.setItem("x-fleet-dashboard-range", JSON.stringify(range));

    setDateRange(range);
  };

  const filterCase = (c: IConnectCase) => {
    const d = userDevices.filter(d => d.serialNumber === c.deviceSN)[0];
    return d && activeMembers.indexOf(d.deviceConfiguration.memberId) >= 0;
  }

  const refreshDashboard = async() => {
    if(currentDateRange && userDevices) {

      // Get DX IS Connect cases
      const res = await getCases(
        null, 
        null,  
        moment(currentDateRange.startDate).startOf("week").format("yyyy-MM-DD"), 
        moment(currentDateRange.endDate).endOf("week").format("yyyy-MM-DD")
      );
      let cases:IConnectCase[] = res.value;

      // filter cases list
      cases = cases.filter(c => filterCase(c));

      // Build cases lists
      let devices: IDeviceCases[] = [];
      // Add total cases per device
      userDevices.map(d => {
        devices.push({
          device: d.deviceConfiguration?.practice?.name ? `${d.serialNumber}-${d.deviceConfiguration?.practice?.name}` : d.serialNumber,
          // device: `${d.serialNumber}`,
          count: (cases.filter(c => c.deviceSN === d.serialNumber)).length
        })
      });
      // Sort list
      devices.sort((a, b) => (a.count > b.count ? -1 : 1));
      setDevicesCases(devices);

      // Build labs cases
      const names = [...new Set(cases.map(c => c.laboratoryName))];
      let labs: ILabCases[] = [];
      names.map(l => {
        labs.push({
          laboratory: l,
          count: (cases.filter(c => c.laboratoryName === l)).length
        })
      });
      // Sort list
      labs.sort((a, b) => (a.count > b.count ? -1 : 1));
      setLabsCases(labs);

      // Set Device lab cases
      let scatter: IDeviceLabCases[] = [];
      userDevices.map(d => {
        names.map(l => {
          const t = (cases.filter(c => c.laboratoryName === l && c.deviceSN === d.serialNumber)).length;
          if (t > 0) {
            let prac = d.deviceConfiguration?.practice?.name;
            if (!prac) {
              const addrName = (cases.filter(c => c.deviceSN === d.serialNumber))[0]["addressName"];
              prac = addrName ? `(${addrName})` : "";
            }

            scatter.push({
              device: prac ? `${d.serialNumber}-${prac}` : d.serialNumber,
              // device: d.serialNumber,
              laboratory: l,
              count: t
            });
          }
        })
      });
      setDeviceLabCases(scatter);

      // Cases per indication
      let indications = cases.map(c => {return c.caseType;});
      const userIndications = [...new Set(indications)];
      const indCases = userIndications.map(i => {
        return {
          indication: i,
          total: (cases.filter(c => c.caseType === i)).length
        }
      });
      setIndicationCases(indCases);
    }
  }

  const handleMemberClick = (memberId: string) => {
    if (activeMembers.indexOf(memberId) < 0) {
      const list = [...activeMembers, ...[memberId] ];
      setActiveMembers(list);

      // Refresh userDevices
      refreshDevicesList(list)
    }
  }

  const handleMemberDelete = (memberId: string) => {
    const members = activeMembers.filter((m) => m !== memberId);
    setActiveMembers(members);
    // Refresh userDevices
    refreshDevicesList(members)
  }

  const refreshDevicesList = (members: string[]) => {
    const list = allUserDevices.filter(d => members.indexOf(d.deviceConfiguration.memberId) >= 0);
    setUserDevices(list);
  }

  const isActiveMember = (memberId: string) => {
    const members = activeMembers.filter((m) => m === memberId);
    return members && members.length > 0;
  }
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#e815c1'];

  const onPieEnter = (_: any, index: number) => {
    setPieActiveIndex(index);
  };

  React.useEffect(() => {
    // Update cases list for new selected range
    refreshDashboard();
  }, [currentDateRange, userDevices]);

  React.useEffect(() => {
    // Get current user's devices
    const fetchUserDevices =async () => {
      const data = await getMemberSerialNumbers();
      saveMembersList(data);
      setUserDevices(data);
      setAllUserDevices(data);
    }
    fetchUserDevices();    
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h3>{t("Cases overview from")} <span className={classes.dateRange}>{currentDateRange?.displayedStartDate}</span> {t("to")} <span className={classes.dateRange}>{currentDateRange?.displayedEndDate}</span></h3>

        <ClickAwayListener onClickAway={() => setCalendarVisible(false)}>
          <div className={classes.calendar}>
            <button onClick={toggleCalendar}>
              <EditCalendarIcon />
            </button>

            <div className={classes.calendarPopup}>
              {calendarVisible === true && (
                <div className={classes.calendarContainer}>
                  <div className="calendar-arrow"></div>
                  <DateRangePicker
                    onChange={handleDateChange}
                    rangeColors={["var(--primary-color)"]}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                    ranges={[currentDateRange]}
                    months={2}
                    direction="horizontal"
                  />
                </div>
              )}
            </div>
          </div>
        </ClickAwayListener>
        <Stack direction="row" spacing={1}>
          {
            membersList.map((member: IMember, index: number) => {
              return (
                <Chip
                  className={isActiveMember(member.recordId) ? classes.active : ""}
                  label={member.name}
                  onClick={() => handleMemberClick(member.recordId)}
                  onDelete={() => handleMemberDelete(member.recordId)}
                  deleteIcon={<DeleteIcon />}
                />
              )
            })
          }
        </Stack>
      </div>

      <h4>{t("Case total by device")}</h4>
      <div className={classes.chartContainer} style={{height: userDevices.length*20}}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            layout="vertical"
            data={devicesCases}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis type="number" />
            <YAxis type="category" interval={0} width={200} dataKey="device"  tick={<CustomizedAxisLabel />} />
            <Tooltip />
            {/* <Legend /> */}
            <Bar dataKey="count" fill="#4E79A7" barSize={20} maxBarSize={20} />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <h4>{t("Case total by lab")}</h4>
      <div className={classes.chartContainer} style={{height: labsCases.length*20}}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            layout="vertical"
            data={labsCases}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis type="number" />
            <YAxis type="category" interval={0} width={200} dataKey="laboratory" tick={<CustomizedAxisLabel />} />
            <Tooltip />
            {/* <Legend /> */}
            <Bar dataKey="count" fill="#4E79A7" maxBarSize={20} />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <h4>{t("Practice - Lab")}</h4>
      <div className={classes.chartContainer} style={{height: (deviceLabCases.length*20 + 200)}}>
        <ResponsiveContainer width="100%" height="100%">
          <ScatterChart>
            <CartesianGrid />
            <XAxis type="category" height={200} interval={0} dataKey="laboratory" allowDuplicatedCategory={false} name="Laboratory"  tick={<ObliqueAxisLabel />} />
            <YAxis type="category" width={200} dataKey="device" allowDuplicatedCategory={false} name="Device" />            
            <ZAxis type="number" dataKey="count" range={[200, 1000]} name="Cases count" />
          
            <Tooltip cursor={{ strokeDasharray: '3 3' }} />
            <Scatter data={deviceLabCases} fill="#4E79A7">
              <LabelList fill="white" dataKey="count" />
            </Scatter>
          
          </ScatterChart>
        </ResponsiveContainer>
      </div>

      <h4>{t("Case total by indication")}</h4>
      <div className={classes.chartContainer} style={{height: 400}}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Pie
              activeIndex={pieActiveIndex}
              activeShape={renderActiveShape}
              data={indicationCases}
              cx="50%"
              cy="50%"
              innerRadius={100}
              outerRadius={150}
              fill="#8884d8"
              dataKey="total"
              onMouseEnter={onPieEnter} >
                {indicationCases.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

function CustomizedAxisLabel(props: any) {  
  const { x, y, stroke, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} textAnchor="end" fill="#666" font-size="smaller">
        {payload.value}
      </text>
    </g>
  );
}

function ObliqueAxisLabel(props: any) {  
  const { x, y, stroke, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} textAnchor="end" fill="#666" font-size="smaller" transform="rotate(-60)">
        {payload.value}
      </text>
    </g>
  );
}

function renderActiveShape(props: any) {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, indication } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${indication}: ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};
