/* Display types  */
export enum AppType {
  QUICK_CONNECT_PARTNERS = "quickConnectPartners",
  MEMBERS = "members",
  DEVICES = "devices",
  CSD = "csd",
  DEALERS = "dealers",
  USERSFLP = "usersFlp",
  LABEXCHANGE = "labExchange",
  AIDETECTION = "aiDetection",
  DEALER_USERS = "dealerUsers",
  PRACTICE = "practice",
  DASHBOARD_OVERVIEW = "dashboard_overview",
  DASHBOARD_PRACTICE_CASES = "dashboard_practice_cases",
  DASHBOARD = "dashboard",
  DASHBOARD_CASESLIST = "dashboard_cases_list"
}
