import React, { useState } from 'react'

// Interface
import IAddressField, {IAddress} from './IAddressField';

//Style
import styles from './AddressField.module.scss'

import { InputForm } from '../../Atoms';
import { CountryCode } from '../../../../shared/enums/CountryCode';
import { MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';


const AddressField: React.FC<IAddressField> = (props) => {
  const {
    address,
    onChange,
    disabled,
  } = props

  const { t } = useTranslation();
  const [ addressLocal, setAddressLocal ] = useState(address);

 
  const handleOnChange = (address: IAddress) => {
    setAddressLocal(address);
    onChange(address)
  };

  return(
    <div>
      <InputForm
        title={t('Address')}
        value={address.line1}
        placeholder={t('Address')}
        onChange={(newLine: string) => handleOnChange({
          ...addressLocal,
          line1: newLine
        })}
        disabled={!!disabled}
      />
      <div className={styles.containerHorizontal}>
        <InputForm
          className={styles.city}
          value={address.city}
          placeholder={t('City')}
          onChange={(newCity: string) => handleOnChange({
            ...addressLocal,
            city: newCity
          })}
          disabled={!!disabled}
        />
        <InputForm
          className={styles.region}
          value={address.region}
          placeholder= {t('RegionOption')}
          onChange={(newRegion: string) => handleOnChange({
            ...addressLocal,
            region: newRegion
          })}
          disabled={!!disabled}
        />
      </div>
      <div className={styles.containerHorizontal}>
        <InputForm
          className={styles.zip}
          value={address.zip}
          placeholder={t('ZipCode')}
          onChange={(newZip: string) => handleOnChange({
            ...addressLocal,
            zip: newZip
          })}
          disabled={!!disabled}
          />
        <Select 
          className={styles.country}
          displayEmpty
          variant="outlined"
          value={address?.country ? address.country : ''}
          onChange={(event: any) => handleOnChange({
            ...addressLocal,
            country: event.target.value
          })}
          disabled={!!disabled}
        >
          <MenuItem value="">{t('Country')}</MenuItem>
          {Object.entries(CountryCode).sort((a, b) => t(a[1]).localeCompare( t(b[1]))).map( (item) => <MenuItem key={item[0]} value={item[0]}>{t(item[1])}</MenuItem>)}
        </Select >
      </div>
      
    </div>
  )
}

export default AddressField