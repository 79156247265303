import { RecordStatus } from "../shared/enums/RecordStatus";
import http from "./api/http";
import getLoadedConfig from "./appConfigService";
import { IUserRequest } from "./userServiceFlp";
import { BuildUserReference } from "../shared/utils/utils";
import { UserRole } from "../shared/enums/UserRole";

const member: IMemberRequestFlp = {
  recordId: "",
  name: "",
  invite: false,
  userReference: "",
  user: {
    recordId: "",
    firstName: "",
    surname: "",
    emailAddress: "",
    role: "",
  },
};

export interface IMemberRequestFlp {
  recordId: string;
  name: string;
  invite: boolean;
  userReference: string;
  user: IUserRequest;
}

export interface IMemberResponseFlp {
  recordId: string;
  name: string;
  userReference: string;
  userId: string;
  user: IUserRequest;
}

export const updateRecordId = (memberRecordId: string) => {
  member.recordId = memberRecordId;
};

export const updateUserRecordId = (userRecordId: string) => {
  member.user.recordId = userRecordId;
};

export const updateMembersNameFlp = (name: string) => {
  member.name = name;
};

export const updateUserFirstNameFlp = (userFirstName: string) => {
  member.user.firstName = userFirstName;
};

export const updateUserSurnameFlp = (userSurname: string) => {
  member.user.surname = userSurname;
};

export const updateEmailFLP = (email: string) => {
  member.user.emailAddress = email;
};

export const getMemberFLP = (): IMemberRequestFlp => {
  return member;
};

export const updateMembersUserReferenceFlp = (userReference: string[]) => {
  member.userReference = BuildUserReference(
    userReference,
    member.userReference
  );
};

export const getMembersFlpAsync = async (
  includeInactive: boolean,
  start: number,
  memberPerPage: number,
  searchValue: string
): Promise<IMemberResponseFlp[]> => {
  let query = getLoadedConfig().oDataUrl + "Members";

  // Filter by record status inorder to have only the active user
  if (!includeInactive)
    query += `/?$filter=(recordStatus eq '${RecordStatus.ACTIVE}')`;

  if (searchValue) {
    searchValue = searchValue.toLowerCase();
    query += `and (contains(tolower(Name),'${searchValue}') or 
    contains(tolower(user/FirstName),'${searchValue}') or 
    contains(tolower(user/Surname),'${searchValue}') or 
    contains(tolower(user/EmailAddress),'${searchValue}'))`;
  }

  query += `&$expand=user&$count=true&$top=${memberPerPage}&$skip=${start}&$orderby=recordCreated desc`;

  const req = await http.get(query);

  return req.data;
};

export const addMemberAsyncFlp = async (
  data: IMemberRequestFlp
): Promise<IMemberRequestFlp> => {
  const req = await http.post(
    getLoadedConfig().oDataUrl + "Members?$expand=user",
    {
      name: data.name,
      userReference: data.userReference,
      user: {
        firstName: data.user.firstName,
        surname: data.user.surname,
        emailAddress: data.user.emailAddress,
        role: UserRole.MEMBER_MANAGER, //A member is a member manager
      },
    }
  );

  return req.data.value[0];
};

export const updateMemberAsyncFlp = async (
  data: IMemberRequestFlp
): Promise<IMemberRequestFlp[]> => {
  const req = await http.put(
    getLoadedConfig().oDataUrl + `members(${data.recordId})?$expand=user`,
    {
      recordId: data.recordId,
      userReference: data.userReference,
      name: data.name,
      user: {
        recordId: data.user.recordId,
        firstName: data.user.firstName,
        surname: data.user.surname,
        emailAddress: data.user.emailAddress,
      },
    }
  );

  return req.data.value[0];
};

export const deleteMemberAsyncFlp = async (
  recordId: string
): Promise<IMemberRequestFlp[]> => {
  const req = await http.delete(
    getLoadedConfig().oDataUrl + `Members(${recordId})`
  );

  return req.data.value[0];
};

export const getMembersList = async (
): Promise<IMemberResponseFlp[]> => {
    let query = getLoadedConfig().oDataUrl + "Members";
  
    query += `?$select=recordId,name&$orderby=name asc`;
  
    const req = await http.get(query);
  
    return req.data?.value;
};
