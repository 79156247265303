import React from "react";
import css from "./PageFooter.module.scss";

export const Footer: React.FC = () => {

  return (
    <>
    <div className={css.pageFooter}>
      <a href="/terms-of-use" className={css.termsOfUseLink}>Terms of Use</a>
      <p>
        &copy; Copyright Dental Imaging Technologies Corporation. All Rights
        Reserved.
      </p>
    </div>
    </>
  );
};

export default Footer;
