import { RESET_DELETE_ERROR } from '../type/practices'
import { RESET_ADD_ERROR } from '../type/practices'

export type IPracticeAction = {
  type: string,
  data?: any
}

export const resetErrorMessage: IPracticeAction = {
  type: RESET_DELETE_ERROR
}

export const resetAddErrorMessage: IPracticeAction = {
  type: RESET_ADD_ERROR
}