import http from "./api/http";
import getLoadedConfig from "./appConfigService";
import { IExchangeLabModel } from './labExchangeModel';
import {
  IDevice,
  IDeviceConfiguration,
  IPartnerDevicesConfiguration,
} from "../shared/interfaces/IDeviceList";

/* cs admin */
export interface IDeviceCsAdminResultFLP {
  serialNumber: string;
  active: boolean;
  countryCode: string;
  deviceType: string;
  entityId: number;
  practiceName: string;
  status: string;
  userRef: string;
  userTypeCode: string;
}
export interface IDeviceCsAdminResultListFLP {
  count: number;
  value: IDevice[];
}

/* member */
export interface IDeviceResultModelFLP {
  serialNumber: string;
  configuration: string;
  iosType: string;
  dateActivation: string;
  memberId: string;
  practiceId: string;
  recordId: string;
  recordCreated: string;
  member: any;
  practice: any;
}
export interface IDeviceResultListFLP {
  count: number;
  value: IDevice[];
}

export const getDevicesForCsAdmin = async (
  start: number,
  numberOfDevice: number
): Promise<any> => {
  const config = getLoadedConfig();
  let url =
    config.oDataUrl +
    `Devices?$count=true&$top=${numberOfDevice}&$skip=${start}`;

  const req = await http.get<any>(url);
  const count = req.data["@odata.count"];
  const resp: IDeviceCsAdminResultListFLP = {
    count: count ? parseInt(count) : 0,
    value: req.data?.value,
  };
  return resp;
};

export const getMemberSerialNumbers = async () => {
  const config = getLoadedConfig();

  let url =
    config.oDataUrl +
    `Devices/GetForMember()?$expand=deviceConfiguration($expand=Practice($expand=address($select=city, countryCode, postCode,region)),member($select=name,recordId))&$select=serialNumber`;

  const req = await http.get<any>(url);
  const resp: IDevice[] = req.data?.value;
  return resp;
};

export const addOrUpdateDeviceConfiguration = async (deviceConfigurationId: string, memberId:string|undefined,
  serialNumber:string, labs: IExchangeLabModel[], practiceId: string|undefined): Promise<IDeviceConfiguration> => {

   const labsList = labs.map((l: IExchangeLabModel) => {
    return {
      deviceId: deviceConfigurationId ? deviceConfigurationId : undefined,
      partnerId: l.recordId
    };
  });

  const reqData = {
    recordId: deviceConfigurationId ? deviceConfigurationId : undefined,
    serialNumber: serialNumber,
    memberId: memberId,
    practiceId: practiceId,
    partnerDevices: labsList
  };

  const query = 'Devices(' + deviceConfigurationId + ')';
  const req = await http.put<any>( getLoadedConfig().oDataUrl + query, reqData);

  return req?.data;
}

export const formatLabsName = (devicesConf: IPartnerDevicesConfiguration[]): string =>
devicesConf ? devicesConf.map((it: IPartnerDevicesConfiguration) => it?.partner?.name)?.join(", ") : "";