import { UserRole } from "../shared/enums/UserRole";
import { IUserDataFlp } from "../shared/interfaces/IUserDataFlp";
import http from "./api/http";
import getLoadedConfig from "./appConfigService";
import { RecordStatus } from "../shared/enums/RecordStatus";

export interface IUserRequest {
  recordId: string;
  firstName: string;
  surname: string;
  emailAddress: string;
  role: string;
}

let userFlp: IUserDataFlp = {
  recordId: "",
  firstName: "",
  surname: "",
  emailAddress: "",
  role: UserRole.NOT_SET,
};
export const setInstanceUserFLP = (data: IUserDataFlp) => {
  userFlp = data;
};

export const getInstanceUserFLP = () => {
  return userFlp;
};

export const getUsersFlpAsync = async (
  includeInactive: boolean
): Promise<IUserRequest[]> => {
  let url = getLoadedConfig().oDataUrl + "Users";

  // Filter by record status inorder to have only the active user
  if (!includeInactive)
    url += `/?$filter=(RecordStatus eq '${RecordStatus.ACTIVE}')`;

  const req = await http.get<any>(url);

  return req.data["value"] as IUserRequest[];
};

export const addUserAsyncFlp = async (
  data: IUserRequest
): Promise<IUserRequest[]> => {
  const req = await http.post(getLoadedConfig().oDataUrl + "Users", {
    firstName: data.firstName,
    surname: data.surname,
    emailAddress: data.emailAddress,
    role: data.role,
  });

  return req.data.value[0];
};

export const updateUserAsyncFlp = async (
  data: IUserRequest
): Promise<IUserRequest[]> => {
  const req = await http.put(
    getLoadedConfig().oDataUrl + `Users(${data.recordId})`,
    {
      recordId: data.recordId,
      firstName: data.firstName,
      surname: data.surname,
      emailAddress: data.emailAddress,
      role: data.role,
    }
  );

  return req.data.value[0];
};

export const deleteUserAsyncFlp = async (
  recordId: string
): Promise<IUserRequest[]> => {
  const req = await http.delete(
    getLoadedConfig().oDataUrl + `Users(${recordId})`
  );

  return req.data["value"];
};
