import { LinearProgress, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Tooltip } from '@mui/material';
import { Pagination } from '@mui/material';
import React from 'react'
import advanced from '../../../../assets/images/advanced.svg';
import { getQuickConnectPartners, IQuickConnectPartner } from '../../../../services/quickConnectPartnersService';
import { Indications, toTooltip, toIcon } from '../../../../enum/FleetPortalMode/Indications'
import { useTranslation } from 'react-i18next';
import classes from './AdminQuickConnectPartners.module.scss'
  
export default function AdminQuickConnectPartners(props: any) {
    const itemPerPage = 10;
    const logoUrlStart = 'data:image/';
    const [currentPage, setCurrentPage] = React.useState(1);
    const [csdQuickConnectPartners, setCsdQuickConnectPartners] = React.useState([]);
    const [isQCPLoading, setQCPLoading] = React.useState<boolean>(false);
    const [count, setCount] = React.useState(0); 
    const {t} = useTranslation();
    const handleChange = React.useCallback( async (event: any, value: number) => {
        setCurrentPage(value);
        setQCPLoading(true);
        const csdQuickConnectPartners = await getQuickConnectPartners(
            (value - 1) * itemPerPage,
            itemPerPage
        );
        setQCPLoading(false);
        setCsdQuickConnectPartners(csdQuickConnectPartners?.value);
        setCount(csdQuickConnectPartners.count);
    }, []);

    React.useEffect(() => {
        handleChange(null, 1);
    }, [handleChange]);

    // This returns for a given partner the graphic object containing its indications icons and tooltips
    const createIndicationsForPartner = (partner: IQuickConnectPartner) => {
      const displayedIndications = [];
      for (const indication in Indications) {
        const tooltipText = toTooltip(indication);  
        const translatedTooltipText = t(tooltipText);
        displayedIndications.push(
          <Tooltip key={partner.recordId + indication} title={translatedTooltipText} placement="top" >
            <img src={toIcon(indication, partner?.indications ? partner.indications.includes(indication) : false)} alt="edit" className={classes.indicationLogo} />
          </Tooltip>
        );
      }
      return displayedIndications;
    }

    return (
        <div className={classes.root}>
            <div className='add-bar'>
                <div></div>
                <p className={classes.nbResult}>{`${count} result${
                count > 1 ? 's' : ''
                }`}</p>
            </div> 
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell width={35}></TableCell>
                            <TableCell width={350}>{t('Lab_Name')}</TableCell>
                            <TableCell width={190} align='center'>{t('Advanced')}</TableCell>
                            <TableCell width={690}>{t('Indications')}</TableCell>
                            <TableCell width={100}>{t('Countries')}</TableCell>
                            <TableCell width={84}>{t('Enable')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {csdQuickConnectPartners.map((csdQuickConnectPartner: IQuickConnectPartner, index: number) => (
                            <TableRow key={csdQuickConnectPartner.recordId}>
                                <TableCell component='th' scope='row'>
                                    {currentPage && (
                                    <>
                                        {(currentPage - 1) * (itemPerPage ? itemPerPage : 0) +
                                        index +
                                        1}
                                    </>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Grid container justify='space-between'>
                                      <Grid item style={{margin: 'auto 0'}}><p>{csdQuickConnectPartner.name}</p></Grid>
                                      {csdQuickConnectPartner?.logo?.startsWith(logoUrlStart) ? 
                                        <Grid item style={{margin: 'auto 0'}}><img src={csdQuickConnectPartner.logo} alt={csdQuickConnectPartner.name} className={classes.logo}/></Grid>
                                        : <></>
                                      }
                                    </Grid>
                                </TableCell>
                                <TableCell align='center'>
                                    {csdQuickConnectPartner.partnerType === 'Advanced' ? <img src={advanced} alt={csdQuickConnectPartner.name} /> : ''}
                                </TableCell>
                                <TableCell>{
                                  <>
                                    {createIndicationsForPartner(csdQuickConnectPartner)}
                                  </>
                                }</TableCell>
                                <TableCell className={classes.countriesCell}>{csdQuickConnectPartner.countries ?? "All"}</TableCell>
                                <TableCell>
                                    {<Switch
                                        checked={csdQuickConnectPartner.enable}
                                        name={csdQuickConnectPartner.name}
                                        color='primary'
                                        disabled={true}
                                    />}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {isQCPLoading && <LinearProgress style={{ marginTop: `16px` }} />}
            {count > 0 && (
                <Pagination
                count={Math.ceil(count / itemPerPage)}
                shape='rounded'
                size='small'
                onChange={handleChange}
                defaultPage={1}
                className={classes.pagination}
                color='primary'
                />
            )}
        </div>
    )
}
