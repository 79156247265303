import { createTheme } from '@mui/material/styles';

// Create a theme instance.
export const theme = createTheme({
  palette: {
    primary: {
      main: "#36B0C9",
    },
    secondary: {
      main: "#FCB614", // for premium
    },
  }, 
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip:{
          backgroundColor:"white",
          color: "black",
          border: "solid skyblue 1px",
          fontSize: "10px", 
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          minWidth: 700,
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          maxHeight: 610,
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-of-type(even)": {
            backgroundColor: "#E9E9E9",
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: "left",
        },
        head: {
          backgroundColor: "#E9E9E9",
          color: "#262626",
          fontSize: "14px",
          fontWeight: "bold",
        },
        body: {
          fontSize: 12,
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minWidth: 120,
          margin: 0,
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontWeight: "bold",
          margin: 0,
          fontSize: "14px",
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: "28px",
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        color: "primary",
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        containedPrimary:{
          backgroundColor: '#36b0c9',
          fontSize: '12px',
          marginLeft: '16px',
          minWidth: '111px',
          height: '32px',
          padding: '0',
          color: '#fff',
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: "#000",
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        InputLabelProps: {
          shrink: true,
        }
      }
    }
  }
});
