import http from "./api/http";
import getLoadedConfig from "./appConfigService";
import { IExchangeLabModel } from "./labExchangeModel";

export const getAssociatedPartners = async (deviceConfigurationId:string):Promise<IExchangeLabModel[]> =>
{
  const config = getLoadedConfig();

  const query = `PartnerDevices/GetPartners?deviceId=${deviceConfigurationId}&$orderby=RecordCreated desc`;

  const req = await http.get<IExchangeLabModel[]>(config.apiUrl + query);

  return req.data;
}

export const assignPartners = async (deviceConfigurationId:string, partnerId:string):Promise<boolean> =>
{
  const config = getLoadedConfig();

  const query = "PartnerDevices/AssignPartners";

  const req = await http.post(config.apiUrl + query,
    {
      deviceConfigurationId: deviceConfigurationId,
      partnerId: partnerId
    }
  );

  return req.data;
}

export const unassignPartners = async (deviceConfigurationId:string, partnerId:string):Promise<boolean> =>
{
  const config = getLoadedConfig();

  const query = "PartnerDevices/UnassignPartners";

  const req = await http.post(config.apiUrl + query,
    {
      deviceConfigurationId: deviceConfigurationId,
      partnerId: partnerId
    }
  );

  return req.data;
}

export const assignPractice = async(deviceConfigurationId:string, practiceId:string):Promise<boolean> =>
{
  const config = getLoadedConfig();

  const query = "PartnerDevicesConfiguration/AssignPractice";

  const req = await http.post(config.apiUrl + query,
    {
      deviceConfigurationId: deviceConfigurationId,
      practiceId: practiceId
    }
  );

  return req.data;
}
