import { LinearProgress, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Tooltip } from '@mui/material';
import { Pagination } from '@mui/material';
import React from 'react'
import useAuthService from "../../../../services/authService";
import { getMemberQuickConnectPartners, IQuickConnectPartner } from '../../../../services/quickConnectPartnersService';
import { Indications, toTooltip, toIcon } from '../../../../enum/FleetPortalMode/Indications'
import { useTranslation } from 'react-i18next';
import classes from './MemberQuickConnectPartners.module.scss'

export default function MemberQuickConnectPartners(props: any) {
    const itemPerPage = 10;
    const logoUrlStart = 'data:image/';
    const [currentPage, setCurrentPage] = React.useState(1);
    const [memberQuickConnectPartners, setMemberQuickConnectPartners] = React.useState([]);
    const [isQCPLoading, setQCPLoading] = React.useState<boolean>(false);
    const [count, setCount] = React.useState(0);
    const [userId, setUserId] = React.useState<string>(""); 
    const {t} = useTranslation();

    const authService = useAuthService();

    React.useEffect(() => {
          authService.getUserId().then(result => {
            setUserId(result);
          });        
      }, [authService]);

    const handleChange = React.useCallback(async (event: any, value: number) => {
        if (userId !== "") {
            setCurrentPage(value);
            setQCPLoading(true);

            const memberQuickConnectPartners = await getMemberQuickConnectPartners(
                (value - 1) * itemPerPage,
                itemPerPage
            );

            setQCPLoading(false);
            setMemberQuickConnectPartners(memberQuickConnectPartners?.value);
            setCount(memberQuickConnectPartners.count);
        }
    }, [userId]);

    React.useEffect(() => {
        handleChange(null, 1);
    }, [handleChange,userId]);

    // This returns for a given partner the graphic object containing its indications icons and tooltips
    const createIndicationsForPartner = (partner: IQuickConnectPartner) => {
      const displayedIndications = [];
      for (const indication in Indications) {
        const tooltipText = toTooltip(indication);  
        const translatedTooltipText = t(tooltipText);
        displayedIndications.push(
          <Tooltip key={partner.recordId + indication} title={translatedTooltipText} placement="top">
            <img src={toIcon(indication, partner?.indications ? partner.indications.includes(indication) : false)} alt="edit" className={classes.indicationLogo} />
          </Tooltip>
        );
      }
      return displayedIndications;
    }

    return (
        <div className={classes.root}>
            <div className='add-bar'>
                <div></div>
                <p className={classes.nbResult}>{`${count} result${
                count > 1 ? 's' : ''
                }`}</p>
            </div> 
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell width={35}>{t('No')}</TableCell>
                            <TableCell width={876}>{t('QuickConnectPartners')}</TableCell>
                            <TableCell width={690}>{t('Indications')}</TableCell>
                            <TableCell width={100}>{t('Countries')}</TableCell>
                            <TableCell width={263}>{t('Enable')}</TableCell>
                            <TableCell width={174}>{t('Disable')} status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {memberQuickConnectPartners.map((memberQuickConnectPartner: IQuickConnectPartner, index: number) => (
                            <TableRow key={memberQuickConnectPartner.recordId}>
                                <TableCell component='th' scope='row'>
                                    {currentPage && (
                                    <>
                                        {(currentPage - 1) * (itemPerPage ? itemPerPage : 0) +
                                        index +
                                        1}
                                    </>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Grid container>
                                      <Grid item style={{margin: 'auto 0', minWidth: 235}}><p>{memberQuickConnectPartner.name}</p></Grid>
                                      {memberQuickConnectPartner?.logo?.startsWith(logoUrlStart) ? 
                                        <Grid item style={{margin: 'auto 0'}}><img src={memberQuickConnectPartner.logo} alt={memberQuickConnectPartner.name} className={classes.logo}/></Grid>
                                        : <></>
                                      }
                                    </Grid>
                                </TableCell>
                                <TableCell>{ 
                                  <>
                                    {createIndicationsForPartner(memberQuickConnectPartner)}
                                  </>
                                }</TableCell>
                                <TableCell>{
                                memberQuickConnectPartner.countries?.split(',').join(", ") ?? "All"}</TableCell>
                                <TableCell>
                                    {<Switch
                                        checked={memberQuickConnectPartner.enable}
                                        name={memberQuickConnectPartner.name}
                                        color='primary'
                                        disabled={false}
                                    />}
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {isQCPLoading && <LinearProgress style={{ marginTop: `16px` }} />}
            {count > 0 && (
                <Pagination
                count={Math.ceil(count / itemPerPage)}
                shape='rounded'
                size='small'
                onChange={handleChange}
                defaultPage={1}
                className={classes.pagination}
                color='primary'
                />
            )}
        </div>
    )
}
