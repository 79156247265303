import React, { useCallback, useEffect, useState } from "react";
import { AccountInfo } from "@azure/msal-browser";
import css from "./PageHeader.module.scss";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import logo from "../../assets/images/dexis-logo.png";
import betaLogo from "../../assets/images/beta.svg";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { configContext } from "../../shared/useAppConfig";
import useAuthService from "../../services/authService";
import { Avatar, Divider, ListItemIcon } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import InfoIcon from "@mui/icons-material/Info";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Dialogs from "../Dialogs";
import { DialogType } from "../../shared/enums/DialogType";
import AppInfoDialog from "../Dialogs/AppInfoDialog/AppInfoDialog";
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next'; 
import i18next from "i18next";
import { Link } from "react-router-dom";


export interface IProps {
  account: AccountInfo | null;
}

const langArray:{codeLang:string, label: string}[] = [
  {
    codeLang: 'en',
    label: 'English'
  },
  {
    codeLang: 'fr',
    label: 'Français'
  }
];

export const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userDisplayName, setUserDisplayName] = useState("Uknown");
  const [lang, setLang] = useState<{ codeLang: string; label: string }>(
      langArray.find(lang => lang.codeLang === i18next.resolvedLanguage) ?? langArray[0]
    );
  const [userAvatar, setUserAvatar] = useState("U");

  const openBoolean = Boolean(anchorEl);

  const {t} = useTranslation();
  const authService = useAuthService();

  const logout = useCallback(
    async () => await authService.logout(),
    [authService]
  );


  useEffect(() => {
    const fetchUserDetails = async () => {
      const avatar = await authService.getUserAvatar();
      setUserAvatar(avatar);
      const displayName = await authService.getDisplayName();
      setUserDisplayName(displayName);
    };

    fetchUserDetails();
  }, [authService]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: string }>
  ) => {
    const value = event.target.value;
    const lang = langArray.find(lang => lang.codeLang === value) ?? langArray[0];
    i18n.changeLanguage(value);
    setLang(lang);
  };

  const config = React.useContext(configContext);

  const [showAppInfoDialog, setShowAppInfoDialog] = useState(false);

  return (
    <>
      <div className={css.pageHeader}>
        <Link to={"/"}>
          <div className={css.logo}>
            <img src={logo} alt="Dexis logo" />
            <div className={css.betaLogo}>
              <img src={betaLogo} alt="beta logo" />
            </div>
          </div>
        </Link>
        <div className={css.account}>
          <div onClick={handleMenu}>
            <Avatar className={css.avatar}>{userAvatar}</Avatar>
          </div>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openBoolean}
            onClose={handleClose}
          >
            <MenuItem disabled={true}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              {userDisplayName}
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => setShowAppInfoDialog(true)}>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              {t('Info')}
            </MenuItem>
            <Divider />
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              {t('Logout')}
            </MenuItem>
          </Menu>
          <FormControl>
            <NativeSelect
              className={css.selectEmpty}
              value={lang.codeLang}
              name="lang"
              onChange={handleChange}
            >
              {langArray.map( (lang:{codeLang: string, label: string}) => <option value={lang.codeLang}>{lang.label}</option>)}
            </NativeSelect>
          </FormControl>
        </div>
      </div>
      {showAppInfoDialog && (
        <Dialogs
          title=""
          dialogType={DialogType.APPLICATION_INFO}
          isOpen={true}
          onDialogClosed={() => setShowAppInfoDialog(false)}
          content={<AppInfoDialog />}
        />
      )}
    </>
  );
};

export default Header;
