import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { BarChart, LineChart, Bar, Line, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';
import {getCases} from "../../../services/api/connectCase";
import {IConnectCase} from "../../../interfaces/IConnectCase";
import moment from 'moment';
import {IDevice} from "../../../shared/interfaces/IDevice";
import { getMemberSerialNumbers } from "../../../services/deviceService";
import classes from './PracticeCases.module.scss';

import { ClickAwayListener } from "@mui/base";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';

 

interface IProps {
  type: string;
}

interface IWeek {
    firstDay: string,
    week: number,
    downloadedCases: number,
    distinctDevicesCount: number
}

export default function PracticeCases(props: IProps) {
  const { type } = props;
  const {t} = useTranslation();
  const [calendarVisible, setCalendarVisible] = React.useState(false);
  const [allUserDevices, setAllUserDevices] = React.useState<IDevice[]>([]);
  const [userDevices, setUserDevices] = React.useState([]);
  const [devicesStats, setDevicesStats] = React.useState([]);
  const [membersList, setMembersList] = React.useState<IMember[]>([]);
  const [activeMembers, setActiveMembers] = React.useState<string[]>([]);

  let currentRange = {
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
    displayedStartDate: moment().startOf("month").format("yyyy-MM-DD"),
    displayedEndDate: moment().endOf("month").format("yyyy-MM-DD"),
    key: "selection",
  };

  let localDateRange = sessionStorage.getItem("x-fleet-dashboard-range");
  if(!localDateRange) {
    localDateRange = JSON.stringify(currentRange);
    sessionStorage.setItem("x-fleet-dashboard-range", localDateRange);
  } else {
    currentRange = JSON.parse(localDateRange);
    currentRange.startDate = moment(currentRange.startDate).toDate();
    currentRange.endDate = moment(currentRange.endDate).toDate();
  }
  
  const [currentDateRange, setDateRange] = React.useState(currentRange);


  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [weeksRange, setWeeksRange] = React.useState<IWeek[]>([]);

  const toggleCalendar = () => {
    setCalendarVisible(!calendarVisible);
  }

  const saveMembersList =  (devices: IDevice[])=> {
    const members = devices.map((d: IDevice) => d.deviceConfiguration.member);
    const uniqueMembers = [...new Map(members.map(v => [v.recordId, v])).values()];
    setMembersList(uniqueMembers);
    setActiveMembers([...uniqueMembers.map(m => m.recordId)]);    
  }

  const getDeviceCasesCount = (cases:IConnectCase[], sn:string, weekNumber: number|null) => {
    const list = cases.filter(c => c.deviceSN == sn && (c.weekNumber == weekNumber || weekNumber === null));
    return list ? list.length : 0;
  }

  const getWeeklyDownloadedCases = (cases:IConnectCase[], weekNumber: number) => {
    const list = cases.filter(c => c.downloadWeekNumber == weekNumber);
    return list ? list.length : 0;
  }

  const getWeeklyDevicesDownloadedCases = (cases:IConnectCase[], weekNumber: number) => {
    const list = cases.filter(c => c.downloadWeekNumber == weekNumber)
    .map(c => c.deviceSN);
    const devices = list.filter((n, i) => list.indexOf(n) === i);
    return devices && devices.length > 0 ? devices.length : 0;
  }

  const handleDateChange = (ranges: any) => {
    
    const range = {
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      displayedStartDate: moment(ranges.selection.startDate).format("yyyy-MM-DD"),
      displayedEndDate: moment(ranges.selection.endDate).format("yyyy-MM-DD"),
      key: "selection",
    };
    sessionStorage.setItem("x-fleet-dashboard-range", JSON.stringify(range));

    setDateRange(range);
  };

  const getWeekTotal = (week:number) => {
    let total = 0;
    userDevices.forEach(d => {
      total += d[`count_${week}`];
    });
    return total;
  }

  const getTotalCases = () => {
    let total = 0;
    userDevices.forEach(d => {
      total += d["total"];
    });
    return total;
  }

  const filterCase = (c: IConnectCase) => {
    const d = userDevices.filter(d => d.serialNumber === c.deviceSN)[0];
    return d && activeMembers.indexOf(d.deviceConfiguration.memberId) >= 0;
  }

  const refreshDashboard = async() => {
    if(currentDateRange && userDevices) {

      // Get DX IS Connect cases
      const res = await getCases(
        null, 
        null,  
        moment(currentDateRange.startDate).startOf("week").format("yyyy-MM-DD"), 
        moment(currentDateRange.endDate).endOf("week").format("yyyy-MM-DD")
      );
      let cases:IConnectCase[] = res.value;

      // filter cases list
      cases = cases.filter(c => filterCase(c));

      cases.map(c => {
        c.weekNumber = moment(new Date(c.sentDate)).week();
        c.downloadWeekNumber = moment(new Date(c.downloadDate)).week();
      });

      // Add cases info to devices list
      let w:IWeek[] = [];
      const m_startDate = moment(currentDateRange.startDate);
      let currentDate = m_startDate.startOf('week');
      let list = [...userDevices];
      while(currentDate < moment(currentDateRange.endDate)) {
        // Add current week
        w.push({
          firstDay: currentDate.format("yyyy-MM-DD"),
          week: currentDate.week(),
          downloadedCases: getWeeklyDownloadedCases(cases, currentDate.week()),
          distinctDevicesCount: getWeeklyDevicesDownloadedCases(cases, currentDate.week())
        });

        list.map(d => {
          d[`week_${currentDate.week()}`] = currentDate.week();
          d[`firstDay_${currentDate.week()}`] = currentDate;
          d[`count_${currentDate.week()}`] = getDeviceCasesCount(cases, d.serialNumber, currentDate.week());
        });

        // Add week to current date
        currentDate = currentDate.add(1, "week");
      }
      // Add total cases per device
      list.map(d => {
        d["total"] = getDeviceCasesCount(cases, d.serialNumber, null);
      });

      setWeeksRange(w);
      setDevicesStats(list);
    }
  }

  const handleMemberClick = (memberId: string) => {
    if (activeMembers.indexOf(memberId) < 0) {
      const list = [...activeMembers, ...[memberId] ];
      setActiveMembers(list);

      // Refresh userDevices
      refreshDevicesList(list)
    }
  }

  const handleMemberDelete = (memberId: string) => {
    const members = activeMembers.filter((m) => m !== memberId);
    setActiveMembers(members);
    // Refresh userDevices
    refreshDevicesList(members)
  }

  const refreshDevicesList = (members: string[]) => {
    const list = allUserDevices.filter(d => members.indexOf(d.deviceConfiguration.memberId) >= 0);
    setUserDevices(list);
  }

  const isActiveMember = (memberId: string) => {
    const members = activeMembers.filter((m) => m === memberId);
    return members && members.length > 0;
  }

  React.useEffect(() => {
    // Update cases list for new selected range
    refreshDashboard();
  }, [currentDateRange, userDevices]);

  React.useEffect(() => {
    // Get current user's devices
    const fetchUserDevices =async () => {
      const data = await getMemberSerialNumbers();
      saveMembersList(data);
      setUserDevices(data);
      setAllUserDevices(data);
    }
    fetchUserDevices();    
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h3>Fleet Cases from <span className={classes.dateRange}>{currentDateRange?.displayedStartDate}</span> to <span className={classes.dateRange}>{currentDateRange?.displayedEndDate}</span></h3>

        <ClickAwayListener onClickAway={() => setCalendarVisible(false)}>
          <div className={classes.calendar}>
            <button onClick={toggleCalendar}>
              <EditCalendarIcon />
            </button>

            <div className={classes.calendarPopup}>
              {calendarVisible === true && (
                <div className={classes.calendarContainer}>
                  <div className="calendar-arrow"></div>
                  <DateRangePicker
                    onChange={handleDateChange}
                    rangeColors={["var(--primary-color)"]}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                    ranges={[currentDateRange]}
                    months={2}
                    direction="horizontal"
                  />
                </div>
              )}
            </div>
          </div>
        </ClickAwayListener>
        <Stack direction="row" spacing={1}>
          {
            membersList.map((member: IMember, index: number) => {
              return (
                <Chip
                  className={isActiveMember(member.recordId) ? classes.active : ""}
                  label={member.name}
                  onClick={() => handleMemberClick(member.recordId)}
                  onDelete={() => handleMemberDelete(member.recordId)}
                  deleteIcon={<DeleteIcon />}
                />
              )
            })
          }
        </Stack>
      </div>
      
      <h4>{t("Weekly submitted cases")}</h4>
      <TableContainer className={classes.container} component={Paper}>
          <Table size="small">
          <TableHead>
              <TableRow>
                  <TableCell width={200}>{t('Practice Name')}</TableCell>
                  <TableCell width={200}>{t('Serial number')}</TableCell>
                  <TableCell width={200}>{t('State')}</TableCell>
                  <TableCell width={200}>{t('City')}</TableCell>
                  {
                      weeksRange.map((week: IWeek, index: number) => (
                          <TableCell width={10} className={classes.countHeader}>{`Week ${week.week} (${week.firstDay})`}</TableCell>
                      ))
                  }
                  <TableCell width={10} className={classes.countHeader}>{t('Total')}</TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
              {devicesStats.map((row: IDevice, index: number) => (
              <TableRow key={row.serialNumber}>
                <TableCell width={200}>{row.deviceConfiguration?.practice?.name}</TableCell>
                <TableCell width={200}>{row.serialNumber}</TableCell>
                <TableCell width={200}>{row.deviceConfiguration?.practice?.address?.region}</TableCell>
                <TableCell width={200}>{row.deviceConfiguration?.practice?.address?.city}</TableCell>
                {
                    weeksRange.map((week: IWeek, index: number) => (
                      <TableCell className={classes.countCell}>{row[`count_${week.week}`]}</TableCell>
                    ))
                }
                <TableCell className={classes.totalCell}>{row.total}</TableCell>
              </TableRow>
              ))}

              <TableRow key="total">
                <TableCell width={200} className={classes.totalLabel}>Total</TableCell>
                <TableCell width={200}></TableCell>
                <TableCell width={200}></TableCell>
                <TableCell width={200}></TableCell>
                {
                    weeksRange.map((week: IWeek, index: number) => (
                      <TableCell className={classes.totalCell}>{getWeekTotal(week.week)}</TableCell>
                    ))
                }
                <TableCell className={classes.totalCell}>{getTotalCases()}</TableCell>
              </TableRow>
          </TableBody>
          </Table>
      </TableContainer>

      <h4>{t("Weekly downloaded cases")}</h4>
      <div className={classes.chartContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={weeksRange}
          >
            <CartesianGrid />
            <XAxis dataKey="firstDay" type="category" interval={0} height={90} tick={<CustomizedAxisLabel />} />
            <YAxis allowDecimals={false} label={{ value: 'Distinct count of Case Id', angle: -90, position: 'insideLeft' }} />
            <Tooltip />
            <Line type="monotone" dataKey="downloadedCases" stroke="#4E79A7" activeDot={{ r: 4 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <h4>{t("Active Scanners: Weekly distinct count of devices having downloaded cases")}</h4>
      <div className={classes.chartContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={weeksRange}
          >
            {/* <CartesianGrid /> */}
            <XAxis dataKey="firstDay" type="category" interval={0} height={90} tick={<CustomizedAxisLabel />} />
            <YAxis allowDecimals={false} label={{ value: 'Device SN distinct count', angle: -90, position: 'insideLeft' }} />
            <Tooltip />
            <Bar type="monotone" dataKey="distinctDevicesCount" fill="#4E79A7" barSize={20} activeDot={{ r: 4 }} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

function CustomizedAxisLabel(props: any) {
  
  const { x, y, stroke, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} textAnchor="end" fill="#666" font-size="10px" transform="rotate(-90)">
        {moment(payload.value).format("MMMM DD, yyyy")}
      </text>
    </g>
  );
}
