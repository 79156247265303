import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import useAuthService from "../../services/authService";
import { UserRole } from "../../shared/enums/UserRole";
import Wait from "../../shared/Wait";
import TabPanel from "../Navigation/VerticalMenu";

import {GET_USER_ID, GET_USER_ROLE} from '../../redux/type/user'
import getMemberId from "../../services/api/user/getMemberId";

import { selectUser } from '../../redux/selector/user';
import { useTranslation } from 'react-i18next';
 


const Authorized: React.FC = () => {

  const authService = useAuthService();
  const dispatch = useDispatch()

  useEffect(() => {
    authService.getUserRole().then(userRole => {
      dispatch({type: GET_USER_ROLE, data: userRole})
      if (UserRole.NOT_SET !== userRole) {
        authService.getUserId().then((userID: string) => {
          dispatch({type: GET_USER_ID, data: userID})
          getMemberId(userID);
        });
      }
    })}
  , []);

  const user = useSelector(selectUser)
  return (
    <React.Fragment>
      { user.loading ? <Loading/> : <TabPanel></TabPanel> }
    </React.Fragment>
  );
};

const NotAuthorized = () => {
  const {t} = useTranslation();
  return (
    <div style={{ height: "89vh" }}>
      <div className="centered">
        <Alert severity="error">
        {t('UnauthorizhedMsg')}
        </Alert>
      </div>
    </div>
  );
};

const Loading = () => {
  return (
    <div style={{ height: "89vh" }}>
      <div className="centered">
        <Wait style={{ padding: "8px 20px" }} />
      </div>
    </div>
  );
};

const Home: React.FC = () => {
  const authService = useAuthService();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchIsAuthorized = async () => {
      const result = await authService.isAuthorizedUser();
      setIsAuthorized(result);
      setIsLoading(false);
    };
    fetchIsAuthorized();
  }, [authService]);

  if (isLoading) {
    return <Loading />;
  }

  if (isAuthorized) {
    return <Authorized />;
  } else {
    return <NotAuthorized />;
  }
};

export default Home;
