import React, { useState, useEffect, useRef } from 'react'

// Interface
import IDeviceForm, { IDevice } from './IDeviceForm';

//Style
import styles from './DeviceForm.module.scss'

import { Dialog } from '../..';
import { Button, TextField } from '@mui/material'; 
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import { getMembersList, IMemberResponseFlp } from '../../../../services/memberServiceFlp';
import { useTranslation } from 'react-i18next'; 
 

interface AutoCompleteOption {
  id: string;
  label: string;
};

const DeviceForm: React.FC<IDeviceForm> = ({
  device,
  onChange,
  open,
  setOpen,
  saveRequest,
  errorMessage
}) => {

  //const translation
  const {t} = useTranslation();
  
  const [members, setMembers] = useState<AutoCompleteOption[]>([])

  const [localDevice, setDevice] = useState(device)
  const [isChange, setIsChange] = useState(false);

  // get members list and set it to the drop down
  React.useEffect(() => {
    getMembersList().then((response: IMemberResponseFlp[]) => {
        setMembers(response.map((m:IMemberResponseFlp) => {
          return {id: m.recordId, label: m.name } as AutoCompleteOption
        }));
      });
    }, []);

    function usePrevious(value: any) {
      const ref = useRef();
      useEffect(() => {
        ref.current = value;
      });
      return ref.current ? ref.current : "";
    }

      const prevMemberId = usePrevious(device.memberId);
      useEffect(() => {
          if(prevMemberId !== localDevice.memberId) {
            setIsChange(true);
          }
      }, [device.memberId])


  const handleOnChange = (newDevice: IDevice) => {
      setDevice(newDevice)
      onChange(newDevice)
    }

  // Retrieve the member info: useful to find the corresponding recordId of a given member name
  const memberInfo = members.find((it:AutoCompleteOption)=> device.memberId === it.id);

  return(
    <Dialog onClose={() =>  setOpen(false)} title={t('Edit Device')} open={open}>
      <>
      { errorMessage && <Alert severity="error">{errorMessage}</Alert> }
      </>
      {
        <div className={styles.field} > 
            <label htmlFor="deviceSN"> <b>{t('SerialNumber')} : {device.serialNumbers}</b></label>
        </div>
      }
      <Autocomplete
        options={members}
        getOptionLabel={(option: AutoCompleteOption) => option.label}
        value= {{id: memberInfo?.id, label: memberInfo?.label} as AutoCompleteOption}
        onChange={(
          event: any,
          updateValue: AutoCompleteOption|null,
          reason: any,
          details: any
        ) => handleOnChange({
          ...device,
          memberId: updateValue ? updateValue.id : ""
        })}
        renderInput={(params) => <TextField {...params} label="Member" />}
      />
      <div className="dialog-actions">
        <Button
          onClick={() => {
            setOpen(false)
            setIsChange(false)
          }}
          variant="outlined"
          className="btn-confirm"
        >{t('Cancel')}</Button>
        <Button
          disabled={!isChange}
          onClick={() => {
            saveRequest()
            setIsChange(false)
          }}
          variant="outlined"
          className="btn-confirm"
        >{t('Update')}</Button>
      </div>
    </Dialog>
  )
}

export default DeviceForm