import http from "./api/http";
import getLoadedConfig from "./appConfigService";

export interface IQuickConnectPartner {
  name: string;
  trustedPartnerId: string;
  emailAddress: string;
  logo: string;
  partnerType: string;
  enable: boolean;
  addressId: string;
  indications?: string;
  countries?: string;
  recordId: string;
  recordStatus: string;
  recordCreated: string;
  recordLastUpdated: string;
  memberPartners: IMemberPartner[];

}
export interface IMemberPartner {
  memberId: string;
  partnerId: string;
  partnerStatus: string;
}

export const getQuickConnectPartners = async(start: number, itemPerPage: number): Promise<any> => {
  const config = getLoadedConfig();
  const query = `Partners?$count=true&$top=${itemPerPage}&$skip=${start}`;
  const filter = `&$filter= recordStatus eq 'Active' and (partnerType eq 'Advanced' or partnerType eq 'Standard')`

  const url = config.oDataUrl + query + filter;
  const res = await http.get(url);
  const count = res.data["@odata.count"];
  const resp = {
    count: count ? parseInt(count) : 0,
    value: res.data?.value as IQuickConnectPartner[]
  };
  return resp;
};

export const getMemberQuickConnectPartners = async(start: number, itemPerPage: number): Promise<any> => {
  const config = getLoadedConfig();
  const query = `Partners/GetMemberQuickPartners()?$expand=memberPartners&$count=true&$top=${itemPerPage}&$skip=${start}`;

  const url = config.oDataUrl + query;
  const res = await http.get(url);
  const count = res.data["@odata.count"];
  const resp = {
    count: count ? parseInt(count) : 0,
    value: res.data?.value as IQuickConnectPartner[],
  };
  return resp;
};



