import React, { useState, useEffect} from "react";
import Pagination from "@mui/material/Pagination";
import LinearProgress from "@mui/material/LinearProgress";
import {
  addOrUpdateDeviceConfiguration,
} from "../../services/deviceService";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CustomizedDialog from "../../components/Dialogs/CustomizedDialog";
import { UserRole } from "../../shared/enums/UserRole";
import EditDeviceFlpDialog from "../../components/Dialogs/EditDeviceFlpDialog";
import {
  getAllExchangeLabsForMemberAsync,
} from "../../services/labExchangeService";
import { IExchangeLabModel } from "../../services/labExchangeModel";
import IPractice from "../../interfaces/IPractice";
import { useSelector  } from 'react-redux';
import { selectUser } from '../../redux/selector/user';
import { selectNbResult, pendingListDevices, selectTableDevices } from '../../redux/selector/devices';
import getDevices from '../../services/api/device/getDevices';

//api
import { updateDevice } from '../../services/api/device'

import { SearchBar, DeviceForm } from "../../components/shared";
import styles from './Device.module.scss'
import { useTranslation } from 'react-i18next';

import { FormatDateMMYYYY } from "../../shared/utils/utils";
import { getCode, getName } from "country-list";
import { IDevice } from '../../components/shared/Organisms/DeviceForm/IDeviceForm';
import { fontStyle } from "@mui/system";
import { InlineFunctions } from "uglify-js";
import IMember from "../../interfaces/IMember";

interface IProps {
  type: string;
}

const fieldsFilters: Array<string> = ['serialNumber','userRef','deviceConfiguration/member/name','deviceConfiguration/practice/name','deviceConfiguration/practice/address/countryCode']
const devicePerPage = 10;

const initForm = {
  serialNumbers: [] as string[],
  deviceType: '',
  memberId: '',
}

export default function DeviceList(props: IProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [openDeviceForm, setOpenDeviceForm] = useState(false)
  const [editDeviceForm, setEditDeviceForm] = useState(initForm)
  const [openDialog, setOpenDialog] = useState(false);
  const [editRowIndex, setEditRowIndex] = useState<number>(-1);
  const [editRowData, setEditRowData] = useState<any>();
  const [ idSelected, setIdSelected ] = useState('')

  const [title, setTitle] = useState("");

  const [isEditDeviceLoading, setEditDeviceLoading] =
    useState<boolean>(false);
  const [editDevicemessageError, setEditDeviceMessageError] =
    useState("");
  const [editDeviceExchangeLabsForMember, setEditDeviceExchangeLabsForMember] =
    useState<IExchangeLabModel[]>();

  const [filter, setFilter] = useState("")

  // const redux
  const user = useSelector(selectUser)
  const deviceCount = useSelector(selectNbResult)
  const isDeviceLoading = useSelector(pendingListDevices)
  const deviceRows = useSelector(selectTableDevices)

  const {t} = useTranslation()

  useEffect(() => {
    if (user.memberID !== "" && user.role !== UserRole.FLP_ADMINISTRATOR ) {

      // Get all exchange lab that has been added to the current member
      // Optimization for edit device management
      getAllExchangeLabsForMemberAsync(user.ID).then((labs: any) => {
        setEditDeviceExchangeLabsForMember(labs.value);
      });
    }
  }, [user.role, user.ID]);

  // Fetch devices
  useEffect(() => {
    // handleChange(null, 1);
    getDevices({
      top: devicePerPage,
      user: user,
      skip: 0
    })
  }, []);

  const generateFilter = (fields: Array<string>, value: string) => {
    var fieldsString: string = ''
    value = value.toLowerCase();
    fields.forEach((field: string, index: number) => {
      if(field !== 'deviceConfiguration/practice/address/countryCode')
         index < 1 ? fieldsString +=  `contains(tolower(${field}),'${value}')` : fieldsString +=  ` or contains(tolower(${field}),'${value}')`
    })
    // Search only on country field 
    var countryCode = getCode(value);
    countryCode = countryCode?.toLowerCase(); 
    if(countryCode)
    {
       fieldsString +=  ` or contains(tolower(deviceConfiguration/practice/address/countryCode),'${countryCode}')`
    } 
    // Search on Labnames 
    fieldsString +=  ` or deviceConfiguration/partnerDevices/any(p:contains(tolower(p/partner/name),'${value}'))`
 

    return fieldsString
  }

  const isCustomerRefValid = (sapId: string, memberRef: string):boolean => {
   if ( sapId.length > 0 && memberRef.length > 0) {
      var refArray = memberRef.split(',')
      return refArray.some(e => e === sapId)
    } else {
      return false
    }
  }

  const closeDialog = () => {
    setOpenDialog(false);
    setEditDeviceMessageError("");
    setEditDeviceLoading(false);
  };

  const closeDeviceForm = () => {
    setOpenDeviceForm(false);
    setIdSelected('');
  };

  const saveEditDevice = async (
    labs: IExchangeLabModel[],
    practice: IPractice | undefined,
    member: IMember | undefined
  ) => {
    setEditDeviceLoading(true);

    try {
      setEditDeviceMessageError("");
      const deviceConfigId = editRowData.recordId ? editRowData.recordId : null;

      if (user.memberID !== "") {
        let deviceConfiguration = await addOrUpdateDeviceConfiguration(
          deviceConfigId,
          member?.recordId,
          editRowData?.serialNumber,
          labs,
          practice?.recordId
        );
        if (deviceConfiguration)
          editRowData.deviceConfiguration = deviceConfiguration;
        else throw new Error("Cannot create device configuration");
      }

      getDevices({
        top: devicePerPage,
        user: user,
        skip: (currentPage - 1)*devicePerPage,
        filter: generateFilter(fieldsFilters, filter)
      })
      closeDialog();
      handleChange(null, currentPage);
    } catch (error: any) {
      if (error instanceof Error) setEditDeviceMessageError(error.message);
      else setEditDeviceMessageError("Internal error");
    }
    setEditDeviceLoading(false);
  };

  const handleChange = (event: any, value: number) => {
    if (value !== currentPage && user.ID.length > 0) {
      setCurrentPage(value);
      getDevices({
        top: devicePerPage,
        user: user,
        skip: (value - 1) * devicePerPage,
        filter: generateFilter(fieldsFilters, filter)
      })
    }
  };

  return (
    <>
      {user.role === UserRole.FLP_ADMINISTRATOR && (
        <DeviceForm
          device={editDeviceForm}
          onChange={(value: IDevice) => {
            setEditDeviceForm(value)
          }}
          open={openDeviceForm}
          setOpen={closeDeviceForm}
          saveRequest={async () => {
              updateDevice({ recordId: idSelected, form: editDeviceForm }).then(() => {
                getDevices({
                  top: devicePerPage,
                  user: user,
                  skip: (currentPage - 1)*devicePerPage,
                  filter: generateFilter(fieldsFilters, filter)
                })
                closeDeviceForm();
              })
            }}
        />
      )}
      <div className={styles.root}> 
        <SearchBar
          onCancelSearch={()=> {
            setFilter('')
            getDevices({
              top: devicePerPage,
              user: user,
              skip: 0
            })
          }}
          onRequestSearch={(value:string) => {
            setFilter(value)
            setCurrentPage(1)
            getDevices({
              top: devicePerPage,
              user: user,
              skip: 0,
              filter: generateFilter(fieldsFilters, value)
            })
          }}
          tooltipText={`${t('SerialNumber')}, ${t('Customer Ref')}, ${t('Member')}, ${t('Lab')}, ${t('Practice')}, ${t('Country')}`}
          buttonLabel={`${t('Search')}`} 
          placeholder={`${t('SerialNumber')}, ${t('Customer Ref')}, ${t('Member')}, ${t('Lab')}, ${t('Practice')}, ${t('Country')}`} >
        </SearchBar> 
        <div>
          {deviceCount> 0 && <p className={styles.nb_result}>{t(`result`, {count: deviceCount})}</p>}
        </div>
        { deviceCount> 0 ? <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell width={150}>{`${t('SerialNumber')}`}</TableCell>
                  <TableCell width={100}>Customer Ref</TableCell>
                  <TableCell width={125}>{`${t('Configuration')}`}</TableCell>
                  <TableCell width={81}>{`${t('DeviceType')}`}</TableCell>
                  <TableCell width={125}>{`${t('Activation')}`}</TableCell>
                  <TableCell width={125}>{`${t('Last Connection')}`}</TableCell>
                  {(user.role === UserRole.MEMBER_MANAGER || user.role === UserRole.MEMBER_SUB_MANAGER) && (
                    <TableCell width={78}>{`${t('Fleet name')}`}</TableCell>
                  )}
                  {(user.role !== UserRole.MEMBER_MANAGER && user.role !== UserRole.MEMBER_SUB_MANAGER) && (
                    <TableCell width={194}>{`${t('Member')}`}</TableCell>
                  )}
                  <TableCell width={200}>{`${t('Lab')}`}</TableCell>
                  <TableCell width={150}>{`${t('Practice')}`}</TableCell>
                  <TableCell width={116}>{`${t('Country')}`}</TableCell>
                  {(user.role === UserRole.FLP_ADMINISTRATOR || user.role === UserRole.MEMBER_MANAGER) && (
                    <TableCell width={78}>{`${t('Actions')}`}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {deviceRows
                .sort((a,b) => { return (a.serialNumber > b.serialNumber ? 1 : -1)})
                .map((row: any, index: number) => {
                  return(
                  <TableRow key={row.serialNumber}>
                    <TableCell width={150}>{row.serialNumber}</TableCell>
                    <TableCell width={100}>{row.sapId}</TableCell>
                    <TableCell width={125}>
                      {(t(row.configuration))}
                    </TableCell>
                    <TableCell width={81}>{row.deviceType}</TableCell>
                    <TableCell width={125}>
                      { FormatDateMMYYYY(row.firstUseDate) }
                    </TableCell>
                    <TableCell width={125}>
                      { FormatDateMMYYYY(row.lastUseDate) }
                    </TableCell>
                    <TableCell width={194} style={isCustomerRefValid(row.sapId, row.memberRef) ? {fontStyle: 'normal'} : {fontStyle: 'italic'}}>
                      {row.memberName || ""}  
                    </TableCell> 
                    <TableCell width={200}>
                      <Tooltip
                        title={row.labName}
                        placement="top"
                        style={{
                          width: "100%",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        <div>
                          {row.labName}
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell width={150}>
                      {row.practice?.name}
                    </TableCell>
                    <TableCell width={116}>
                      {row.practice?.address?.countryCode ? getName(row.practice?.address?.countryCode) : ''}
                    </TableCell>
                    {user.role === UserRole.FLP_ADMINISTRATOR && (
                      <TableCell width={50}>
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            setEditRowIndex(index);
                            setEditRowData(row);
                            setEditDeviceForm({
                              serialNumbers: row?.serialNumber,
                              deviceType: row?.deviceType,
                              memberId: row?.memberId
                            });
                            setOpenDeviceForm(true);
                            setIdSelected(row?.recordId)
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                    {user.role === UserRole.MEMBER_MANAGER && (
                      <TableCell width={50}>
                        <IconButton
                          aria-label={t("Edit")}
                          onClick={() => {
                            setEditRowIndex(index);
                            setEditRowData(row);
                            setTitle(t("Edit Device"));
                            setOpenDialog(true);
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                )})}
              </TableBody>
            </Table>
          </TableContainer> : <span>{t('no_result')}</span>
        }
        {isDeviceLoading && <LinearProgress style={{ marginTop: `16px`, width: '100%' }} />}
        {deviceCount > 0 && !isDeviceLoading && (
          <div className={styles.pagination}>
          <Pagination
            count={Math.ceil(deviceCount / devicePerPage)}
            shape="rounded"
            size="small"
            onChange={handleChange}
            defaultPage={currentPage}
            className={styles.pagination}
            color="primary"
          />
          </div>
        )}
      </div>
      <CustomizedDialog
        title={title}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      >
        { title && (
          <EditDeviceFlpDialog
            closeDialog={closeDialog}
            saveData={saveEditDevice}
            deviceId={editRowData?.recordId}
            userId={user.ID}
            loading={isEditDeviceLoading}
            errorMessage={editDevicemessageError}
            exchangeLabsForMember={editDeviceExchangeLabsForMember}
            practice={editRowData?.practice}
            member={editRowData?.member}
          />
        )}
      </CustomizedDialog>
    </>
  );
}
