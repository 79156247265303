export enum DialogType {
  ADD_MEMBER = "addMember",
  CONFIRM_DISABLE_DIALOG = "confirmDisableDialog",
  CONFIRM_DELETE = "confirmDelete",
  ADD_CSD_USER = "addCSDUser",
  EDIT_CSD_USER = "editCSDUser",
  EDIT_MEMBER = "editMember",
  APPLICATION_INFO = "appInfo",
  ADD_USERFLP = "addUserFlp",
  EDIT_USERFLP = "editUserFlp",
}
