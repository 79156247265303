import {
  GET_DEVICES_SUCCESS,
  GET_DEVICES_PENDING,
  GET_DEVICES_FAIL,
  UPDATE_DEVICE_SUCCESS,
} from '../type/device'
import { IDeviceAction } from '../action/device'
import IDevice from '../../interfaces/IDevice'

import { getName } from "country-list";
export interface IDeviceState {
  devices: {
    [key: string]: IDevice
  },
  listDevices: {
    data: Array<string>,
    count: number,
    pending: boolean,
  }
}

const initialState: IDeviceState = {
  devices: {},
  listDevices: {
    data: [],
    count: 0,
    pending: false,
  }
}

export default function (
  state: IDeviceState = initialState,
  action: IDeviceAction
) {
  switch (action.type) {
    case GET_DEVICES_PENDING:
      return {
        ...state,
        listDevices: {
          ...state.listDevices,
          pending: true,
        }
      }
    case GET_DEVICES_SUCCESS:
      return {
        ...state,
        devices: {
          ...state.devices,
          ...action.data.value.reduce((a: any, b: any) => ({
            ...a, [b.serialNumber]: {
              entityId: b.entityId,
              serialNumber: b.serialNumber,
              deviceType: b.deviceType,
              practiceId: b.practiceId ? b.practiceId : '',
              practice: b.practice ? b.practice : null,
              country: b.countryCode ? getName(b.countryCode) : '',
              configuration: b.configuration ? b.configuration : '',
              firstUseDate: b.firstUseDate ? b.firstUseDate : '',
              lastUseDate: b.lastUseDate ? b.lastUseDate : '',
              memberName: b.memberName ? b.memberName : '',
              memberId: b.memberId ? b.memberId : '',
              member: b.member ? b.member : null,
              labName: b.labName ? b.labName : '',
              recordId: b.recordId ? b.recordId : '',
              sapId: b.sapId ? b.sapId : '',
              memberRef: b.memberRef ? b.memberRef : ''
            }
          }), {})
        },
        listDevices: {
          data: action.data.value.map((device: any) => {
            return device.serialNumber
          }),
          count: action.data['@odata.count'],
          pending: false
        }
      }
    case GET_DEVICES_FAIL:
      return {
        ...state,
        listDevices: {
          ...state.listDevices,
          pending: false,
        }
      }
    case UPDATE_DEVICE_SUCCESS:
      return {
        ...state,
        listDevices: {
          ...state.listDevices,
          add: {
            ...state.listDevices,
            errorMessage: "",
            pending: false
          }
        }
      }
  }
  return state
}
