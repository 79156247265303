import React from 'react';

interface IAlertContext {
    alertMessage: string;
    setAlertMessage: React.Dispatch<React.SetStateAction<string>>; 
    alertSeverity: string;
    setAlertSeverity: React.Dispatch<React.SetStateAction<string>>;
    isOpenAlert: boolean;
    setOpenAlert: React.Dispatch<React.SetStateAction<boolean>>;  
    isButtonDisabled: boolean;
    setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;  
    alertErrorMessage: string;
    setAlertErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  } 
interface IChildrenType{
    children : React.ReactNode;
}

export const AlertContext = React.createContext<IAlertContext| null>(null);

export const AlertContextProvider = ({ children } : IChildrenType ) => {
    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const [alertErrorMessage, setAlertErrorMessage] = React.useState<string>("");
    const [alertSeverity, setAlertSeverity] = React.useState<string>("");
    const [isOpenAlert, setOpenAlert] = React.useState<boolean>(false);
    const [isButtonDisabled, setButtonDisabled] = React.useState<boolean>(false); 
  
    const memorizedAlertContextValue = React.useMemo(
        () => ({
            alertMessage,            
            setAlertMessage,
            alertErrorMessage,            
            setAlertErrorMessage,
            alertSeverity,
            setAlertSeverity,
            isOpenAlert,
            setOpenAlert,
            isButtonDisabled,
            setButtonDisabled 
        }),
        [alertMessage, setAlertMessage, alertSeverity, setAlertSeverity,isOpenAlert , setOpenAlert, isButtonDisabled, setButtonDisabled, alertErrorMessage, setAlertErrorMessage]
    );

    return (
        <AlertContext.Provider value={ memorizedAlertContextValue }>
            {children}
        </AlertContext.Provider>
    );
}; 

export const useAlertContext = () => {
    const alertContext = React.useContext(AlertContext);

    if(!alertContext) throw new Error('The context must be used inside the provider <AlertContextProvider></AlertContextProvider>');

    return alertContext;
}
 