import React, { useState } from 'react'
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Paper from "@mui/material/Paper";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import withStyles from "@mui/material/styles/withStyles";
import classNames from "classnames";
import classes from './SearchBar.module.scss'

// Interface
import ISearchBar from './ISearchBar';

// MaterialUI
// import MaterialSearchBar from 'material-ui-search-bar';
import { Button, Tooltip } from "@mui/material";


const SearchBar: React.FC<ISearchBar> = (props) => {
  const {
    buttonLabel,
    placeholder,
    tooltipText,
    onRequestSearch,
    onCancelSearch,
  } = props

  const inputRef = React.useRef();
  const [value, setValue] = useState("");

  const handleCancel = React.useCallback(() => {
    setValue("");
    if (onCancelSearch) {
      onCancelSearch();
    }
  }, [onCancelSearch]);

  const handleRequestSearch = React.useCallback(() => {
    if (onRequestSearch) {
      onRequestSearch(value);
    }
  }, [onRequestSearch, value]);

  const handleKeyUp = React.useCallback(
    (e) => {
      if (e.charCode === 13 || e.key === "Enter") {
        handleRequestSearch();
      }
    },
    [handleRequestSearch, handleCancel]
  );

  return(
    <div className={classes.container}>
      <Tooltip 
        title={tooltipText ? tooltipText: ''}
        placement="top-start"
          style={{ 
            width: "100%",
            textOverflow: "ellipsis",
            overflow: "hidden", 
          }}>
        <div>
          <Paper className={classes.paper}>
            <div className={classes.searchContainer}>
              <Input
                inputRef={inputRef}
                onBlur={() => {
                  setValue((v) => v.trim());
                }}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value)
                }}
                onKeyUp={(e) => {
                  if (e.charCode === 13 || e.key === "Enter") {
                    handleRequestSearch();
                  }
                }}
                fullWidth
                className={classes.searchInput}
                disableUnderline
                placeholder={placeholder}
              />
            </div>
            <IconButton
              onClick={handleRequestSearch}
              className={classNames(classes.iconButton, classes.searchIconButton, {
                [classes.iconButtonHidden]: value !== "",
              })}
            >
              <SearchIcon />
            </IconButton>
            <IconButton
              onClick={handleCancel}
              className={classNames(classes.iconButton, {
                [classes.iconButtonHidden]: value === "",
              })}
            >
              <ClearIcon />
            </IconButton>
          </Paper>
        </div>
      </Tooltip>
      <Button
        variant="contained"
        onClick={() => onRequestSearch(value)}
      >
        {buttonLabel}
      </Button>
    </div>
  )
}

export default SearchBar